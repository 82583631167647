import React,{useState} from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { saveLists } from '../../../features/lists/listsSlice';
import { updateList } from "../../../utils/miscHelpers"

import HeaderBar from "../../../components/layout/HeaderBar";
import ClientForm from "../../../components/client/elements/ClientForm";

const section = 'client';

const ClientDetailForm = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  
  const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);  
  const storedClient = useSelector(state => state.client.value && state.client.value ? state.client.value  : {});

  const client = location.state || null;

  const [tsId, setTsId] = useState((new URLSearchParams(location.search)).get('tsId') || null);

  const topSpacer = 50;

  const onCancel = () =>{
    if (client) {
      navigate('/client');
    } else {
      navigate('/client');
    }
  };

  const onSubmit = (clientObj) =>{    
    if (clientObj && clientObj._id) {
         const updatedList = updateList(storedList,clientObj); 
         const hasDoc = updatedList.find((ele)=>ele._id === clientObj._id);
         if (!hasDoc) {
           updatedList.unshift(clientObj);
           updatedList.pop();
         }
         dispatch(saveLists({ section : section, data :updatedList}));
    }

    if (client) {
      navigate('/client');
    } else {
      navigate('/client');
    }    
  };
  
  return (
    <div className="container">
        <HeaderBar 
          actionContent={null}        
          hasBack={true} 
          hasFilters={false} 
          hasSort={false} 
          hasSearch={false} 
          section={section} 
          storedFilters={{}}
        />
            <div className='tab-form' style={{borderRadius:'10px',backgroundColor:'white',padding:'16px',marginTop:topSpacer+'px'}}>           
                <ClientForm 
                    scale='full'
                    setOpen={onCancel}
                    data={client} 
                    tsId={tsId}
                    handlerMethod={onSubmit}
                  />
            </div> 
                 
      </div>            
    
  );
};

export default ClientDetailForm;