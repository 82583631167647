import React, { useState,useEffect } from 'react';
import {  Divider , Input, Checkbox} from 'antd';
import { useNotification } from '../../../hooks/NotificationProvider';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector,useDispatch } from 'react-redux';
import { saveUser } from '../../../features/user/userSlice';
import { saveLists } from '../../../features/lists/listsSlice';
import { EditUserProps } from "../../../services/User"
import { ShowFile, downloadFile , getFileExtension,blobToDownload } from "../../../services/File"
import { updateList, capitalize, getOnGoingStudies, getLevelHospitality ,getDiplomas ,getLanguage, getLanguageLevel} from "../../../utils/miscHelpers"

import UserGeneral from "./UserGeneral"
import MediaCarousel from '../../../components/elements/MediaCarousel';

const nbMedias = 4;

const { TextArea } = Input;

const PresentationTab = ({section, bmgFilters,  openGeneral, setOpenGeneral}) => { 

    const { openNotification } = useNotification();

    const dispatch = useDispatch();
    const { t } = useTranslation();    
    const storedUser = useSelector(state => state.user.value && state.user.value ? state.user.value  : {});
    const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : null);

    const [notes, setNotes] = useState(storedUser && storedUser.notes ? storedUser.notes : '');
    const [pdfShowExpertiseDescription, setPdfShowExpertiseDescription] = useState(storedUser.pdfShowExpertiseDescription);
    const [pdfShowStudiesDescription, setPdfShowStudiesDescription] = useState(storedUser.pdfShowStudiesDescription);

    useEffect(() => {
        if (storedUser && storedUser.notes) setNotes(storedUser.notes);
    }, [storedUser]);

    const onChangeNotes = (e) => {       
        setNotes(e.target.value);        
    };

    const saveNotes = async () => {  
        if (notes !== undefined) {
            const userUpdated = await EditUserProps(storedUser, { notes : notes});
            if (userUpdated && userUpdated._id) {
                dispatch(saveUser(userUpdated));
                const updatedList = updateList(storedList,userUpdated); 
                dispatch(saveLists({ section : section, data :updatedList}));
                openNotification('success','Modification Enregistrée');
            } else {
                openNotification('error','Probléme lors de la sauvegarde');
            }
        }
    };

    const onChangeCheckbox = (e, type) => {
        const checked = e.target.checked;

        if (type === "pdfShowStudiesDescription") {
            setPdfShowStudiesDescription((prevState) => {
                const newState = checked;
                savePdfShow({ pdfShowStudiesDescription: newState, pdfShowExpertiseDescription });
                return newState;
            });
        } else if (type === "pdfShowExpertiseDescription") {
            setPdfShowExpertiseDescription((prevState) => {
                const newState = checked;
                savePdfShow({ pdfShowStudiesDescription, pdfShowExpertiseDescription: newState });
                return newState;
            });
        }
    };

    const savePdfShow = async (pdfShow) =>{        
        const userUpdated = await EditUserProps(storedUser, pdfShow );
        if (userUpdated && userUpdated._id) {
            dispatch(saveUser(userUpdated));
            openNotification('success','Modification Enregistrée');
        } else {
            openNotification('error','Probléme lors de la sauvegarde');
        }        
    };

    const openFile = async (prop,fileName) => {
        if (fileName) {
            if (getFileExtension(fileName) === 'pdf') {
                const file = await downloadFile({section: prop,file: fileName,size:''});
                return blobToDownload(file,fileName);
            } else {
                return await ShowFile(prop,fileName);
            }            
        } else {
            console.error('File not found', prop,fileName);
            openNotification('error','Aucun fichier');
        }
    };

    const doLanguages = (languages) => {
        if (languages && languages.length) {
            return (
                <>
                    { languages.map((lang,index) => (
                        <div key={index.toString()} className="language-detail">
                            <span className='inline tag-fix capitalize accent-color uppercase'>{lang.code}</span>                        
                            <span className='inline font-small capitalize'>{getLanguage(lang.code)}</span>
                            <span>-</span>
                            <span className='inline font-small capitalize'>{getLanguageLevel(lang.level)}</span>
                        </div>
                    ))}    
                </>
            );
        }
    };

    const doAreaExpertises = (areaExpertises) => {

        if (areaExpertises && areaExpertises.length) {
            return (
                <>
                    { areaExpertises.map((areaExpertise,index) => (
                        <div  key={index.toString()}  className='tag capitalize accent-color'>{areaExpertise}</div>
                    ))}    
                </>
            );
        }

    };

    const cardMetaExperience = (user) =>{

        return (
            <> 
                <div className="card-content-detail">
                    <div className='large'>
                        <div>
                            <div className="h2 font-small">{t('user.languages')} : </div>
                            <div>{doLanguages(user.languages)}</div>
                        </div>
                        <div>
                            <div className="h2 font-small">{t('user.areaExpertises')} : </div>
                            <div className="area-expertise-detail capitalize">{doAreaExpertises(user.areaExpertises)}</div>
                        </div>   
                    </div>
                    <div >
                        <div className="align-right">
                            <div className="h2 inline align-right font-small">{t('user.diploma')}</div>
                            <div className="font-small  capitalize">{getDiplomas(user.diploma)}</div>
                        </div>                          
                        <div className="align-right">
                            <div className="h2 inline align-right font-small">{t('user.ongoingStudies')}</div>
                            <div className="font-small  capitalize">{getOnGoingStudies(user.ongoingStudies)}</div>
                        </div>
                        <div  className="align-right">
                            <div className="h2 inline align-right font-small">{t('user.levelHospitality')}</div>
                            <div className="font-small  capitalize">{getLevelHospitality(user.levelHospitality)}</div>
                        </div>  
                        <div  className="align-right">
                            <div className="h2 inline align-right font-small">{t('user.job')}</div>
                            <div className="font-small  capitalize">{user.job}</div>
                        </div>                                                                     
                    </div>            
                </div>
            </>
        );
    };
    

    const cardMetaLegal = (user) =>{
        return (
            <>
                <div className="card-content-detail">
                    <div >
                        <div className='uppercase'>
                            <span className="h2 font-small">{t('user.iban')} : </span>
                            <span>{user.iban}</span>
                        </div>
                        <div className='uppercase'>
                            <span className="h2 font-small">{t('user.avs')} : </span>
                            <span>{user.avsNumber}</span>
                        </div>
                        { (user.drivingLicense || user.carAvailable) &&
                            <div className='spacerH' >
                                <span className="h2 font-small">{t('user.mobility')} : </span>
                                { user.drivingLicense && 
                                    <span className='inline tag spacer  capitalize accent-color'>{t('user.drivingLicense')}</span>
                                }
                                { user.carAvailable && 
                                    <span className='inline tag  capitalize accent-color'>{t('user.carAvailable')}</span>
                                }
                            </div>
                        }
                        { (user.trainSubscriptionAG || user.trainSubscriptionHP) &&
                            <div className='spacerH' >
                                <span className="h2 font-small">{t('user.train')} : </span>
                                { user.trainSubscriptionAG && 
                                  <span className='inline spacer tag  capitalize accent-color'>{t('user.trainSubscriptionAG')}</span>
                                }
                                { user.trainSubscriptionHP && 
                                 <span className='inline tag  capitalize accent-color'>{t('user.trainSubscriptionHP')}</span>
                                }
                            </div>   
                        }                        
                    </div>          
                </div>
            </>
        );
    };
    
    return(
        <div className="tab-content" style={{height:'initial'}}>
            <UserGeneral
                section={section}
                bmgFilters={bmgFilters}
                openGeneral={openGeneral}
                setOpenGeneral={setOpenGeneral}
            />
            <div className={'tab-block user-block '+ (openGeneral ? '' : 'folded')} >
                <MediaCarousel user={storedUser} nbMedias={nbMedias}/>
                <div className='detail-block'>
                    <div className='lefter column-direction'>
                        <div className="header-user-detail">
                            <div className="inline h2 capitalize">{t('user.experience')}</div>
                            { (storedUser.cv) && 
                            <div onClick={()=>openFile('cv',storedUser.cv)} className='inline secondary-button'>{t('user.cv')}</div>
                            }
                        </div> 
                        <Divider className="divider-detail-user-presentation"/>
                        {storedUser.expertiseDescription && 
                            <div className='user-presentation-block-checkbox'>
                                <Checkbox
                                    title='Afficher EXPERTISE dans le PDF'
                                    style={{marginRight:'6px',marginTop:'10px'}}               
                                    id='pdfShowExpertiseDescription'
                                    checked={pdfShowExpertiseDescription} 
                                    className="custom-checkbox"
                                    onChange={(e)=>onChangeCheckbox(e,"pdfShowExpertiseDescription")}
                                />                               
                                <p className='para'>{capitalize(storedUser.expertiseDescription)}</p>
                            </div>      
                        }
                        {!storedUser.expertiseDescription && 
                            <div className='user-presentation-block-checkbox'>
                                <p className='para'>Aucun Commentaire Expertise</p>
                            </div>
                        }                  
                        <Divider className="divider-detail-user-presentation"/>
                        {storedUser.studiesDescription && 
                            <div className='user-presentation-block-checkbox'>
                                <Checkbox       
                                    title='Afficher ÉTUDES dans le PDF'       
                                    style={{marginRight:'6px',marginTop:'10px'}}       
                                    id='pdfShowStudiesDescription'
                                    checked={pdfShowStudiesDescription} 
                                    className="custom-checkbox"
                                    onChange={(e)=>onChangeCheckbox(e,"pdfShowStudiesDescription")}
                                />                              
                                <p className='para'>{capitalize(storedUser.studiesDescription)}</p>
                            </div>
                        }
                        {!storedUser.studiesDescription && 
                            <div className='user-presentation-block-checkbox'>
                                <p className='para'>Aucun Commentaire Études</p>
                            </div>
                        }
                        <Divider className="divider-detail-user-presentation"/>
                        {cardMetaExperience(storedUser)}
                    </div>
                    <div className='righter column-direction'>
                        <div className="header-user-detail">
                            <div className="inline h2 capitalize">{t('user.legal')}</div>
                            {(storedUser.idCard) && 
                                <div onClick={()=>openFile('idCard',storedUser.idCard)} className='inline secondary-button'>{t('user.idCard')}</div>
                            }
                            {(storedUser.workPermit) && 
                                <div onClick={()=>openFile('workPermit',storedUser.workPermit)} className='inline secondary-button'>{t('user.workPermit')}</div>
                            }                            
                        </div>                         
                        <Divider className="divider-detail-user-presentation"/>
                        {cardMetaLegal(storedUser)}
                        <Divider className="divider-detail-user-presentation"/>
                        <div className="note-header h2">
                            <span className="h2 capitalize">{t('user.notes')}</span>
                            <span onClick={saveNotes} className="icon-save link" title="Enregistrer"><CheckCircleOutlined/></span>
                        </div>                                                
                        <TextArea 
                            value={notes}
                            onChange={onChangeNotes}
                            className="bmg-textarea" 
                            rows={6} 
                            placeholder={t('user.notes')} 
                        />
                    </div>
                </div>                
            </div>
        </div>
    )
};

export default PresentationTab;