import React,{useEffect, useState, useRef} from "react";
//import moment from 'moment';
import { useNavigate,useSearchParams,useNavigationType } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
import { useSelector,useDispatch } from 'react-redux';
import { Table, Pagination } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { DeleteOutlined,EditOutlined,TeamOutlined } from '@ant-design/icons';
import { useNotification } from '../../../hooks/NotificationProvider';
import { ListClients, EditClientProps } from "../../../services/Client";
import { saveLists } from '../../../features/lists/listsSlice';
import { saveClient } from '../../../features/client/clientSlice';
import { clientBmgStatus } from '../../../utils/constants';
import { getCountry, updateList ,findObjDifferences } from "../../../utils/miscHelpers"
import HeaderBar from "../../../components/layout/HeaderBar";
import LabelForm from "../../../components/label/LabelForm";
import PopOverForm from "../../../components/elements/PopOverForm";
import PopOver from "../../../components/elements/PopOver";
import ToDoIcon from "../../../components/todo/ToDoIcon";

const section = 'client';

const ClientList = () => {
  
  const defaultPageSize = 40;
  
  const storedFilters = useSelector(state => state.filters.value && state.filters.value[section] ? state.filters.value[section] : {});
  const storedLabelList = useSelector(state => state.lists.value && state.lists.value['label'] ? state.lists.value['label'] : []);  
  const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);  

  const [searchParams, setSearchParams] = useSearchParams();
  const actualPage = searchParams.get('page') || 1;

  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const dispatch = useDispatch();
  // const { t } = useTranslation();
  const { openNotification } = useNotification();
  const [listItems, setListItems] = useState(storedList);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [totalPages, setTotalPages] = useState(100);
  
  const [sortBy, setSortBy] = useState('cdate');
  const [orderBy, setOrderBy] = useState(-1);

  const prevDependencies = useRef({ actualPage,currentPage, pageSize, storedFilters });

  useEffect(() => {

    const fetchData = async (hasFilterDiff) => {
      try {        
        const sort= {};
        sort[sortBy] = orderBy;    
        
        const goPage = hasFilterDiff ? 1 : actualPage;

        // Get list
        const {resultList, total} = await ListClients({page:goPage,pageSize:pageSize,filters:{...storedFilters,...{sort:sort}}});        

        setListItems(resultList);        
        setTotalPages(total);
        setCurrentPage(goPage);    
        setSearchParams({ page: Number(goPage) });            
        dispatch(saveLists({ section : section, data : resultList}));
        return;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };      
    setCurrentPage(actualPage);
    const hasFilterDiff = findObjDifferences(prevDependencies.current.storedFilters,storedFilters);
    if (
      prevDependencies.current.actualPage !== actualPage ||
      prevDependencies.current.pageSize !== pageSize ||
      Object.keys(hasFilterDiff).length ||
      navigationType === 'POP'
    ) {
      fetchData(Object.keys(hasFilterDiff).length);      
      prevDependencies.current = { actualPage, pageSize, storedFilters };            
    }
  }, [actualPage,pageSize,storedFilters]);


  // useEffect(() => {
  //   fetchData();    
  // }, [currentPage,pageSize,storedFilters]); 

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'sort') {
      setSortBy(sorter.columnKey);
      setOrderBy(sorter.order === 'ascend' ? 1 : -1);
    }    
  };
  const Edit = (record) =>{    
    navigate("/client/form",{state:record});
  }
  const Delete = (record) =>{
    //console.log('Delete record',record);
  }
  // 1sr column checkboxes
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  };

  const linkMe = async (item)=>{    
    dispatch(saveClient(item));
    navigate("/client/detail");
  };

  const onPaginationChange = (page,pageSize) => {  
    setSearchParams({ page: Number(page) });
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onLabelUpdated = (record) => {
    const updatedList = updateList(storedList,record); 
    setListItems(updatedList);                
    dispatch(saveLists({ section : section, data : updatedList}));
    return true;
  };

  const onEditTodo = (todoUpdated) =>{    
    if (todoUpdated && todoUpdated._id) {
      let eleToModified = storedList.find((ele)=>  ele._id === todoUpdated.targetId )
      if (eleToModified && eleToModified._id) {
        const eleModified = {...eleToModified, todo : todoUpdated};
        const updatedList = updateList(storedList,eleModified); 
        setListItems(updatedList);
        dispatch(saveLists({ section : section, data : updatedList}));
      }
    }    
  };

   const onPopClientBmgStatusChange = async (operation,props)=>{
     try {
       const itemUpdated = await EditClientProps(operation._id,props);
       if (itemUpdated && itemUpdated._id) {  
          dispatch(saveClient(itemUpdated));
         const updatedList = updateList(storedList,itemUpdated); 
         setListItems(updatedList);      
         dispatch(saveLists({ section : section, data :updatedList}));
         openNotification('success','Modification Enregistrée');
       } else {
         openNotification('error','Probléme lors de la sauvegarde');
       }
     } catch (error) {
         console.error('Error onPopClientBmgStatusChange EditClientProps:', error);
         openNotification('error','Catch / Probléme lors de la sauvegarde');
     }     
  }; 

  //console.log(listItems);
  let columns = [
    { 
      key: "name", 
      title: "Nom", 
      dataIndex: "name", 
      sorter: {},
      width: '200px',
      fixed: 'left',      
      render: (value,record) => { 
        return ( 
        <> 
          <div className="custom-name capitalize link" onClick={()=>linkMe(record)}>{value}</div> 
        </> 
        ); 
      },      
      }, 
      { 
        key: "_admin", 
        title: "Géré par" ,
        dataIndex: "_admin", 
        sorter: (a, b) => {          
          if (a && b) return a._admin.lastName.localeCompare(b._admin.lastName);
        },
        render: (value, record) => { 
          return ( 
            <> 
              <div className="capitalize-full">{value && value._id ? value.firstName  : '-'}</div> 
            </> 
          ); 
        },          
      },
      { 
        key: "activity", 
        title: "Activité" ,
        dataIndex: "activity", 
        sorter: {},
        render: (value) => { 
          return ( 
            <> 
              <div className="capitalize-full">{value}</div> 
            </> 
          ); 
        },          
      },  
      { 
        key: "bmgStatus", 
        title: "Type" ,
        dataIndex: "bmgStatus", 
        render: (value,record) => { 
          let clientStatus = clientBmgStatus.find((ele)=>ele.value === value);          
          return ( 
          <> 
          <div>
            <PopOver 
                  content={
                      <span className="capitalize tag" style={{color:clientStatus ? 'white' : 'black',backgroundColor:clientStatus ? clientStatus.color : 'white'}}>{clientStatus ? clientStatus.label : '+'}</span>
                  }              
                  prop="clientBmgStatus" 
                  handlerMethod={onPopClientBmgStatusChange}
                  items={clientBmgStatus}
                  user={record}
              />              
          </div>
 
          </> 
          ); 
        },
        sorter: {},
      },
      { 
        key: "city", 
        title: "Ville" ,
        dataIndex: "city", 
        sorter: {},
        render: (value) => { 
          return ( 
            <> 
              <div className="capitalize-full">{value}</div> 
            </> 
          ); 
        },   
      },  
      { 
        key: "country", 
        title: "Pays" ,
        dataIndex: "country", 
        sorter: {},
        render: (record) => { 
          return ( 
          <> 
            <div className="capitalize-full">{record ? getCountry(record ,'fr') : '-'}</div> 
          </> 
          ); 
        },
      },   
      { 
        key: "quotes", 
        title: "Devis" ,
        dataIndex: "quotes", 
        sorter: {},
        render: (value,record) => { 
          return ( 
          <> 
            <div>{value ? 
              <PopOverForm
                content={<TeamOutlined/>}
                form="quote"
                color={'white'}
                handlerMethod={(result)=>{console.log("Quote handlerMethod ",result)}}
                data={value && value.length ? {...value[0] ,...{clientName:record.name}} : {}}
              />                
             : '-'
             }
            </div> 
          </> 
          ); 
        },        
      },        
      { 
        key: "label", 
        title: "Labels",
        dataIndex: "label", 
        render: (value,record) => { 
          return(
            <LabelForm 
              section={section}
              labels={value}
              record={record}
              handlerMethod={onLabelUpdated}
              storedLabelList={storedLabelList || []}
          />
          );
        },      
      },      
      { 
        key: "action", 
        title: "Actions", 
        width: '120px',
        fixed: 'right',
        render: (record) => { 
          return ( 
            <> 
              <div className="flex"> 
                <PopOverForm
                  content={
                    <ToDoIcon section={section} record={record}/>
                  }
                  form="todo"
                  handlerMethod={onEditTodo}
                  data={record.todo}
                  target='client'
                  targetData={record}
                />                
                <EditOutlined 
                  title="Editer"
                  className='icon-table'
                  onClick={() => Edit(record)} 
                /> 
                <DeleteOutlined 
                  title="Effacer"
                  className='icon-table'
                  onClick={() => Delete(record)} 
                /> 
              </div> 
            </> 
          ); 
        }, 
      },                  
  ];
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    
  columns = columns.map(col => ({
    ...col,
    fixed: isMobile ? undefined : col.fixed,
  }));
  return (
    <div className="container">
      <HeaderBar 
        actionContent={
          <div className="header-actions" style={{ marginLeft:"16px"}}>
            <div onClick={()=>navigate('/client/form?tsId='+Date.now())} className="secondary-button" >+</div>
          </div>
        }        
        hasFilters={true} 
        hasSearch={true} 
        hasSort={false} 
        section={section} 
        storedFilters={storedFilters} 
        setCurrentPage={setCurrentPage}
      />
      <div className="content">    
        <div>
          <Table 
            dataSource={listItems} 
            rowKey="_id"
            columns={columns} 
            scrollToFirstRowOnChange={true}
            className="capitalize custom-checkbox custom-table"
            onChange={onTableChange} 
            pagination={{
              onChange:onPaginationChange,
              position:['bottomCenter'],
              current:currentPage,
              pageSize,
              total:totalPages,
              showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`
            }}             
            scroll={{
              y: 620,
              x: 1300
            }}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
          />
          {/* <Pagination
            showSizeChanger={true} 
            current={currentPage}
            pageSize={pageSize}                
            total={totalPages} 
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}       
            onChange={onPaginationChange}
            className="pagination-list"
          /> */}
        </div> 
      </div>
    </div>
  );
};

export default ClientList ;
