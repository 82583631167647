import React from 'react';
// import { useTranslation } from 'react-i18next';
import QuoteDetail from '../../../components/operation/elements/QuoteDetail'
import { capitalize } from '../../../utils/miscHelpers';

const StepFour =  ({
        section, 
        missionSlots,
        quote,
        quoteVat,
        quoteCurrency,
        quoteHeader,
        quoteFooter,
        quoteFees,
        quoteExpire,
        clientList,
        bmgStatus,name,client,type,bookedTotal,dressCode,body,bodyClient,instruction,contact,languages
    }) => {

    // const { t } = useTranslation();    

    const clientName = clientList.find((ele)=> {return ele.value === client;});    
    
    return(
        <div className="step-content">    
            <div className='h2 step-title'>Résumé</div>        
            <div className='step-container'>
                <div className='step-list'>   
                    <div><span className='h2'>Intitulé :</span> {capitalize(name)}</div>
                    <div><span className='h2'>Client :</span> {clientName ? capitalize(clientName.label) : ''}</div>
                    <div><span className='h2'>Type :</span> {capitalize(type)}</div>
                    <div className='capitalize'><span className='h2'>Langues :</span> <span className='uppercase'>{languages.join(', ')}</span></div>
                    <div style={{marginTop:16,marginBottom:16}}><span className='h2'>Déscription Client (PDF) :</span> {bodyClient}</div>
                    <div style={{marginTop:16,marginBottom:16}}><span className='h2'>Déscription Staff (App) :</span> {body}</div>
                    <QuoteDetail
                        name={name}
                        missionSlots={missionSlots}
                        quote={quote}
                        quoteFees={quoteFees}
                        quoteVat={quoteVat}
                        quoteCurrency={quoteCurrency}
                        quoteHeader={quoteHeader}
                        quoteFooter={quoteFooter}
                        quoteExpire={quoteExpire}                        
                    />
                </div>  
            </div>         
        </div>
    )
};

export default StepFour;