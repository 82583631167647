import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Select,Checkbox, Drawer,Input,Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { getMedia } from "../../services/File"

import MessageForm from '../../components/message/MessageForm'
import { BmgStatus, Genders, BmgStatusArr} from "../../utils/constants"
import { getAge, scoreContent,capitalize ,formatListWithValueLabel,doS} from "../../utils/miscHelpers"

const UserListDrawer = ({record, openDrawer, handlerMethod}) => {    
    
    const { t } = useTranslation();

    const storedLabelList = useSelector(state => state.lists.value && state.lists.value['label'] ? state.lists.value['label'] : []);    

    const [avatarFiles, setAvatarFiles] = useState([]);
    const [userList, setUserList] = useState([]);
    const [originalUserList, setOriginalUserList] = useState([]);
    const [labels, setLabels] = useState([]);
    const [bmgStatus, setBmgStatus] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [fullCheckBox, setFullCheckBox] = useState(false);
    const [filterDisplay, setFilterDisplay] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [gender, setGender] = useState([1,2]);

    useEffect(() => {
        const prepareUsers = async () => {
            let cleanUsers = [];
            if (record?.users?.length) {
                cleanUsers = record.users.filter(user => user.bmgStatus !== 6); // Filter Refused
                
                // Apply Labels
                if (cleanUsers.length) {
                    cleanUsers = cleanUsers.map(user => {
                        if (user?.label?.length) {
                            const fullLabels = user.label
                                .map(labelId => storedLabelList.find(lb => lb._id === labelId))
                                .filter(label => label && label._id); 
    
                            return { ...user, label: fullLabels };
                        }
                        return user; 
                    });
                }
            }
            setUserList(cleanUsers);
            setOriginalUserList(cleanUsers);
        };    
        if (openDrawer) prepareUsers();
    }, [openDrawer,record, storedLabelList]);
    
    useEffect(() => {
        const fetchAvatarFiles = async () => {
            if (userList && userList.length) {
                const avatarFilesPromises = userList.map(async (user) => {                    
                    if (!user || !user.medias || !user.medias.length) return false;                    
                    const avatarArr = user.medias.find((media) => media.section === 'avatar');
                    const avatarFile = await getMedia({...avatarArr, size: 'small'});
                    return avatarFile;
                });
                const resolvedAvatarFiles = await Promise.all(avatarFilesPromises);
                setAvatarFiles(resolvedAvatarFiles);
            } else {
                setAvatarFiles([]);
            }
        };
        fetchAvatarFiles();
    }, [userList]);

    useEffect(() => {
        const applyFilters = () => {
            let filteredUsers = [...originalUserList];
    
            if (labels?.length) {
                filteredUsers = filteredUsers.filter(user => 
                    user?.label?.some(lb => labels.includes(lb._id))
                );
            }
    
            if (searchTerm) {
                const lowerSearchTerm = searchTerm.toLowerCase();
                filteredUsers = filteredUsers.filter(user => 
                    user.firstName.toLowerCase().includes(lowerSearchTerm) ||
                    user.lastName.toLowerCase().includes(lowerSearchTerm)
                );
            }
    
            if (bmgStatus?.length) {
                filteredUsers = filteredUsers.filter(user => bmgStatus.includes(user.bmgStatus));
            }
    
            if (gender?.length) {
                filteredUsers = filteredUsers.filter(user => gender.includes(user.gender));
            } else {
                filteredUsers = [];
            }

            setUserList(filteredUsers);
        };
    
        applyFilters();
    }, [labels, bmgStatus, gender, searchTerm, originalUserList]);

    const linkMeToUser = async (user,index)=>{
        try {            
            return window.open(process.env.REACT_APP_PUBLIC_BO_URL+'/user/detail?staffId='+user._id);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };    
    
    const onCheckbox = (staff)=>{
        let newSelectedUsers = [...selectedUsers];
        if (newSelectedUsers.includes(staff)) {
            newSelectedUsers = newSelectedUsers.filter((ele)=>ele._id !== staff._id);
        } else {
            newSelectedUsers.push(staff);
        }
        setSelectedUsers(newSelectedUsers);
    };

    const onFullCheckbox = () =>{
        let staff = []
        if (!fullCheckBox) staff = userList;        
        setSelectedUsers(staff);      
        setFullCheckBox(!fullCheckBox);
    };

    const onChangeTerm = (e) =>{        
        setSearchTerm(e.target.value);    
    };    

    const onSubmit = ()=>{        
        closeDrawer();
    };

    const closeDrawer = () =>{
        handlerMethod(false);
        setUserList([]);
        setSelectedUsers([]);
        setOriginalUserList([]);     
        setFullCheckBox(false);   
        setLabels([]);
        setBmgStatus([]);
        setSearchTerm('');
        setGender([1,2]);
        setFilterDisplay(false);
    };
    
    const toogleFilters = ()=>{
        setFilterDisplay(filterDisplay ? false : true);
    };

    const clearFilters = () =>{
        setLabels([]);
        setBmgStatus([]);
        setGender([1,2]);  
        setSearchTerm('');
        setFilterDisplay(false);
    };

    const tagRender = (props) => {
            
        const { label, value, closable, onClose } = props;

        const item = storedLabelList.find((ele)=>ele._id === value);

        const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                color={"white"}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                className="tag" 
                style={{
                    padding:"5px",
                    backgroundColor: item && item.color ? item.color : 'var(--accent-color)',
                    color:"white",
                }}
            >{label}</Tag>
        );
    };
    
    return (
        <>
            <Drawer
                title={
                    <div className="h3b grey-dark message-drawer-title ">                                            
                        <div style={{marginLeft:'16px'}}>{capitalize(record.name)} - {userList.length}/{originalUserList.length} Demande{doS(userList.length,'number')}</div>
                    </div>
                }
                placement="right"
                width={600}
                onClose={closeDrawer}
                open={openDrawer}
                className=''      
            >  
                <div className='flex-row' style={{borderBottom:'solid 2px var(--accent-light-alt-color)',position:'relative',top:0,left:0,zIndex:100}}>
                    <div style={{marginBottom:'16px'}} >
                        <div className='flex' style={{ alignContent: 'center',justifyContent: 'space-between',alignItems: 'center',marginBottom:'16px'}}>
                            <div style={{width:'68%'}}>
                                <Input placeholder="Rechercher" allowClear onChange={onChangeTerm} value={searchTerm}/>
                            </div>                        
                            <div style={{textAlign:'right'}}>
                                {(gender.length !== 2 || labels.length>0 || bmgStatus.length>0) && 
                                    <span title='Réinitialiser les filtres' onClick={clearFilters} className='h4b link' style={{fontWeight:600,marginRight:'16px'}}>Réinitialiser</span>
                                }
                                <span title={(filterDisplay ? 'Fermer' : 'Voir')+' les Filtres'} onClick={toogleFilters} className='h4b link' >{filterDisplay ? 'Fermer X' : 'Filtres +'}</span>
                            </div>
                        </div>  
                        {filterDisplay && 
                            <div style={{margin:'16px 0 16px 0',padding:'16px',borderRadius:'6px',border:'solid 1px var(--accent-light-alt-color)'}}>                            
                                <div className='flex' style={{alignContent: 'center',justifyContent: 'space-between',alignItems: 'center'}}>
                                    <h4 >{t('filter.bmgStatus')}</h4>
                                    <div>
                                        <Checkbox.Group
                                            options={Genders}
                                            defaultValue={gender}
                                            value={gender}
                                            onChange={setGender}
                                            className="custom-checkbox"
                                        />
                                    </div>
                                </div>
                                <div>                                
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{
                                        width: '100%',
                                        
                                        }}
                                        optionLabelProp="label"
                                        optionFilterProp="label"
                                        placeholder="Filtrer par Statuts"
                                        defaultValue={[]}
                                        value={bmgStatus}
                                        onChange={setBmgStatus}
                                        options={BmgStatusArr}
                                        className="filter-select"
                                    />       
                                </div>                            
                                <div>
                                    <h4 >{t('filter.labels')}</h4>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        optionLabelProp="label"
                                        optionFilterProp="label"
                                        placeholder="Filtrer par Labels"
                                        defaultValue={[]}
                                        tagRender={tagRender}
                                        value={labels}
                                        onChange={setLabels}
                                        options={formatListWithValueLabel(storedLabelList)}
                                        className="filter-select"
                                    />            
                                </div>
                            </div>
                        }                        
                        <div>
                            <Checkbox style={{marginRight:'16px'}} className='custom-checkbox' checked={fullCheckBox} onChange={()=> onFullCheckbox()}/>
                            <span className='h4b'>Tout Séléctionner</span>
                        </div>                                              
                    </div>
                </div>
                <div style={{marginTop:'3px',maxHeight:filterDisplay? '28vh' : '55vh',overflowY:'auto',justifyContent: "flex-start"}}>                    
                    {userList && userList.length > 0 && userList.map((user, index) => (
                        <div key={user._id} className="tab-table-row-block" style={{zIndex:0,marginBottom:'16px',display:'flex',alignItems: "center"}}>
                            <Checkbox style={{marginRight:'6px'}} className='custom-checkbox' checked={selectedUsers.find((ele)=> ele._id === user._id)} onChange={()=> onCheckbox(user)}/>
                            <div> 
                                <img alt="user" src={avatarFiles[index] ? avatarFiles[index] : '/img/placeholder-avatar.jpg'} className="avatar-small" />                                                    
                            </div>
                            <div style={{marginLeft:'6px'}}>
                                {(user.score !== null && user.score !== 0) && 
                                    <div className="score-list-user-small">{scoreContent(user.score,'score-small')}</div>
                                }
                                <div className="custom-name capitalize bold link" onClick={()=>linkMeToUser(user,index)}>{user.firstName +' '+user.lastName}</div> 
                                <div>
                                    <span className='tab-table-row-span'>({user.gender === 1 ? 'H' : 'F'})</span>
                                    <span className='tab-table-row-span'>{user.birthday ? getAge(user.birthday)+' ans' : ''}</span>
                                    <span className='tab-table-row-span'>{user.height ? (user.height/100).toFixed(2)+'m' : ''}</span>
                                </div>
                                <div style={{marginTop:'16px'}}>
                                    <span className="capitalize tag" style={{backgroundColor:user.bmgStatus ? BmgStatus[user.bmgStatus].color : BmgStatus[0].color}}>{user.bmgStatus ? BmgStatus[user.bmgStatus].label : BmgStatus[0].label}</span>
                                    {user.label && user.label.map((label,lidx)=>
                                        <Tag
                                            key={lidx.toString()}
                                            color={"white"}
                                            closable={false}
                                            className="tag" 
                                            style={{
                                                margin:"4px",
                                                width:'80px',
                                                display:'inline',
                                                backgroundColor:label.color,
                                                color:'white',
                                                fontSize:'10px',
                                            }}
                                        >
                                            {label.name}
                                        </Tag>
                                    )}
                                </div>
                                
                            </div>    

                        </div>
                    ))}
                    {!userList || userList.length === 0 &&
                        <div className="tab-table-row-block" style={{zIndex:0,marginBottom:'16px',display:'flex',alignItems: "center"}}>
                            <span>Aucun Utilisateur</span>
                        </div>
                    }
                </div>                
                <MessageForm 
                    formSize='large'
                    messageUsers={selectedUsers} 
                    defaultMessage={''}
                    handlerMethod={onSubmit}
                    checkedData={{}}
                />            
            </Drawer>
        </>
    );
};
export default UserListDrawer;
