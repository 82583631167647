import React,{useEffect} from 'react';
import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';
import { Document, Page, Text, View, Image, StyleSheet, Font } from '@react-pdf/renderer';

import { doS,
    getLevelHospitality, 
    getLanguageLevel, 
    capitalize, 
    isSundayOrHoliday,
    getGender, 
    getDiplomas,
    FormatMissionSlotsByLocation,
    FormatMissionSlotsByRoles,
    calculateHoursDifference,
    getAddress,
    calculateTotals,
    getLanguage,
    getCountry,    
    QuoteTotals,
    formatNumber,
    GetSlotStaff,
    getAge } from "../../../utils/miscHelpers"

import { paymentSpecials } from "../../../utils/constants"
import PoppinsRegular from '../../../assets/font/Poppins-Regular.ttf';
import PoppinsBold from '../../../assets/font/Poppins-Bold.ttf';
import PoppinsItalic from '../../../assets/font/Poppins-Italic.ttf';
import logoBmg from '../../../assets/images/logo_tagline_accent.png'

import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);

const locale = require('dayjs/locale/fr');
dayjs.locale(locale);

const dateFormat = {
   fr: 'dddd D MMMM YYYY',
   en: 'dddd Do of MMMM YYYY'
};

const CoverPage = ({selectedTypes,storedOperation, staffList, version}) => {

    return (
        <>
            <Page size="A4" orientation="portrait" style={styles.cover}>
                <View style={styles.logoContainer}>
                    <Image style={styles.logo} src={logoBmg} />
                </View>
            </Page>            
        </>
    );

};

const FooterSection = ({ selectedTypes, staffList, selectedUsers,page}) =>{
    const { t } = useTranslation();  
    let totalPages = 0;

    const cleanSelectedTypes = [...selectedTypes].filter((ele)=>ele !== 'planningContact')

    if (selectedUsers) totalPages += selectedUsers.length;
    if (cleanSelectedTypes) {
        if (cleanSelectedTypes.includes('user')) totalPages += cleanSelectedTypes.length - 1;
        if (!cleanSelectedTypes.includes('user')) totalPages += cleanSelectedTypes.length;
    } 

    return(
        <View style={styles.footer}>
            <Text> {t('pdfOperation.footer')} - {dayjs().year()}</Text>
            <Text style={styles.smallerFont}>{page} / {totalPages}</Text>            
        </View>
    );
};

const MissionSection = ({lang,storedOperation,staffList=[]}) =>{

    const { t } = useTranslation(); // {t('pdfOperation.info.lang')}    
    
    const locations = FormatMissionSlotsByLocation(storedOperation && storedOperation.missionSlots ? storedOperation.missionSlots : [])
    return (
        <View>
            <View style={[styles.block,{marginTop:16}]}>
                {locations && locations.length > 0 && locations.map((location,idx)=>{
                    const roles = FormatMissionSlotsByRoles(location.missionSlots);                           
                    return(
                        <View key={'loca'+idx.toString()}>
                            <Text style={[styles.accent, styles.bold]}>{getAddress(location)}</Text>
                            {roles && Object.keys(roles).length > 0 && Object.keys(roles).map((roleKey,ridx)=>{   
                                const role = roles[roleKey];
                                let roleTotal = 0;
                                let totalDuration = 0;
                                const rKey = 'loca'+idx.toString()+'-role'+ridx.toString();
                                return(
                                    <View key={rKey} style={{marginTop:6,marginBottom:6}}>
                                        <View key={rKey+'-slot0'} style={[styles.rowWithColumns,{marginBottom:6}]}>
                                            <Text style={[styles.bold,{width:500}]}>{capitalize(role.name)} - {formatNumber(role.cost,role.currency.toUpperCase())}/HR</Text>
                                            <Text style={{fontSize:10,width:70,textAlign:'center'}}>{t('pdfOperation.missionSection.duree')}</Text>
                                            <Text style={{fontSize:10,marginLeft:16,width:50,textAlign:'center'}}>Staff</Text>
                                            <Text style={{fontSize:10,marginLeft:16,width:150,textAlign:'center'}}>Total</Text>
                                        </View>                                          
                                        {role && role.missionSlots && role.missionSlots.length > 0 && role.missionSlots.map((slot, sidx)=>{
                                            let { totalHrs, overTimeHrs, normalHrs} = calculateHoursDifference(slot.timeSlot.stime,slot.timeSlot.etime);
                                            const isHoliDay = isSundayOrHoliday(slot.eday);
                                            let minHr = false;

                                            // Minimun Hours billed
                                            if (totalHrs < 4) {
                                                let difference = 4 - totalHrs;
                                                normalHrs += difference;
                                                totalHrs = 4;
                                                minHr=true;
                                            }

                                            totalDuration += Number(slot.workForce) * totalHrs;

                                            const normalHrsWorkForce = Number(slot.workForce) * normalHrs;
                                            const overTimeHrsWorkForce = Number(slot.workForce) * overTimeHrs;

                                            const normalHrsmissionTotal   = calculateTotals(role.cost,normalHrsWorkForce, isHoliDay,'normal'); // Number(mission.role?.cost) * ( isHoliDay ? total.holiday : 1) * normalHrsWorkForce ;
                                            const overTimeHrsmissionTotal = calculateTotals(role.cost,overTimeHrsWorkForce,isHoliDay,'overTime');  //Number(mission.role?.cost) * ( isHoliDay ? total.holiday : 1) * total.extra * overTimeHrsWorkForce ;
                                            const slotTotal            = normalHrsmissionTotal + overTimeHrsmissionTotal;

                                            roleTotal += slotTotal;

                                            return(
                                                <View key={rKey+'-slot'+(sidx+1)} style={styles.rowWithColumns}>                                                    
                                                    <Text style={{fontSize:11,width:500}}>{capitalize(dayjs(slot.eday).format(dateFormat[lang]))} {t('pdfOperation.date.from')} {slot.timeSlot.stime} {t('pdfOperation.date.to')} {slot.timeSlot.etime} {isHoliDay?'*':''} {overTimeHrs ? '**':''} {minHr ? '***':''}</Text>
                                                    <Text style={{fontSize:11,width:70,textAlign:'right'}}>{totalHrs}h</Text>
                                                    <Text style={{fontSize:11,marginLeft:16,width:50,textAlign:'center'}}>{slot.workForce}</Text>
                                                    <Text style={{fontSize:11,marginLeft:16,width:150,textAlign:'right'}}>{formatNumber(slotTotal,role.currency.toUpperCase())}</Text>
                                                </View>
                                            )
                                        })}                    
                                        <View key={rKey+'-slot'+(role.missionSlots.length+1)} style={styles.rowWithColumns}>
                                            <Text style={{width:500}}></Text>
                                            <Text style={[styles.bold,{fontSize:11,width:70,textAlign:'right'}]}>{totalDuration}h</Text>
                                            <Text style={{marginLeft:16,width:50}}></Text>
                                            <Text style={[styles.bold,{fontSize:11,marginLeft:16,width:150,textAlign:'right'}]}>{formatNumber(roleTotal,role.currency.toUpperCase())}</Text>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    )
                })}
            </View>
            <View>
                <Text style={{fontSize:9}}>* {t('pdfOperation.missionSection.footer.holidayPerc')} (+{paymentSpecials.holidayPerc})</Text>
                <Text style={{fontSize:9}}>** {t('pdfOperation.missionSection.footer.extraPerc')} (+{paymentSpecials.extraPerc})</Text>
                <Text style={{fontSize:9}}>*** {t('pdfOperation.missionSection.footer.minimum')}</Text>
            </View>
        </View>            
    );
};

const InfoPage = ({lang,selectedTypes,page,storedOperation, selectedUsers,staffList, version}) => {
    
    const { t } = useTranslation();

    if (storedOperation && storedOperation._id) {
        return (
            <>
                <Page size="A4" orientation="portrait" style={styles.page}>
                     <View style={styles.section}>                    
                        <Text style={[styles.bold,styles.h3]}>{capitalize(storedOperation._client.name)}</Text>
                        <Text style={styles.h2}>{capitalize(storedOperation.name)}</Text>
                        <Text >{t('pdfOperation.date.du')} {dayjs(storedOperation.sdate).format(dateFormat[lang])} {t('pdfOperation.date.au')} {dayjs(storedOperation.edate).format(dateFormat[lang])}</Text>                    
                        <View style={[styles.block,{marginTop:32,marginBottom:32}]}>
                            <View style={styles.inline}>
                                <Text style={styles.accent}>{t('pdfOperation.info.typeOpe')}: </Text>
                                <Text>{capitalize(storedOperation.type)}</Text>
                            </View>          
                            {/* <View style={styles.inline}>
                                <Text style={styles.accent}>{t('pdfOperation.info.admin')}:  </Text>
                                <Text>{storedOperation._admin ? capitalize(storedOperation._admin.firstName) + ' ' + capitalize(storedOperation._admin.lastName) : '-'}</Text>
                            </View> */}
                            <View style={styles.inline}>
                                <Text style={styles.accent}>{t('pdfOperation.info.lang')}: </Text>
                                {storedOperation.languages && storedOperation.languages.map((opLang,index)=>
                                    <View key={'lang'+index.toString()}>
                                        <Text key={index.toString()} style={{marginRight:'6px'}}>{getLanguage(opLang,lang)}</Text>
                                    </View>
                                )}
                            </View>
                            <View style={[styles.inline]}>
                                <Text style={styles.accent}>{t('pdfOperation.info.staff')}: </Text>
                                <Text>{storedOperation.bookedTotal ? storedOperation.bookedTotal : '-'}</Text>
                            </View>
                        </View> 
                        <Text style={styles.body}>{storedOperation.bodyClient}</Text>
                        <MissionSection storedOperation={storedOperation} lang={lang}/>      
                        <Text style={[styles.bold,styles.h3]}> </Text>                  
                    </View>   
                    <FooterSection selectedTypes={selectedTypes} page={page} selectedUsers={selectedUsers} staffList={staffList}/>
                </Page>            
            </>
        );
    } else {
        return null;
    }
};

const MissionPage = ({lang,selectedTypes,page,storedOperation, selectedUsers,staffList, version}) => {    

    const { t } = useTranslation(); // {t('pdfOperation.info.lang')}

    return (
        <>
            <Page size="A4" orientation="portrait" style={styles.page}>
                 <View style={styles.section}>                    
                    <Text style={styles.h2}>{t('pdfOperation.missionPage.mission')}{doS(storedOperation.missionSlots)} & {t('pdfOperation.missionPage.roles')}</Text>
                    <MissionSection lang={lang} storedOperation={storedOperation}/>
                </View>   
                <FooterSection selectedTypes={selectedTypes} page={page} selectedUsers={selectedUsers} staffList={staffList}/>
            </Page>            
        </>
    );
};

const PlanningPage = ({lang,selectedTypes,page,storedOperation, selectedUsers,staffList, version}) => {    

    const { t } = useTranslation(); // {t('pdfOperation.info.lang')}
    
    const locations = FormatMissionSlotsByLocation(storedOperation && storedOperation.missionSlots ? storedOperation.missionSlots : [])

    return (
        <>
            <Page size="A4" orientation="portrait" style={styles.page}>
                 <View style={styles.section}>                    
                    <Text style={[styles.h2,{margin:0}]}>{capitalize(storedOperation.name)}</Text>
                    <Text style={[styles.h4,{margin:0}]}>{t('pdfOperation.planning.title')} Be My Guest</Text>                    
                    <View style={[styles.block,{marginTop:10}]}>
                        {locations && locations.length > 0 && locations.map((location,idx)=>{
                            const roles = FormatMissionSlotsByRoles(location.missionSlots);                           
                            return(
                                <View key={'loca'+idx.toString()}>
                                    <Text style={[styles.accent, styles.bold,{marginTop:16}]}>{getAddress(location)}</Text>
                                    {roles && Object.keys(roles).length > 0 && Object.keys(roles).map((roleKey,ridx)=>{   
                                        const role = roles[roleKey];
                                        const rKey = 'loca'+idx.toString()+'-role'+ridx.toString();
                                        return(
                                            <View key={rKey} style={{marginTop:6,marginBottom:6}}>
                                                <View key={rKey+'-slot0'} style={[styles.rowWithColumns,{marginBottom:16}]}>
                                                    <Text style={[styles.bold,{width:500}]}>{capitalize(role.name)}</Text>
                                                </View>                                          
                                                {role && role.missionSlots && role.missionSlots.length > 0 && role.missionSlots.map((slot, sidx)=>{                                            
                                                    const slotStaff = GetSlotStaff(storedOperation._id,slot,staffList);
                                                    return(
                                                        <View key={rKey+'-slot'+(sidx+1)} style={{marginLeft:16,marginBottom:16}}>                                                    
                                                            <Text style={{fontWeight:555,marginBottom:16,fontSize:10,width:500}}>{slotStaff && slotStaff.length ? slotStaff.length : 0} Staff{doS(slotStaff)} {'>'} {capitalize(dayjs(slot.eday).format(dateFormat[lang]))} {t('pdfOperation.date.from')} {slot.timeSlot.stime} {t('pdfOperation.date.to')} {slot.timeSlot.etime}</Text>
                                                            <View>
                                                                {slotStaff && slotStaff.length > 0 && slotStaff.map((staffSlot,sfsidx)=>(
                                                                    <View key={rKey+'-slotStaff'+(sfsidx+1)} style={[styles.rowWithColumns,{marginTop:3,marginBottom:3}]}>
                                                                        <div style={{width:200,marginLeft:16}}>
                                                                            <Text style={{fontSize:11}}>{capitalize(staffSlot.firstName)} {capitalize(selectedTypes.includes('planningContact') ? staffSlot.lastName : staffSlot.lastName[0]+'.')}</Text>
                                                                        </div>
                                                                        {selectedTypes.includes('planningContact') && 
                                                                            <div style={{margin:0,width:100}}>
                                                                                <Text style={{fontSize:10}}>{staffSlot.phone}</Text>
                                                                            </div>
                                                                        }
                                                                        {selectedTypes.includes('planningContact') && 
                                                                            <div style={{margin:0,width:200}}>
                                                                                <Text style={{fontSize:10}}>{staffSlot.email}</Text>
                                                                            </div>                                                
                                                                        }
                                                                    </View>
                                                                ))}
                                                            </View>
                                                        </View>
                                                    )
                                                })}
                                            </View>
                                        )
                                    })}
                                </View>
                            )
                        })}
                    </View>
                </View>
                <FooterSection selectedTypes={selectedTypes} page={page} selectedUsers={selectedUsers} staffList={staffList}/>
            </Page>            
        </>
    );
};


const QuotePage = ({selectedTypes,page,storedOperation, selectedUsers,staffList, version}) => {
    
    const { t } = useTranslation(); // {t('pdfOperation.quote.lang')}

    const quoteTotals = QuoteTotals(storedOperation.missionSlots,storedOperation.quoteFees,storedOperation.quoteVat);
 
    return (
        <>
            <Page size="A4" orientation="portrait" style={styles.page}>
                <View style={[styles.section,styles.smallSection]}>
                    <View  style={[styles.rowWithColumns]}>
                        <View>
                            <Text style={[styles.body,styles.bold]}>Be My Guest Sàrl</Text>
                            <Text>Centre administratif</Text>
                            <Text>Chemin des Polonais 16A</Text>
                            <Text>2016 Cortaillod, Switzerland</Text>
                            <Text>+41 78 944 12 79</Text>
                            <Text>info@bemyguest.ch — www.bemyguest.ch</Text>
                            <Text>{t('pdfOperation.quote.vatNum')}: CHE - 114.565.243 TVA</Text>
                            <View style={[styles.inline,{marginTop:6}]}>
                                <Text style={styles.accent}>{t('pdfOperation.quote.eventDate')}: </Text>
                                <Text>{dayjs(storedOperation.sdate).format('DD/MM/YYYY')} {t('pdfOperation.date.au')} {dayjs(storedOperation.edate).format('DD/MM/YYYY')}</Text>
                            </View>
                        </View>  
                        <View>                 
                            <View style={styles.cartouche}>
                                <Text style={[styles.body,styles.bold]}>{capitalize(storedOperation._client.name)}</Text>
                                <Text>{storedOperation._client.address}</Text>
                                {storedOperation && storedOperation._client.addressAlt &&
                                    <Text>{storedOperation._client.addressAlt}</Text>
                                }
                                <Text>{storedOperation._client.zipcode} {capitalize(storedOperation._client.city)}, {getCountry(storedOperation._client.country)}</Text>
                            </View>  
                            <View style={{display:'flex',alignItems:'flex-end',marginTop:16}}>
                                <Text style={[styles.body,styles.accent,styles.bold]}>{t('pdfOperation.quote.quoteRef')} - {storedOperation.quote}</Text>
                                <Text >{t('pdfOperation.quote.done')} {dayjs().format('DD/MM/YYYY')}</Text>   
                                <Text >{t('pdfOperation.quote.validDate')}: {dayjs(storedOperation.quoteExpire).format('DD/MM/YYYY')}</Text>
                            </View>       
                        </View>                    
                    </View>        
                    
                    <View style={{marginTop:16,marginBottom:10}}>  
                        <Text>{storedOperation.quoteHeader}</Text>
                    </View>
                    {(quoteTotals.locations && Object.keys(quoteTotals.locations).length > 0) &&
                        quoteTotals.locations.map((location,index)=>{
                            return(
                                <View key={'location-'+index.toString()} style={{marginTop:6}} >
                                    {/* <hr style={[styles.hr]} /> */}
                                    <View>
                                        <View style={[styles.row,{marginBottom:16}]}>
                                            <Text style={styles.bold} >{capitalize(storedOperation.name)} / </Text>                       
                                            <Text className='capitalize h2'>{getAddress(location.location)}</Text>
                                        </View>
                                        {(location.roles && location.roles.length > 0) &&
                                            location.roles.map((role,idx)=>{
                                                const cost = formatNumber(role.cost,storedOperation.quoteCurrency);
                                                const totalNormal = formatNumber(role.totalNormal,storedOperation.quoteCurrency);     
                                                
                                                return(
                                                    <View key={'role-'+idx} style={{marginBottom:6}}>
                                                        <View  style={styles.rowWithColumns}>
                                                            <Text style={[styles.column,{textAlign:'left',width:160}]}>{capitalize(role.name)}</Text>                                        
                                                            <Text style={[styles.column]}>{role.employees} {t('pdfOperation.quote.rolePosition')}{doS(role.employees,'number')}</Text>
                                                            <Text style={styles.column}>{role.edays.length} {t('pdfOperation.quote.day')}{role.edays && role.edays.length ? doS(role.edays):''}</Text>
                                                            <Text style={[styles.uppercase,styles.column]}>{cost}/hr</Text>
                                                            <Text style={styles.column}>{role.totalNormalHrs} Hrs</Text>
                                                            <Text style={[styles.uppercase,styles.column]}>{totalNormal}</Text>
                                                        </View>
                                                        {role.totalOverTimeHrs !== 0 &&
                                                            <View style={styles.rowWithColumns}>
                                                                <Text style={[styles.column,{width:160}]}>({t('pdfOperation.quote.overtime')})</Text>
                                                                <Text style={[styles.column]}>{role.workForce} {t('pdfOperation.quote.rolePosition')}{doS(role.workForce,'number')}</Text>
                                                                <Text style={styles.column}>{role.edays.length} {t('pdfOperation.quote.day')}{role.edays && role.edays.length ? doS(role.edays):''}</Text>
                                                                <Text style={[styles.uppercase,styles.column]}>{formatNumber((role.cost * quoteTotals.extra),storedOperation.quoteCurrency)}/hr</Text>
                                                                <Text style={styles.column}>{role.totalOverTimeHrs} Hrs</Text>
                                                                <Text style={[styles.uppercase,styles.column]}>{formatNumber(role.totalOverTime,storedOperation.quoteCurrency)}</Text>
                                                            </View>  
                                                        }   
                                                        {role.isHoliDay &&
                                                            <View style={styles.rowWithColumns}>
                                                                <Text style={[styles.column,{width:160}]}>({t('pdfOperation.quote.holiday')})</Text>
                                                                <Text style={[styles.column]}>{role.workForce} {t('pdfOperation.quote.rolePosition')}{doS(role.workForce,'number')}</Text>
                                                                <Text style={styles.column}>{role.edays.length} {t('pdfOperation.quote.day')}{role.edays && role.edays.length ? doS(role.edays):''}</Text>
                                                                <Text style={[styles.uppercase,styles.column]}>{formatNumber((role.cost * quoteTotals.extra),storedOperation.quoteCurrency)}/hr</Text>
                                                                <Text style={styles.column}>{role.totalHolidayHrs} Hrs</Text>
                                                                <Text style={[styles.uppercase,styles.column]}>{formatNumber(role.totalHoliday,storedOperation.quoteCurrency)}</Text>
                                                            </View>
                                                        }                                                                         
                                                    </View>
                                                )
                                            })
                                        }   
                                    </View>
                                </View>
                            )
                        })
                    } 
                    <hr style={[styles.hr,{marginTop:16}]} />
                    <View style={[styles.rowWithColumns]}>
                        <Text>{t('pdfOperation.quote.totalMission')}</Text>
                        <Text style={styles.uppercase}>{formatNumber(quoteTotals.missions,storedOperation.quoteCurrency)}</Text>
                    </View>    
                    {(storedOperation.quoteFees && storedOperation.quoteFees.length !== 0) &&
                        <hr style={styles.hr} />                    
                    }
                    {(storedOperation.quoteFees && storedOperation.quoteFees.length !== 0) &&
                        storedOperation.quoteFees.map((quoteFee,index)=>{
                            return(
                                <View key={'fee-'+index.toString()} style={styles.rowWithColumns}>
                                    <Text>{capitalize(quoteFee.name)}</Text>
                                    <Text style={styles.uppercase}>{formatNumber(quoteFee.price,storedOperation.quoteCurrency)}</Text>
                                </View>
                            )
                        })
                    }
                    {(storedOperation.quoteFees && storedOperation.quoteFees.length !== 0) &&
                        <>
                            <hr style={styles.hr} />
                            <View style={styles.rowWithColumns}>
                                <Text>{t('pdfOperation.quote.totalExtra')}</Text>
                                <Text style={styles.uppercase}>{formatNumber(quoteTotals.fees,storedOperation.quoteCurrency)}</Text>
                            </View>                                        
                        </> 
                    } 
                    <hr style={styles.hr} />
                    <View style={styles.rowWithColumns}>
                        <Text style={styles.bold}>{t('pdfOperation.quote.totalNet')}</Text>
                        <Text style={[styles.uppercase,styles.bold]}>{formatNumber(quoteTotals.net,storedOperation.quoteCurrency)}</Text>
                    </View>        
                    <View style={[styles.rowWithColumns,{marginTop:6,marginBottom:6}]}>
                        <Text>{t('pdfOperation.quote.vat')} ({storedOperation.quoteVat}%)</Text>
                        <Text style={styles.uppercase}>{formatNumber(quoteTotals.vat,storedOperation.quoteCurrency)}</Text>
                    </View>  
                    <View style={styles.rowWithColumns}>
                        <Text style={styles.bold}>Total</Text>
                        <Text style={[styles.uppercase,styles.bold]}>{formatNumber(quoteTotals.full,storedOperation.quoteCurrency)}</Text>
                    </View>  
                    <hr style={styles.hr} />                                                                  
                    <View style={{marginTop:16,marginBottom:16}}>
                        <Text>{storedOperation.quoteFooter}</Text>
                    </View>
                    <View>
                        <Text style={styles.bold}>{t('pdfOperation.quote.terms.title')}</Text>
                    </View>
                    <View style={[styles.smallFont,{marginTop:10,}]}>
                        <Text style={styles.bold}>{t('pdfOperation.quote.terms.text1')} :</Text>
                        <Text>UBS IBAN : CH05 0026 4264 6507 0201 C</Text>
                        <Text>BIC : UBSWCHZH80A</Text>
                    </View>                    
                    <View style={[styles.smallFont,{marginTop:10,marginBottom:10}]}>
                        <Text style={styles.bold}>{t('pdfOperation.quote.terms.text2')}</Text>
                        <Text>{t('pdfOperation.quote.terms.text3')}</Text>
                    </View>   
                    <View style={styles.smallFont}>
                        <Text style={styles.bold}>{t('pdfOperation.quote.terms.text4')} :</Text>
                        <Text style={{marginLeft:16}}>- {t('pdfOperation.quote.terms.text5')}</Text>
                        <Text style={{marginLeft:16}}>- {t('pdfOperation.quote.terms.text6')}</Text>
                        <Text style={{marginLeft:16}}>- {t('pdfOperation.quote.terms.text7')}</Text>
                        <Text style={{marginLeft:16}}>- {t('pdfOperation.quote.terms.text8')}</Text>
                    </View>  
                    <View style={[styles.smallFont,{marginTop:10}]}>
                        <Text>{t('pdfOperation.quote.terms.text9')}</Text>
                    </View> 
                    <View style={[styles.inline,styles.smallFont,{marginTop:10}]}>
                        <Text style={{marginRight:160}}>{t('pdfOperation.quote.terms.text10')},</Text>
                        <Text>{t('pdfOperation.quote.terms.text11')} : </Text>
                    </View>                                                        
                </View>
                <FooterSection selectedTypes={selectedTypes} page={page} selectedUsers={selectedUsers} staffList={staffList}/>
            </Page>            
        </>
    );

};  

const ContactPage = ({selectedTypes,page,storedOperation,selectedUsers, selectedContacts, staffList, version}) => {

    return (
        <>
            <Page size="A4" orientation="portrait" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.h2}>Staff Be My Guest</Text>
                    <View style={styles.block}>
                        {staffList && staffList.length > 0 && staffList.map((staff,index)=>{
                            if (!selectedContacts.includes(staff._id)) return false;
                            page++;
                            return (
                                <>                                
                                    <View style={[styles.rowWithColumns,{marginTop:8,marginBottom:8}]}>
                                        <Text style={[styles.bold,{width:200}]}>{capitalize(staff.firstName)} {capitalize(staff.lastName)}</Text>
                                        <Text style={{textAlign:'left',width:300}}>{capitalize(staff.email)}</Text>    
                                        <Text>{capitalize(staff.phone)}</Text>                                    
                                    </View>
                                </>
                            );
                        })}
                    </View>
                </View>
                <FooterSection selectedTypes={selectedTypes} page={page} selectedUsers={selectedUsers} staffList={staffList}/>
            </Page>            
        </>
    );

};  
  


const UserPage =  ({lang,selectedTypes,page,storedOperation, selectedUsers, staffList, version}) => {
    const { t } = useTranslation();  
    let userPage = page;

    return(
        <>
            {staffList && staffList.length > 0 && staffList.map((staff,index)=>{
                if (!selectedUsers.includes(staff._id)) return false;                                

                userPage++;
                page++;
                
                // Need to get 2 Media selected and make sure the media order Index match the file array indexes
                const mediasIdx = [];
                const medias = staff.medias.filter((media)=>media.section === 'media'); // Same order & number as Files
                for (const mediaIdx in medias) {
                    if (medias[mediaIdx] && medias[mediaIdx].selected === true) mediasIdx.push(mediaIdx);
                }

                const sortedLanguages = [...staff.languages].sort((a, b) => b.level - a.level);

                return (
                    <>
                        <Page key={index.toString()} size="A4" orientation="portrait" style={styles.pageUnWrappable}>
                            <View style={styles.sectionImage}>
                                {mediasIdx && mediasIdx.length > 0 && mediasIdx.slice(0, 2).map((mediaIdx,idx)=>{
                                    if (!staff.files[mediaIdx]) return false;
                                    return(
                                        <View key={idx.toString()}>
                                            <Image style={styles.staffImage} src={staff.files[mediaIdx]} />
                                        </View>
                                    )
                                })}
                            </View>
                            <View style={[styles.sectionUnWrappable,{paddingTop:0}]}>
                                <View style={{margin:0,paddingTop:0,paddingBottom:16}}>
                                    <Text style={styles.h2}>{capitalize(staff.firstName)} {capitalize(staff.lastName[0])}.</Text>
                                    <Text style={styles.h4}>{getAge(staff.birthday)} ans ({getGender(staff.gender,lang)})</Text>
                                </View>
                                <View style={[styles.rowWithColumns,{paddingBottom:0}]}>
                                    <View style={[styles.blockNeutral,{paddingRight:'10px',width:400}]}>
                                        {staff.height && 
                                            <View style={styles.inline}>
                                                <Text style={styles.accent}>{t('pdfOperation.user.height')} : </Text>
                                                <Text >{staff.height ? staff.height+'cm' : '-'}</Text>
                                            </View>  
                                        }
                                        {staff.bodyUp && 
                                            <View style={styles.inline}>
                                                <Text style={styles.accent}>{t('pdfOperation.user.jacket')} : </Text>
                                                <Text style={[styles.uppercase,{marginRight:16}]}>{staff.bodyUp}</Text>
                                                <Text style={styles.accent}>{t('pdfOperation.user.trouser')} : </Text>                          
                                                <Text style={[styles.uppercase,{marginRight:16}]}>{staff.bodyDown}</Text>  
                                                <Text style={styles.accent}>{t('pdfOperation.user.shoeSize')} : </Text>
                                                <Text style={styles.uppercase}>{staff.shoeSize}</Text>
                                            </View>  
                                        }                                                                                   
                                        {staff.languages  &&
                                            <View style={styles.inline}>
                                                <Text style={[styles.accent]}>{t('pdfOperation.user.language')+doS(sortedLanguages)} : </Text>
                                                <View style={{marginLeft:'40px',width:400}}>
                                                    {sortedLanguages && [...new Set(sortedLanguages)].map((staffLang,index)=>{
                                                        return(
                                                            <View key={index.toString()} >
                                                                <Text style={{marginRight:'6px'}}>{getLanguage(staffLang.code,lang)} - {getLanguageLevel(staffLang.level,lang)}</Text>
                                                            </View>                                                            
                                                        )
                                                    })}  
                                                </View>                        
                                            </View> 
                                        }                                                                                    
                                    </View>  
                                    <View style={[styles.blockNeutral,{paddingRight:'10px',width:400}]}>

                                        {staff.job && 
                                            <View style={[styles.inline,{width:200}]}>
                                                <Text style={[styles.accent,{marginRight:16}]}>{t('pdfOperation.user.job')} : </Text>
                                                <Text>{capitalize(staff.job)}</Text>
                                            </View>  
                                        }                                          
                                        {staff.diploma && 
                                            <View style={styles.inline}>
                                                <Text style={styles.accent}>{t('pdfOperation.user.diploma')} : </Text>
                                                <Text >{getDiplomas(staff.diploma)} {staff.ongoingStudies && staff.ongoingStudies === 1 ? '(Étude en cours)' : ''}</Text>
                                            </View>  
                                        }
                                        {staff.levelHospitality && 
                                            <View style={styles.inline}>
                                                <Text style={styles.accent}>{t('pdfOperation.user.levelHospitality')} : </Text>
                                                <Text>{getLevelHospitality(staff.levelHospitality)}</Text>
                                            </View> 
                                        }         
                                        {staff.areaExpertises  &&
                                            <View style={styles.inline}>
                                                <Text style={[styles.accent]}>{t('pdfOperation.user.areaExpertises')} : </Text>
                                                <View style={{marginLeft:'40px',width:400}}>
                                                    {staff.areaExpertises && [...new Set(staff.areaExpertises)].map((areaExpertise,index)=>
                                                        <Text key={index.toString()} style={{marginRight:'6px'}}>{capitalize(areaExpertise)}</Text>
                                                    )}
                                                </View>                        
                                            </View> 
                                        }                                     
                                    </View>                                            
                                </View> 
                                {((staff.pdfShowStudiesDescription && staff.studiesDescription) || (staff.pdfShowExpertiseDescription && staff.expertiseDescription)) && 
                                    <View>
                                        <View style={styles.inline}>
                                            <Text style={styles.accent}>{t('pdfOperation.user.comment')} : </Text>                                                                                  
                                        </View>
                                        {staff.pdfShowStudiesDescription && staff.studiesDescription && 
                                            <View style={styles.inline}>                                        
                                                <Text style={styles.bodySmall}>{capitalize(staff.studiesDescription)}</Text>
                                            </View>  
                                        }
                                        {staff.pdfShowExpertiseDescription && staff.expertiseDescription && 
                                            <View style={styles.inline}>                                        
                                                <Text style={styles.bodySmall}>{capitalize(staff.expertiseDescription)}</Text>
                                            </View>  
                                        }                                       
                                    </View>
                                }
                            </View>
                            <FooterSection selectedTypes={selectedTypes} page={userPage} selectedUsers={selectedUsers} staffList={staffList}/>
                        </Page>            
                    </>
                );
            })}
        </>
    );
};    

const OperationPdf = ({lang='fr',selectedTypes,selectedUsers,selectedContacts,storedOperation, staffList, version}) => {
    
    const { i18n } = useTranslation(); 

    let page = 0;
    
    useEffect(() => {
        if (lang && i18n.language !== lang) {
          i18n.changeLanguage(lang).catch((err) => console.error("Language change failed:", err));
          dayjs.locale(lang);
        }
      }, [lang,i18n]);

    return (
        <Document>
            {selectedTypes && selectedTypes.includes('cover') &&
                <CoverPage
                    storedOperation={storedOperation} 
                    staffList={staffList} 
                    selectedTypes={selectedTypes}
                    version={version}     
                    lang={lang}    
                    page={++page}     
                />
            }
            {selectedTypes && selectedTypes.includes('info') &&
                <InfoPage
                    storedOperation={storedOperation} 
                    staffList={staffList} 
                    selectedTypes={selectedTypes}
                    selectedUsers={selectedUsers}
                    version={version}   
                    lang={lang}          
                    page={++page} 
                />
            }
            {selectedTypes && selectedTypes.includes('user') &&
                <UserPage
                    storedOperation={storedOperation} 
                    staffList={staffList} 
                    selectedUsers={selectedUsers}
                    selectedTypes={selectedTypes}
                    version={version}     
                    lang={lang}        
                    page={page}
                />
            }  
            {selectedTypes && selectedTypes.includes('contact') &&
                <ContactPage
                    storedOperation={storedOperation} 
                    staffList={staffList} 
                    selectedTypes={selectedTypes}
                    selectedUsers={selectedUsers}
                    selectedContacts={selectedContacts}
                    version={version}      
                    lang={lang}        
                    page={(selectedUsers.length ? selectedUsers.length : 0)+(++page)}    
                />
            }   
            {selectedTypes && selectedTypes.includes('quote') &&
                <QuotePage
                    storedOperation={storedOperation} 
                    staffList={staffList} 
                    selectedTypes={selectedTypes}
                    selectedUsers={selectedUsers}
                    version={version}   
                    lang={lang}        
                    page={(selectedUsers.length ? selectedUsers.length : 0)+(++page)}    
                />
            }  
            {selectedTypes && selectedTypes.includes('mission') &&
                <MissionPage
                    storedOperation={storedOperation} 
                    staffList={staffList} 
                    selectedTypes={selectedTypes}
                    selectedUsers={selectedUsers}
                    version={version}   
                    lang={lang}        
                    page={(selectedUsers.length ? selectedUsers.length : 0)+(++page)}    
                />
            }   
            {selectedTypes && selectedTypes.includes('planning') &&
                <PlanningPage
                    storedOperation={storedOperation} 
                    staffList={staffList} 
                    selectedTypes={selectedTypes}
                    selectedUsers={selectedUsers}
                    version={version}   
                    lang={lang}        
                    page={(++page)}    
                />
            }                                                                   
        </Document>
      );
};
  
  Font.register({
    family: 'Poppins',
    fonts: [
      { src: PoppinsRegular },
      { src: PoppinsBold, fontWeight: 'bold' },
      { src: PoppinsItalic, fontStyle: 'italic' },
    ], 
});

const styles = StyleSheet.create({
    cover: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        backgroundColor:'#433F3A'
        
    },
    page: {
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        fontFamily: 'Poppins', 
        fontSize: 12,    
        padding:16,
    },
    section: {
        margin: 16,
        // padding: 10,
        //   flexGrow: 1
    },
      pageUnWrappable: {
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        fontFamily: 'Poppins', 
        fontSize: 12,
      },      
     sectionUnWrappable: {
        margin: 16,
        padding: 16,
        //   flexGrow: 1
    },      

    logoContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    svg: {
        width: '50%', // Adjust as needed
        height: '50%', // Adjust as needed
    },  
    logo: {
        width: 300,
        height: 190,
        color:'#ff0000'
    },
    staffImage : {
        width: 300,
        height: 360,
        objectFit:"cover"
    },
    sectionImage : {
        // flexGrow: 1
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',        
    },
    smallSection:{
        fontSize:10,
    },
    inline : {
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
        marginTop:3,
        marginBottom:3,
    },
    row : {
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
    },      
    rowWithColumns : {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
    },   
    semibold : {
        fontWeight:400,
    },
    left: {
        textAlign:'left',
    },
    column : {
        width:'16%',  
        textAlign:'right',      
        border:'solid 1px black'
    },
    uppercase : {
        textTransform:"uppercase",
    },
    h2: {
        fontSize: 28,        
        color:'#C9B39B',
        fontWeight:600,
    },
    h3: {
        fontSize: 18,        
    },  
    h4: {
        fontSize: 14,   
        fontWeight:600,
    },      
    accent : {
        color:'#C9B39B',
    },
    bold : {
        fontWeight:600,
    },
    cartouche : {
        border: '0.5px solid #C9B39B',
        borderRadius:6,
        padding:6,
        backgroundColor:'#C9B39B',
    },
    body: {
        fontSize: 12,    
    }, 
    bodySmall: {
        fontSize: 11,  
        // fontStyle:'italic',
    },      
    block:{
        marginTop:32,
        marginBottom:64,
    },
    blockNeutral:{
        marginTop:0,
        marginBottom:0,
    }, 
    hr : { 
        width: '100%', 
        border: '0.3px solid #C9B39B',
        marginTop:6,
        marginBottom:6
    },
    big:{
        fontSize:33,
        display:'inline',
    },
    smallFont : {
        fontSize:9,
    },
    smallerFont : {
        fontSize:7,
    },    
    footer: {
        position: 'absolute',
        bottom: '16px', // 20px from the bottom
        left: '0',
        right: '0',
        textAlign: 'center',
        fontSize:8,
        color: '#808080',
    },      
});
  

export default OperationPdf;
