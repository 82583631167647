import React, { useState } from 'react';
import dayjs from 'dayjs';

import { DownloadOutlined, DownOutlined,UpOutlined,CheckOutlined } from '@ant-design/icons';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { paymentSpecials, SlotBmgStatus } from "../../../utils/constants"
import { saveOperation } from '../../../features/operation/operationSlice';
import { saveFilters } from '../../../features/filters/filtersSlice';
import { GetOp } from '../../../services/Operation'
import { downloadFile , blobToDownload} from "../../../services/File"

import {  FormatTotal, sortOnDateField,capitalize, getAddress, FormatUserMissionSlots } from "../../../utils/miscHelpers"
import UserGeneral from "./UserGeneral"
import LegendStatus from '../../../components/elements/LegendStatus'

const MissionsTab = ({section, bmgFilters,  openGeneral, setOpenGeneral}) => { 

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const storedUser = useSelector(state => state.user.value && state.user.value ? state.user.value  : {});
    const missions = storedUser.missions ? sortOnDateField(storedUser.missions,'cdate') : [];
    let userJobOffers = [];
    if (storedUser && storedUser.jobOffers && storedUser.jobOffers.length) {
        userJobOffers = [...storedUser.jobOffers].sort((a, b) => a.name.localeCompare(b.name));
    }
        
    const [toogledOps, setToogledOps] = useState([]);
    
    const toogleOperation = (missionKey)=>{
        let newToogledOps = [...toogledOps];
        if (newToogledOps.includes(missionKey)) {
            newToogledOps = newToogledOps.filter((ele)=>ele !== missionKey)
        } else {
            newToogledOps.push(missionKey);            
        }
        setToogledOps(newToogledOps);        
    };

    const linkMe = async (opId,section)=>{    
        switch(section){
          case 'operation':
              const operation = await GetOp(opId);
              dispatch(saveFilters({section:'user',data:{listType:'default'}}));    
              dispatch(saveOperation(operation));
              navigate('/operation/detail?opId='+opId);
            break;                                    
          default:
        }    
    };
    
    const getStatusAndColor = (status,opBmgStatus) =>{
        let color = 'var(--accent-color)';
        if(opBmgStatus === 1) return {color,label:'Terminé'};
        return SlotBmgStatus[status];
    };

    const viewContract = async (mission) =>{        
        const file = await downloadFile({section:'contract',file:mission.contract,})
        return blobToDownload(file,'contrat_'+mission._operation.name+'_'+storedUser.lastName+'.pdf');
    };
    
    return(
        <div className="tab-content">
            <UserGeneral
                section={section}
                bmgFilters={bmgFilters}
                openGeneral={openGeneral}
                setOpenGeneral={setOpenGeneral}
            />
            <div className={'tab-block user-block '+ (openGeneral ? '' : 'folded')} style={{                
                    height:'70vh',
                    overflowY:'auto',
                    display:'flex',
                    marginTop: "33px",
                    marginLeft: "2%",     
                    marginRight: "2%",     
                     width: "80%",
                    flexDirection: "column",
                    textAlign:'left',
                    alignItems: storedUser && storedUser.missions && !storedUser.missions.length ? 'center' : 'flex-start'
                }}
                >
                <LegendStatus type='full'/>
                {storedUser && storedUser.missions && !storedUser.missions.length && 
                    <div style={{marginTop:'32px'}}>
                        <div>Aucune mission</div>
                    </div>
                }                  
                {missions.length > 0 && missions.map((mission,midx)=>{
                    if (!mission._operation) return null;
                    const formattedMission = FormatUserMissionSlots(mission.slots,1);
                    const missionKey = 'mission-' + midx;   
                    const statusAndColor = getStatusAndColor(mission.status,mission._operation.bmgStatus);        
                    
                    return (
                        <div key={missionKey} style={{
                                width:'100%',
                                margin:'6px 0',
                                backgroundColor:'var(--accent-light-color)',
                                padding:'16px',
                                border:'solid 1px var(--accent-color)',
                                borderLeftColor:statusAndColor.color,
                                borderLeftWidth:'10px',
                                borderRadius:'6px'
                            }}
                            title={statusAndColor.label}
                            >
                            <div className='flex flex-column'>
                                <div className='h2 link' onClick={()=>linkMe(mission._operation._id,'operation')}>{capitalize(mission._operation.name)}</div>
                                <div className='flex flex-column-start link' onClick={()=>toogleOperation(missionKey)} title="Afficher/Fermer les details de la Mission">
                                    <div className='flex flex-column-start' >
                                        {/* <div style={{width:'10px',height:'10px', marginRight:'6px', borderRadius:'5px',backgroundColor:statusAndColor.color}} title={statusAndColor.label}/> */}
                                        <div className='font-medium bold'>Du {dayjs(mission._operation.sdate).format('DD/MM/YYYY')} au {dayjs(mission._operation.edate).format('DD/MM/YYYY')}</div>
                                    </div>
                                    <div style={{cursor:'pointer', marginLeft:'16px',width:'20px'}}>
                                        {toogledOps.includes(missionKey) &&
                                            <UpOutlined style={{color:'var(--accent-color) !important'}}/>
                                        }
                                        {!toogledOps.includes(missionKey) &&
                                            <DownOutlined style={{color:'var(--accent-color) !important'}}/>                                            
                                        }                                    
                                    </div>                                
                                </div>                                
                            </div>
                            {formattedMission && formattedMission.data && formattedMission.data.length > 0 && formattedMission.data.map((locationMission,locidx) => 
                                <div key={'location-'+locidx} style={{marginTop:'16px',display:toogledOps.includes(missionKey) ? '' : 'none'}}>
                                    <div  className='bold font-medium'>{getAddress(locationMission.location,'direct')}</div>
                                    <div className='flex flex-column font-medium bold' style={{margin:'16px 0'}}>
                                        <span style={{width:'150px'}}>Rôle</span>
                                        <span style={{width:'220px'}}>Horaires</span>
                                        <span style={{width:'80px', textAlign:'center'}}>Prix</span>
                                        <span style={{width:'60px', textAlign:'right'}}>Normal</span>
                                        <span style={{width:'60px', textAlign:'right'}}>Overtime</span>
                                        <span style={{width:'60px', textAlign:'right'}}>Total</span>
                                        <span style={{width:'50px', textAlign:'center'}}>Férié</span>
                                        <span style={{width:'80px', textAlign:'right'}}>Total</span>
                                    </div>     
                                    {locationMission.slots && locationMission.slots.length > 0 && locationMission.slots.map((slot,sidx)=>{
                                        return(
                                            <div key={'slot-'+sidx} className='flex flex-column font-medium'>
                                                <span style={{width:'150px'}}>{capitalize(slot.role)}</span>
                                                <div className='flex flex-column-start' style={{width:'220px'}}>
                                                    <div style={{width:'10px',height:'10px', marginRight:'6px', borderRadius:'5px',backgroundColor:SlotBmgStatus[slot.status] ? SlotBmgStatus[slot.status].color : 'none'}} title={SlotBmgStatus[slot.status] ? SlotBmgStatus[slot.status].label : ''}/>
                                                    <span style={{textDecoration: slot.status === 0 ? "line-through":"none",}}>{dayjs(slot.eday).format('DD/MM/YYYY')} de {slot.stime.replace(':',"h")} à {slot.etime.replace(':',"h")}</span>
                                                </div>
                                                <span style={{width:'80px', textAlign:'right'}}>{FormatTotal(slot.price,slot.currency,0)} / Hr</span>
                                                <span style={{width:'60px', textAlign:'right'}}>{slot.totalNormalHrs ? slot.totalNormalHrs : 0} Hrs</span>
                                                <span style={{width:'60px', textAlign:'right'}} title={paymentSpecials.extraPerc+' en plus car entre 23h et 6h'}>{slot.totalOverTimeHrs ? slot.totalOverTimeHrs : 0} Hrs</span>
                                                <span style={{width:'60px', textAlign:'right'}}>{slot.totalHrs ? slot.totalHrs : 0} Hrs</span>
                                                <span style={{width:'50px', textAlign:'center'}}>{slot.isHoliDay ? <CheckOutlined title={paymentSpecials.holidayPerc+' en plus car Dimanche ou Jour férié'}/> : ''}</span>
                                                {/* <span style={{width:'50px', textAlign:'center'}}>{slot.isHoliDay ? 'x'+paymentSpecials.holiday : ''}</span> */}
                                                <span style={{width:'80px', textAlign:'right'}} title={slot.totalNormal+' + '+slot.totalOverTime}>{FormatTotal(slot.total,slot.currency,0)}</span>                                                
                                            </div>   
                                        )}                                         
                                    )}
                                </div>
                            )}
                            <div className='flex flex-column font-medium' style={{marginTop:'16px'}}>
                                <span className='bold' style={{width:'150px'}}/>
                                <span className='bold' style={{width:'220px'}}/>
                                <span className='bold' style={{width:'80px', textAlign:'right'}}>Total</span>
                                <span className='bold' style={{width:'60px', textAlign:'right'}}>{formattedMission.totalNormalHrs ? formattedMission.totalNormalHrs : 0} Hrs</span>
                                <span className='bold' style={{width:'60px', textAlign:'right'}}>{formattedMission.totalOverTimeHrs ? formattedMission.totalOverTimeHrs : 0} Hrs</span>
                                <span className='bold' style={{width:'60px', textAlign:'right'}}>{formattedMission.totalHrs ? formattedMission.totalHrs : 0} Hrs</span>
                                <span className='bold' style={{width:'50px'}}/>
                                <span className='bold' style={{width:'80px', textAlign:'right'}}  title={formattedMission.totalNormal+' + '+formattedMission.totalOverTime}>{FormatTotal(formattedMission.total,formattedMission.currency,0)}</span>
                            </div>   
                            {mission.contract && toogledOps.includes(missionKey) && 
                                <div className='flex flex-column-end' style={{width:'100%',marginTop:'16px'}}>
                                    <div className='flex link'  onClick={()=>viewContract(mission)} title="Télécharger le Contrat envoyé pour cette Mission">
                                        <div style={{fontWeight:'bold',marginRight:'10px'}}>Contrat</div>
                                        <DownloadOutlined />
                                    </div>                  
                                </div>                                                                   
                            }
                            {mission.contractValidate && 
                                <div className='flex flex-column-end' style={{width:'100%',marginTop:'16px'}}>
                                    <div className='flex'>
                                        <div style={{marginRight:'10px'}}>Contrat lu & approuvé par {capitalize(storedUser ? storedUser.firstName : '')} {capitalize(storedUser ? storedUser.lastName : '')} le {dayjs(mission.contractValidate.cdate).format('DD MMM YYYY à HH:mm')}</div>
                                    </div>    
                                </div>    
                            }                                                                                
                        </div>
                    )
                })}
                {userJobOffers && userJobOffers.length > 0 && 
                    <div style={{marginTop:'16px',width:'100%'}}>
                        <div className='h2'>Job Board, {capitalize(storedUser.firstName)} a postulé(e) à :</div>
                        <div className='flex flex-row' style={{marginTop:'10px',width:'100%'}} >
                        {userJobOffers.map((joboffer,jidx)=>
                            <div key={'jo'+jidx.toString()}>
                                <span>{joboffer.name}</span>
                            </div>                
                        )}  
                        </div>
                    </div>                   
                }
            </div>
        </div>
    )
};

export default MissionsTab;