import React,{useEffect,useState} from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
//import { useAuth } from "../../hooks/AuthProvider";
import { useTranslation } from 'react-i18next';
import {  Row, Col, Checkbox, Badge, Spin, Card , Divider, Tag } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import { 
  MetricsDashboard,
  MsgDashboard,
  UsersDashboard,
  MissionsDashboard,
  ToDoDashboard
} from "../../services/Dashboard"
import { saveFilters } from '../../features/filters/filtersSlice';
import { capitalize,doS } from "../../utils/miscHelpers";
import { saveUser } from '../../features/user/userSlice';

import HeaderBar from "../../components/layout/HeaderBar";
import PopOverForm from "../../components/elements/PopOverForm"

const section = 'dashboard';

const DashboardScreen = () => {
  // const auth = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Meta } = Card;  
  const storedFilters = useSelector(state => state.filters.value && state.filters.value[section] ? state.filters.value[section] : {});
  //const storedFiltersUsers = useSelector(state => state.filters.value && state.filters.value['user'] ? state.filters.value['user'] : {});
  const { t } = useTranslation();
  const [metrics, setMetrics] = useState({});
  const [messages, setMessages] = useState([]);
  const [isLoadingMessage, setIsLoadingMessage]= useState(false);
  const [users, setUsers] = useState([]);
  const [isLoadingUser, setIsLoadingUser]= useState(false);
  const [operations, setOperations] = useState([]);
  const [isLoadingOp, setIsLoadingOp]= useState(false);
  const [todos, setTodos] = useState([]);
  const [isLoadingTodo, setIsLoadingTodo]= useState(false);
  const [showUnRead, setShowUnRead] = useState(false);

  const fetchData = async () => {
    try {

      setIsLoadingMessage(true);
      setIsLoadingUser(true);
      setIsLoadingOp(true);
      setIsLoadingTodo(true);

      const metricsResults = await MetricsDashboard({});        
      setMetrics(metricsResults); 

      const msgResults = await MsgDashboard({});
      setMessages(msgResults); 
      setIsLoadingMessage(false);

      const usersResults = await UsersDashboard({});        
      setUsers(usersResults); 
      setIsLoadingUser(false);

      const opsResults = await MissionsDashboard({});        
      setOperations(opsResults); 
      setIsLoadingOp(false);

      const todoResults = await ToDoDashboard({});        
      setTodos(todoResults);   
      setIsLoadingTodo(false);

      return;

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();    
  }, []); 
  
  const linkMe = (page,data) =>{
    
    switch(page){
      case 'message' :
        if (data && data._id) navigate('/user/detail?staffId='+data._id,{state:{activeKey:'4'}});
      return;      
      case 'messageHome' : 
        navigate('/message');
      return;
      case 'userHome' : 
        navigate('/user');
      return;      
      case 'userStatus' : 
        dispatch(saveFilters({section:'user',data:{bmgStatus:[Number(data.value)]}}));    
        navigate('/user');
      return;
      case 'opHome' : 
        navigate('/operation');
      return;       
      case 'todoHome' : 
        navigate('/todo');
      return;           
      case 'user' : 
        dispatch(saveUser(data));
        navigate('/user/detail');
      return;
      case 'opStatus' : 
        dispatch(saveFilters({section:'operation',data:{bmgStatus:[Number(data.value)]}}));    
        navigate('/operation');
      return;      
      case 'operation' : 
        if (data && data._id) window.open('/operation/detail?opId='+data._id);
        // dispatch(saveOperation(data));
        // navigate('/operation/detail');
      return;      
      default:
    }
  };


  const onEditTodo = (record) =>{
    //console.log('onEdit',record);
    return true;
  };

  const onUnReadMsg = async () =>{    
    setIsLoadingMessage(true);
    let msgResults = null;
    if (showUnRead === false) {
      setShowUnRead(true);
      msgResults = await MsgDashboard({adminView:1});
    } else {
      setShowUnRead(false);
      msgResults = await MsgDashboard({});
    }    
    setMessages(msgResults); 
    setIsLoadingMessage(false);  
  };

  const messageContent = ()=>{
    return (
      <div>
        <div className="flex flex-column" style={{width:'100%'}} >
          <h3 className="dashboard-h3 link" onClick={()=>linkMe('messageHome')}>Derniers Messages reçus</h3>
          <Checkbox
            style={{ }}
            title='Message Non Lu'
            className="custom-checkbox "
            checked={showUnRead}
            onChange={() => onUnReadMsg()}
          >
            <span className="h4light">Non Lu</span>
          </Checkbox>          
        </div>
        <Divider                   
          style={{padding:0,width:'100%', height: '1px' , marginTop:'10px', marginBottom:'10px', minWidth: '100%', borderColor: 'var(--accent-color)'}}
          />
        <div style={{height:'320px',overflow:'auto'}}>
          {isLoadingMessage &&
            <Spin className='spinner-dashboard'/>
          }
          {!isLoadingMessage && messages && messages.length > 0 && messages.map((message,idx)=>{
            return(
              <div key={'msg-'+idx.toString()} className="link flex flex-column" style={{marginBottom:'10px'}} onClick={()=>linkMe('message',message._user)}>
                <div className="flex flex-column-start">
                  <img alt="user" src={message._user.avatar ? message._user.avatar : '/img/placeholder-avatar.jpg'} className="avatar-small" />
                  {!message.adminView && <Badge count={1} style={{    position:'absolute',marginTop: '-20px'}}/>}
                  <div className="h2" style={{
                      marginLeft: '10px',
                      minWidth: '120px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '120px',
                      marginRight: '10px',                    
                    }}>{capitalize(message._user?.firstName)} {capitalize(message._user?.lastName)}</div>
                </div>
                <div style={{marginRight: '10px',}} className="h2" >{message._admin ? capitalize(message._admin.firstName) : '-'}</div>
                <div className="body" style={{width:'100%',minWidth:'160px', whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',marginRight: '10px',
                      fontWeight:message.highlight ? 800 : 'normal'}}>{message.body}</div>
                <div className="body"  style={{minWidth:'140px'}}>{dayjs(message.cdate).format('DD MMM YYYY - HH:mm')}</div>
              </div>
            )
          })}
        </div>
      </div>      
    );
  };

  const userContent = ()=>{
    return (
      <div>
        <h3 className="dashboard-h3 link" onClick={()=>linkMe('userHome')}>Dernières Inscriptions</h3>
        <Divider                   
          style={{padding:0,width:'100%', height: '1px' , marginTop:'10px', marginBottom:'10px', minWidth: '100%', borderColor: 'var(--accent-color)'}}
          />
          <div style={{height:'320px',overflow:'auto'}}>            
            {isLoadingUser &&
              <Spin className='spinner-dashboard'/>
            }
            {!isLoadingUser && users && users.length > 0 && users.map((user,idx)=>{
              return(
                <div key={'user-'+idx.toString()} style={{marginBottom:'10px'}} className="link flex flex-column-start" onClick={()=>linkMe('user',user)}>
                  <img alt="user" src={user.avatar ? user.avatar : '/img/placeholder-avatar.jpg'} className="avatar-small" />
                  <div style={{marginLeft:'10px'}}>
                    <div className="h2" style={{minWidth:'160px'}}>{capitalize(user.firstName)} {capitalize(user.lastName)}</div>         
                    <div className="body" >{dayjs(user.cdate).format('DD MMM YYYY - HH:mm')}</div>
                  </div>
                </div>
              )
            })}
          </div>
      </div>      
    );
  };

  const operationContent = ()=>{
    return (
      <div>
        <h3 className="dashboard-h3 link"  onClick={()=>linkMe('opHome')}>Prochaines Opérations</h3>
        <Divider                   
          style={{padding:0,width:'100%', height: '1px' , marginTop:'10px', marginBottom:'10px', minWidth: '100%', borderColor: 'var(--accent-color)'}}
          />
        <div style={{height:'320px',overflow:'auto'}}>
          {isLoadingOp &&
              <Spin className='spinner-dashboard'/>
            }
          {!isLoadingOp && operations && operations.length > 0 && operations.map((op,idx)=>{
              return(
                <div key={'todo-'+idx.toString()} className="link flex flex-column" onClick={()=>linkMe('operation',op)}>
                  <div className="h2 capitalize" style={{marginBottom:'10px',overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',width:'150px'}} >{op.name}</div>                              
                  <div className="body" style={{whiteSpace: 'nowrap', }}>{dayjs(op.sdate).format('DD MMM')} {">"} {dayjs(op.edate).format('DD MMM YYYY')}</div>                                    
                </div>
              )
            })}          
        </div>
      </div>      
    );
  };

  const todoContent = ()=>{    
    return (
      <div>
        <h3 className="dashboard-h3 link"  onClick={()=>linkMe('todoHome')}>Mes Tâches</h3>
        <Divider                   
          style={{padding:0,width:'100%', height: '1px' , marginTop:'10px', marginBottom:'10px', minWidth: '100%', borderColor: 'var(--accent-color)'}}
          />
        <div style={{height:'320px',overflow:'auto'}}>
          {isLoadingTodo &&
              <Spin className='spinner-dashboard'/>
            }
          {!isLoadingTodo && todos && todos.length > 0 && todos.map((todo,idx)=>{
              return(
                  <PopOverForm
                    key={'todo-'+idx.toString()} 
                    content={
                      <div className="flex flex-column link" style={{marginBottom:'10px'}}>
                        <div className="body capitalize" style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',width:'150px'}} >{todo.name}</div>                              
                        <div className="body">{dayjs(todo.ddate).format('DD MMM YYYY')}</div>
                        <EyeOutlined 
                          title="Voir / Editer"
                          className='link icon-table'
                        /> 
                      </div>
                    }
                    form="todo"
                    handlerMethod={onEditTodo}
                    data={todo}                    
                  />                                   
              )
            })}          
        </div>
      </div>      
    );
  };

  const operationMetricsContent = ()=>{    
    return (
      <div>
        <h3 className="dashboard-h3 link" onClick={() => linkMe("operation")}>
          {metrics?.operation?.fullTotal} Opération{doS(metrics?.operation?.fullTotal,'number')}
        </h3>
        <Divider
          style={{
            padding: 0,
            width: "100%",
            height: "1px",
            marginTop: "10px",
            marginBottom: "10px",
            minWidth: "100%",
            borderColor: "var(--accent-color)",
          }}
        />
        {isLoadingTodo &&
          <Spin className='spinner-dashboard'/>
        }
        {!isLoadingTodo &&
          <Row gutter={[16, 16]}>
            {metrics &&
              metrics.opBmgStatus &&
              metrics.opBmgStatus.length &&
              metrics.opBmgStatus.map((metric, lidx) => {
                return (
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} key={lidx} className='flex' style={{
                    paddingTop:'16px',
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",}}>
                      <div className="flex" style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",}}>
                        <Tag
                          onClick={() => linkMe("opStatus", metric)}
                          color={"white"}
                          closable={false}
                          className="tag link"
                          style={{
                            margin: "6px",
                            width: "100px",
                            height: "100px",
                            display: "flex",
                            borderRadius: "50px",                            
                            backgroundColor: metric.color,
                            color: 'white',
                            fontSize: "14px",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign:'center'
                          }}
                        >
                          <div style={{ fontSize: "24px" }}>{metric.cnt}</div>     
                          <div
                            className="h2 capitalize"                          
                            style={{ color:'white',textTransform: "uppercase" }}
                          >
                            {metric.label}{lidx < 2 ? doS(metric.cnt,'number') : ''}
                          </div>                                             
                          <div style={{ fontSize: "16px" }}>
                            {makePerc(metric.cnt, metrics?.operation?.fullTotal)}%
                          </div>
                        </Tag>
                      </div>

                  </Col>
                );
              })}
          </Row>
        }
      </div>
    );
  };

  const makePerc = (value,total) =>{
    return total ? ((value/total)*100).toFixed(0) : 0;
  };

  return ( 
    <div className="container">
        <HeaderBar hasFilters={false} hasSort={false} hasSearch={false}  section={section} storedFilters={storedFilters}/>
        <div className="content" style={{top:'20px'}}>
          <div className="dashboard-layout-row">             
            
            <div className="dashboard-layout-column " style={{width:'80%',justifyContent:'space-around'}}>
              <div>
                <div style={{fontWeight:600,color:'#0dcc05',textAlign:'center'}}>Membres Actifs</div>
                <div className="flex" style={{flexDirection:'row'}}>
                  <div className='flex' style={{flexDirection:'column',alignItems: 'center'}}>
                    <span style={{fontWeight:600,fontSize:'18px'}}>{metrics?.activity?.activeThisWeeks}</span>
                    <span style={{fontSize:'12px'}}>Cette semaine</span>
                  </div>
                  <div className='flex' style={{marginLeft:'16px',flexDirection:'column',alignItems: 'center'}}>
                    <span style={{fontWeight:600,fontSize:'18px'}}>{metrics?.activity?.activeThisMonth}</span>
                    <span style={{fontSize:'12px'}}>Ce mois ci</span>
                  </div>
                </div>
              </div>  
              <div>
                <div style={{fontWeight:600,color:'#ff9400',textAlign:'center'}}>Membres Inactifs</div>
                <div className="flex" style={{justifyContent: 'center',flexDirection:'row'}}>
                  <div className='flex' style={{flexDirection:'column',alignItems: 'center'}}>
                    <span style={{fontWeight:600,fontSize:'18px'}}>{metrics?.activity?.inactiveThisMonth}</span>
                    <span style={{fontSize:'12px'}}>Plus d'un mois</span>
                  </div>
                  {metrics?.activity?.inactiveSixMonths > 0 && 
                    <div className='flex' style={{marginLeft:'16px',flexDirection:'column',alignItems: 'center'}}>
                      <span style={{fontWeight:600,fontSize:'18px'}}>{metrics?.activity?.inactiveSixMonths}</span>
                      <span style={{fontSize:'12px'}}>Plus de 6 mois</span>
                    </div>
                  }
                </div>
              </div>                             
            </div>   

            <div className="dashboard-layout-column">
              {metrics && metrics.bmgStatus && metrics.bmgStatus.length && metrics.bmgStatus.map((metric,lidx)=>{
                  return(
                      <Tag
                          key={lidx.toString()}
                          onClick={()=>linkMe('userStatus',metric)}
                          color={"white"}
                          closable={false}
                          className="tag link" 
                          style={{
                              margin:"6px",
                              width:'100px',
                              height:'100px',
                              display:'flex',
                              borderRadius:'50px',
                              backgroundColor:metric.color,
                              color:lidx === 0 ? 'grey' : 'white',
                              fontSize:'14px',                              
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center'
                          }}
                      >
                        <div style={{fontSize:'18px'}}>{metric.cnt}</div>
                        <div className="capitalize" style={{fontSize:'14px',textTransform:'uppercase'}}>{metric.label}{lidx === 0 ? 'x' : lidx !== 1 ? doS(metric.cnt,'number') : ''}</div>
                        <div style={{fontSize:'12px'}}>{makePerc(metric.cnt,metrics?.user?.total)}%</div>
                      </Tag>
                  )
                }
              )}
            </div>              

          </div>

          <div className="dashboard-layout-row">
            <div className="dashboard-layout-column" style={{width: '100%'}}>
              <div className="dashboard-metric">
              <div className="dashboard-metric-block" onClick={()=>navigate('/user')}>
                <div className="dashboard-circle accent-color-border">{metrics && metrics.user ? metrics.user.total : 0}</div>
                <div >{t('dashboard.members')}</div>
              </div>
              <div className="dashboard-metric-block" onClick={()=>navigate('/operation')}>
                <div className="dashboard-circle accent-color-border">{metrics && metrics.booked ? metrics.booked.total : 0}</div>
                <div >{t('dashboard.operations')}</div>
              </div>
              <div className="dashboard-metric-block" onClick={()=>navigate('/operation')}>
                <div className="dashboard-circle orange-color-border">{metrics && metrics.operation ? metrics.operation.total : 0}</div>
                <div >{t('dashboard.operationsRunning')}</div>
              </div>
              <div className="dashboard-metric-block" onClick={()=>navigate('/client')}>
                <div className="dashboard-circle red-color-border">{metrics && metrics.quote ? metrics.quote.total : 0}</div>
                <div >{t('dashboard.quotes')}</div>
              </div>                                    
            </div>
            </div>
            <div className="dashboard-layout-column">
              <Card className="dashboard-card large">
                <Meta description={messageContent()}/>
              </Card>
            </div>            
          </div>
          <div className="dashboard-layout-row">
            <div className="dashboard-layout-column">
              <Card className="dashboard-card">
                <Meta description={userContent()} />
              </Card>
            </div>
            <div className="dashboard-layout-column">
              <Card className="dashboard-card">
                <Meta description={operationContent()} />
              </Card>
            </div> 

            <div className="dashboard-layout-column">
              <Card className="dashboard-card">
                <Meta description={operationMetricsContent()} />
              </Card>
            </div>

            {todos && todos.length > 0 && 
              <div className="dashboard-layout-column">
                <Card className="dashboard-card">
                  <Meta description={todoContent()} />
                </Card>
              </div>     
            }

          </div>
        </div>
    </div>    
  );
};

export default DashboardScreen;