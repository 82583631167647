import React, { useState } from 'react';
import {  Input, Checkbox, InputNumber, Select } from 'antd';
import { operationBmgStatus,Languages } from '../../../utils/constants'
import PopOverForm from "../../../components/elements/PopOverForm"

const { TextArea } = Input;

    /*
    sdate,
    edate,
    eday: { type: Date },
    duration: Number,
    */

const FormGeneral =  ({
        section, 
        bmgFilters, 
        clientList,
        topSpacer,
        handlerChangeMethod,
        handlerSelectMethod,
        onSubmitNewClient,
        latitude,
        longitude,
        bmgStatus,
        dateImposed,
        name,
        client,
        type,
        newType,
        quote,
        price,
        bookedTotal,
        address,
        zipcode,
        city,
        country,
        dressCode,
        body,
        bodyClient,
        instruction,
        contact,
        languages
    }) => {

    const [eventFieldDisplay, setEventFieldDisplay] = useState(false);

    const toogleNewEventField = () =>{
        setEventFieldDisplay(!eventFieldDisplay);
    };

    return(
        
        <div className='tab-form steps' style={{marginTop:topSpacer+'px'}}>
            <div className='tab-form-block'>
                <div className="form-label-name">
                    <span className="label">Intitulé de l'événement</span>
                    <Input 
                        title="Intitulé de l'événement"
                        defaultValue={name}
                        id="name"
                        name="name"
                        placeholder="Intitulé de l'événement" 
                        className=""
                        onChange={(e)=>handlerChangeMethod(e,'setName')}
                    />
                </div>
            </div>
            <div className='tab-form-block'>
                <div className='tab-form-block-column'>
                     <div className="form-label-name">
                        <div className='flex flex-column'>
                            <span className="label">Client</span>    
                            <PopOverForm
                                content={<span className="link label">+ Ajouter un nouveau Client</span>}
                                form="clientForm"
                                color={'white'}
                                handlerMethod={(props)=>onSubmitNewClient(props)}                                                            
                                data={{}}
                            />                                
                        </div>                        
                        <Select
                            style={{
                                width: '100%',                                
                            }}
                            optionLabelProp="label"
                            optionFilterProp="label"
                            placeholder="Clients"
                            defaultValue={[]}
                            value={client}
                            onChange={(e)=>handlerSelectMethod(e,"setClient")}
                            options={clientList}
                            showSearch
                            className="filter-select accented"
                        />
                    </div>  
                </div>                                         
                <div className='tab-form-block-column' style={{margin:"0 16px"}}>
                    {!eventFieldDisplay && 
                        <div className="form-label-name">
                            <div className='flex flex-column'>
                                <span className="label">Type d'événement</span>
                                <div onClick={toogleNewEventField}>
                                    <span className="label">+ Nouvel événement</span>
                                </div>                               
                            </div>
                            <Select
                                title="Type d'événement"
                                style={{
                                    width: '100%',                            
                                }}
                                optionLabelProp="label"
                                optionFilterProp="label"
                                placeholder="Type d'événement"
                                defaultValue={[]}
                                value={type}
                                showSearch
                                onChange={(e)=>handlerSelectMethod(e,"setType")}
                                options={bmgFilters && bmgFilters.operation ? bmgFilters.operation.type : []}
                                className="filter-select capitalize  accented"
                            />
                        </div>
                    }
                    {eventFieldDisplay && 
                        <div className="form-label-name">
                            <div className='flex flex-column'>
                                <span className="label">Nouvel événement</span>
                                <div onClick={toogleNewEventField}>
                                    <span className="label">Liste</span>
                                </div>                               
                            </div>
                            <Input 
                                title="Type d'événement"
                                // defaultValue={quote}
                                value={newType}
                                id="newType"
                                name="newType"
                                placeholder="Type d'événement"
                                className=""
                                onChange={(e)=>handlerChangeMethod(e,"setNewType")}
                            />
                        </div>
                    }                    
                </div>
                <div className='tab-form-block-column'>
                    <div className="form-label-name">
                        <span className="label">Statut</span>                            
                        <Select
                            title='Statut'
                            style={{
                                width: '100%',                            
                            }}
                            optionLabelProp="label"
                            optionFilterProp="label"
                            placeholder="Statut"
                            defaultValue={bmgStatus}
                            value={bmgStatus}
                            onChange={(e)=>handlerSelectMethod(e,"setBmgStatus")}
                            options={operationBmgStatus}
                            className="filter-select capitalize  accented"
                        />
                    </div>                        
                </div>                              
            </div>   
            <div className='tab-form-block'>
                <div className='tab-form-block-column'>
                     <div className="form-label-name">
                        <span className="label">Langues</span>    
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                                
                            }}
                            optionLabelProp="label"
                            optionFilterProp="label"
                            placeholder="Langues"
                            defaultValue={[]}
                            value={languages}
                            onChange={(e)=>handlerSelectMethod(e,"setLanguages")}
                            options={Languages}
                            className="filter-select"
                        />
                    </div>  
                </div>    
                <div className='tab-form-block-column' style={{margin:"0 16px"}}>
                    <div className="form-label-name">
                        <span className="label">Réf. Devis</span>    
                        <Input 
                            title='Réf. Devis'
                            // defaultValue={quote}
                            value={quote}
                            id="quote"
                            name="quote"
                            placeholder="Réf. Devis" 
                            className=""
                            onChange={(e)=>handlerChangeMethod(e,"setQuote")}
                        />
                    </div>
                </div> 
                <div className='tab-form-block-column'>
                    <div className="form-label-name">
                        <span className="label">Nb. Total à booker</span>    
                        <InputNumber 
                            title='Nb. Total à booker'
                            // defaultValue={bookedTotal}
                            value={bookedTotal}
                            id="bookedTotal"
                            name="bookedTotal"
                            placeholder="Nb. Total à booker" 
                            style={{width:'100%'}}
                            onChange={(e)=>handlerChangeMethod(e,"setBookedTotal")}
                        />
                    </div>
                </div>
                <div className='tab-form-block-column' style={{width:'150px'}}>
                    <div className="form-label-name" style={{marginLeft:'16px'}}>
                        <span className="label">Date Imposée</span>
                        <br/> 
                         <Checkbox              
                            style={{marginTop:'6px'}}               
                            id='dateImposed'
                            checked={dateImposed} 
                            className="custom-checkbox"
                            onChange={(e)=>handlerChangeMethod(e,"setDateImposed")}
                        />       
                    </div>
                </div>
            </div>   
            <div className='tab-form-block'>
                <div className="form-label-name">
                    <span className="label">Déscription Client (PDF)</span>    
                    <TextArea 
                        title='Déscription Client (PDF)'
                        value={bodyClient}
                        onChange={(e)=>handlerChangeMethod(e,"setBodyClient")}
                        className="bmg-textarea" 
                        rows={4} 
                        placeholder='Déscription Client (PDF)'
                    />
                </div>                 
            </div>  
            <div className='tab-form-block'>
                <div className='tab-form-block-column'>
                    <div className="form-label-name">
                        <span className="label">Déscription Staff (App)</span>    
                        <TextArea 
                            title='Déscription Staff (App)'
                            value={body}
                            onChange={(e)=>handlerChangeMethod(e,"setBody")}
                            className="bmg-textarea" 
                            rows={4} 
                            placeholder='Déscription Staff (App)'
                        />
                    </div> 
                </div> 
                <div className='tab-form-block-column'>
                    <div className="form-label-name">
                        <span className="label">Instructions (App + Contrat)</span>    
                        <TextArea 
                            title='Instructions'
                            value={instruction}
                            onChange={(e)=>handlerChangeMethod(e,"setInstruction")}
                            className="bmg-textarea" 
                            rows={4} 
                            placeholder='Instructions'
                        />
                    </div> 
                </div>
            </div>  
            <div className='tab-form-block'>
                <div className="form-label-name">
                    <span className="label">Dress Code (App + Contrat)</span>    
                    <TextArea 
                        title='Dress Code'
                        value={dressCode}
                        onChange={(e)=>handlerChangeMethod(e,"setDressCode")}
                        className="bmg-textarea" 
                        rows={2} 
                        placeholder='Dress Code'
                    />
                </div> 
            </div>
            <div className='tab-form-block'>
                <div className="form-label-name">
                    <span className="label">Contact (App + Contrat)</span>    
                    <TextArea 
                        title='Contact'
                        value={contact}
                        onChange={(e)=>handlerChangeMethod(e,"setContact")}
                        className="bmg-textarea" 
                        rows={2} 
                        placeholder='Contact'
                    />
                </div> 
            </div>            
            <div className='tab-form-block' style={{justifyContent:'space-between'}}>               
                <div className='tab-form-block-column' style={{margin:"0 16px"}}>
                    <div className="form-label-name">
                        <span className="label">Latitude (App)</span>    
                        <Input 
                            title='Latitude (App)'
                            value={latitude}
                            id="latitude"
                            name="latitude"
                            placeholder="Latitude" 
                            onChange={(e)=>handlerChangeMethod(e,"setLatitude")}
                        />
                    </div>
                </div> 
                <div className='tab-form-block-column' style={{margin:"0 16px"}}>
                    <div className="form-label-name">
                        <span className="label">Longitude (App)</span>    
                        <Input 
                            title='Longitude (App)'
                            value={longitude}
                            id="longitude"
                            name="longitude"
                            placeholder="Longitude" 
                            onChange={(e)=>handlerChangeMethod(e,"setLongitude")}
                        />
                    </div>
                </div>
                <div className='tab-form-block-column' style={{margin:"0 16px"}}>
                    <div className="form-label-name link" onClick={()=>window.open('https://www.latlong.net/')}>
                        <br/>
                        <span>Trouver les coordonnées {'>>'}</span>    
                    </div>
                </div>                  
            </div>  
        </div>

    )
};

export default FormGeneral;