import React, { useEffect, useState } from 'react';
import {  Divider,Upload} from 'antd';
import moment from 'moment';
import { MenuFoldOutlined,MenuUnfoldOutlined, FilePdfOutlined,PlusCircleOutlined} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNotification } from '../../../hooks/NotificationProvider';
import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import { useSelector,useDispatch } from 'react-redux';
import { InstagramFilled } from '@ant-design/icons';

import { MediaUrl,  UploadSectionFile } from "../../../services/File"
import { saveLists } from '../../../features/lists/listsSlice';
import { saveUser } from '../../../features/user/userSlice';
import { BmgStatus, ScoresValues } from "../../../utils/constants";
import { capitalize, getGeographicAvailibilities, getAddress, getGender, getAge,getBirthday, getCountry, scoreContent,updateList } from "../../../utils/miscHelpers";
import { EditUserProps } from "../../../services/User";
import PopOver from "../../../components/elements/PopOver";
import LabelForm from "../../../components/label/LabelForm";
import OperationPdf from '../../../components/operation/elements/PdfOperation';

const UserGeneral = ({section, bmgFilters, openGeneral, setOpenGeneral}) => {
    
    const { openNotification } = useNotification();
    const dispatch = useDispatch();
    const { t } = useTranslation();    

    const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);
    const storedUser = useSelector(state => state.user.value && state.user.value ? state.user.value  : {});
    const storedLabelList = useSelector(state => state.lists.value && state.lists.value['label'] ? state.lists.value['label'] : []);  
    
    const [version, setVersion] = useState(1);
    const [userData, setUserData] = useState(storedUser);

    useEffect(() => {
        setUserData(storedUser);
    }, [storedUser]);   
    
    const onCloseGeneral = () =>{
        setOpenGeneral(openGeneral ? false : true);
    };

    const onPopChange = async (user,props)=>{
        try {
            // console.log('UserGeneral onPopChange');
          const userUpdated = await EditUserProps(user,props);
          if (userUpdated && userUpdated._id) {  
            dispatch(saveUser(userUpdated));
            dispatch(saveLists({ section : section, data : updateList(storedList,userUpdated) }));
            openNotification('success','Modification Enregistrée');
          } else {
            openNotification('error','Probléme lors de la sauvegarde');
          }
        } catch (error) {
          console.error('Error onPopChange EditUserProps:', error);
          openNotification('error','Catch / Probléme lors de la sauvegarde');
        }     
    };
   
    const onLabelUpdated = (record) => {
        const userUpdated = {...storedUser,...{label:record.label}};
        const updatedList = updateList(storedList,userUpdated) ;
        //const updatedList = updateList(listItems,{...userRecord,...{label:record.label}}) ;
        dispatch(saveUser(userUpdated));
        dispatch(saveLists({section:section,data:updatedList}));
        return true;
    };

    const genPdf = async () =>{

        const blob = await pdf(
            <OperationPdf 
                storedOperation={{}} 
                staffList={[userData]} 
                selectedTypes={['user']}
                selectedUsers={[userData._id]}
                version={version} 
            />
        ).toBlob();
                
        saveAs(blob, 'BeMyGuest-' + capitalize(userData.firstName) + capitalize(userData.lastName[0]) + '.pdf');
        
        setVersion(version+1);
    };    

    const addAvatar = async ({ file, onSuccess, onError }) => {
        try {     
            // console.log('addAvatar',storedUser._id);       
            const userUpdated = await UploadSectionFile(file, 'avatar', storedUser._id); 
            if (userUpdated && userUpdated._id) {
                openNotification('success','Avatar Sauvegardée');                 
                const files = await MediaUrl(userUpdated.medias,'media','small') ;             
                const avatar = await MediaUrl(userUpdated.medias,'avatar','small');
                dispatch(saveUser({ ...userUpdated ,avatar:avatar, files:files }));                    
                onSuccess();
            } else {
                openNotification('error','Probléme lors de la sauvegarde');
                onError();
            }
        } catch (error) {
            console.error('addAvatar',error);
            onError();
        }
    };

    const cardMetaDescription = (user) =>{        
        return ( 
            <>
                <div className="card-content" style={{ width: "250px"}}>
                    {user.isPaused &&
                        <div className="tag" style={{backgroundColor:'var(--red-color)',marginBottom:'16px'}}>Compte sur Pause</div> 
                    }
                    {user.ldate && 
                        <div style={{marginBottom:'6px'}}>
                            <span className="h5bl">Connexion le {moment(user.ldate).format('DD/MM/YYYY à HH[h]mm')}</span>
                        </div> 
                    } 
                    {user.cdate && 
                        <div style={{marginBottom:'6px'}}>
                        <span className="h5bl">Inscrit depuis le {moment(user.cdate).format('DD/MM/YYYY')}</span>
                        </div> 
                    }                      
                    <div onClick={()=>genPdf()} className='flex link' title='Générer le fichier de présentation en PDF' style={{
                            marginTop:'16px',
                            marginBottom:'16px',
                            alignItems: "center",
                            justifyContent: "flex-start"
                        }}>
                        <FilePdfOutlined style={{marginRight:'6px',fontSize:'16px'}}/>
                        <span className="">{'>'} Fiche de Présentation PDF</span>                        
                    </div>  
                    <div>
                        <div>
                            <span className="h2">{t('user.gender')} : </span>
                            <span>{getGender(user.gender)}</span>
                        </div>
                        <div>
                            <span className="h2">{t('user.height')} : </span>
                            <span>{user.height ? user.height+'cm' : '-'}</span>
                        </div>  
                        <div>
                            <span className="h2">{t('user.body')} : </span>
                            <span className="uppercase">{user.bodyUp} / {user.bodyDown}</span>
                        </div>  
                        <div>
                            <span className="h2">{t('user.shoeSize')} : </span>
                            <span className="uppercase">{user.shoeSize}</span>
                        </div>
                    </div>
                    <div style={{width:'100%',maxWidth:'60%',margin:0}}>
                        <div>
                            <span className="h2">{t('user.birthday')} : </span>
                            <span>{getBirthday(user.birthday)}</span>
                        </div>
                        <div>
                            <span className="h2">{t('user.age')} : </span>
                            <span>{getAge(user.birthday)}</span>
                        </div>              
                        <div>
                            <span className="h2">{t('user.nationality')} : </span>
                            <span className="capitalize">{user.nationality ? getCountry(user.nationality ,'fr') : ''}</span>
                        </div>   
                        <div>
                            <span className="h2">{t('user.profileCompleted')} : </span>
                            <span>{storedUser.profileCompleted ? storedUser.profileCompleted : 0}%</span>
                        </div>                        
                        {(user._sponsor && user._sponsor._id) && 
                            <div>
                                <span className="h2">{t('user.sponsor')} : </span>
                                <span className="capitalize">{capitalize(user._sponsor.firstName)} {capitalize(user._sponsor.lastName)}</span>
                            </div>                               
                        }   
                        {(user.invitees && user.invitees.length !==0 ) && 
                            user.invitees.map((invitee)=>{
                                if (invitee.status === 1) {
                                    return(
                                        <div>
                                            <span className="h2">{t('user.godFather')} : </span>
                                            <span className="capitalize">{capitalize(invitee.firstName)} {capitalize(invitee.lastName)}</span>
                                        </div>  
                                    ) 
                                } else {
                                    return null
                                }
                            })                            
                        }                                                        
                        <div>
                            <span className="h2" >{t('user.bmgStatus')} : </span>
                            <PopOver 
                                content={
                                    <span className="capitalize tag" style={{backgroundColor:user.bmgStatus ? BmgStatus[user.bmgStatus].color : BmgStatus[0].color}}>{user.bmgStatus ? BmgStatus[user.bmgStatus].label : BmgStatus[0].label}</span>
                                }              
                                prop="bmgStatus" 
                                handlerMethod={onPopChange}
                                items={BmgStatus}
                                user={user}
                            />
                        </div>
                     </div>            
                </div>
                <div className="card-content" style={{ width: "250px"}}>
                    <div>   
                        <div>
                            <span className="h2">{t('user.email')} : </span>
                            <span>{user.email}</span>
                        </div>
                        <div>
                            <span className="h2">{t('user.phone')} : </span>
                            <span>{user.phone}</span>
                        </div>
                        <div>
                            <span className="h2 capitalize">{t('user.address')} : </span>
                            <span>{getAddress(user)}</span>
                        </div>
                        <div>
                            <span className="h2 capitalize">{t('user.geographicAvailability')} : </span>
                            <span>{getGeographicAvailibilities(user.geographicAvailability)}</span>
                        </div>
                        {user.instagram &&
                            (
                            <div>
                                <a className="icon-link" title="Ouvrir sur Instagram" href={'https://instagram.com/' + user.instagram } target="new"><InstagramFilled/></a>            
                            </div>            
                            )
                        }
                        <div style={{ paddingBottom:"16px",textAlign: "right"}}>
                            <LabelForm 
                                section={section}
                                labels={user.label}
                                record={user}
                                handlerMethod={onLabelUpdated}
                                storedLabelList={storedLabelList || []}                
                            />
                       </div>                                                           
                    </div>
                </div>
          </>
        );
      }

    return(
        <div className={'user-general '+ (openGeneral ? 'unfolded' : 'folded')} >
            <div onClick={(onCloseGeneral)} title="Ouvrir" className='fold-user-general link'>
                {openGeneral === true &&
                    <div style={{marginLeft:'10px',marginTop:'3px',position:'absolute',}}>                        
                        <MenuFoldOutlined className='link' style={{marginRight:'12px',color:'var(--accent-color)'}}/> 
                    </div>
                }   
                {openGeneral !== true &&
                    <div className='flex' style={{marginLeft:'10px',marginTop:'3px',position:'absolute'}}>                        
                        <MenuUnfoldOutlined className='link' style={{marginRight:'12px',color:'var(--accent-color)'}}/> 
                        <div className='h2' style={{width:'70vw',textAlign:'left'}}>{storedUser ? capitalize(storedUser.firstName) +' '+capitalize(storedUser.lastName) : ''}</div>
                    </div>                        
                }                
            </div>     
            {openGeneral === true &&
                <>
                    <div className='avatar-block'>      
                        <div className='add'>
                            <Upload 
                                defaultFileList={[]}
                                fileList={[]}
                                customRequest={addAvatar}
                                name='file'         
                                className='media-detail-uploader'       
                                >
                                <PlusCircleOutlined style={{cursor:'pointer',fontSize: '60px',color:'white',opacity:0.8}} title="Ajouter un nouvelle photo d'Avatar"/>
                            </Upload>  
                            </div>
                            {/* <PlusCircleOutlined style={{color:'white',opacity:0.6}}  onClick={()=>addAvatar()} /></div>           */}
                        <img alt="avatar" src={storedUser.avatar ? storedUser.avatar : '/img/placeholder-avatar.jpg'} className="avatar-detail-user" />
                    </div>
                    <div>
                        <PopOver 
                            content={
                                <div className="score-detail-user">{scoreContent(storedUser.score,'score-large')}</div>
                            }
                            prop="score"
                            handlerMethod={onPopChange}
                            items={ScoresValues}
                            user={storedUser}
                            />  
                    </div>
                    <div className="h2 capitalize">{capitalize(storedUser.firstName) + ' '+ capitalize(storedUser.lastName)}</div>
                    <Divider className="divider-list-user"/>
                    {cardMetaDescription(storedUser)}                
                </>
            }                      
        </div>
    )
};

export default UserGeneral;
