import React,{useEffect, useState} from "react";
import dayjs from "dayjs";

import { useSelector, useDispatch } from 'react-redux';

import {  Calendar,ConfigProvider } from 'antd';
import frFR from 'antd/es/locale/fr_FR';

import WeekViewCalendar from './WeekViewCalendar';

import { saveLists } from '../../../features/lists/listsSlice';

import { ListAgenda } from "../../../services/Agenda"
import PopOverForm from "../../../components/elements/PopOverForm"
import HeaderBar from "../../../components/layout/HeaderBar";

import EventContent from "./EventContent";

const section = 'agenda';

const AgendaList = () => {

  const dispatch = useDispatch();

  const storedFilters = useSelector(state => state.filters.value && state.filters.value[section] ? state.filters.value[section] : {});
  const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);  

  const [events, setEvents] = useState(storedList || []);
  const [tmpTodoList, setTmpTodoList] = useState([]);
  // const [openPops,setOpenPops] = useState([]);
  const [loading, setLoading] = useState(false);

  const [view, setView] = useState('week');
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [viewDate, setViewDate] = useState(dayjs()); // Separate state for panel navigation


  useEffect(() => {
    const fetchData = async () => {
      try {
        const resultList = await ListAgenda({});
        setEvents(resultList);        
        dispatch(saveLists({ section : section, data : resultList}));
        setLoading(false);
        return;
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    setLoading(true);
    fetchData();    
  }, [tmpTodoList]);

  // const onChangeCell = (ele) => {
  //   return true;
  // };

  const dateCellRender = (value) => {
    return (
      <ul key={dayjs(value).format('YYYY-MM-DD')} className="agenda-events">
        {events.map((item, index) => {
          if (item && item.date === dayjs(value).format('YYYY-MM-DD')) {            
            return <EventContent section={section} item={item} index={index} setTmpTodoList={setTmpTodoList}/>
          } else {
            return false;
          }
        })}
      </ul>
    );
  };

  const handleTodayClick = () => {
    setSelectedDate(dayjs());
    setViewDate(dayjs()); // Ensure the panel updates to today
  };

  const onChangeCell = (date) => {
    setSelectedDate(date);
  };

  const onPanelChange = (date) => {
    setViewDate(date); // Allow month and year selection
  };

  const onSubmit = (newlist) =>{
    // console.log('onSubmit newlist',newlist);
    // update Todo Stored list 
    setTmpTodoList([]);
    return true;
  };
  return (
    <div className="container">
        <HeaderBar 
          actionContent={
            <div style={{ marginLeft:"16px"}}>
              <PopOverForm
                content={
                  <div className="secondary-button">+</div>
                }
                form="todo"
                handlerMethod={onSubmit}
                data={{}}
              />
            </div>
          }
          loading={loading}
          hasFilters={false} 
          hasSort={false} 
          hasSearch={false} 
          section={section} 
          storedFilters={storedFilters} 
          setCurrentPage={0}
        />
        <div className="content">
        <div className="view-mode-switcher " style={{marginTop:'-40px'}}>            
            <div className={`view-option ${view === 'week' ? 'active' : ''}`} onClick={()=>setView('week')}>Semaine</div>
            <div className={`view-option ${view === 'month' ? 'active' : ''}`}onClick={()=>setView('month')}>Mois</div>
          </div>
          <div className="tab-content">            
              {view === 'month' && 
                  <ConfigProvider locale={frFR}>
                      <div className='link' style={{zIndex: 10,position: 'absolute',right: '200px',top: '30px'}} onClick={handleTodayClick}>Aujourd'hui</div>
                      <Calendar 
                        onChange={onChangeCell}
                        onPanelChange={onPanelChange}
                        value={selectedDate}
                        dateCellRender={dateCellRender} 
                      />   
                  </ConfigProvider>           
              }
              {view === 'week' && 
                  <WeekViewCalendar 
                    events={events}
                    locale="fr"
                    setTmpTodoList={setTmpTodoList}
                  />
              }            
          </div>
        </div>
    </div>    
  );
};

export default AgendaList;