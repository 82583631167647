import React, { useState } from 'react';
import { Popover } from 'antd';
import { scoreContent,capitalize } from "../../utils/miscHelpers"

const PopOver = ({content,user,prop,items,handlerMethod}) => {

    const [open, setOpen] = useState(false);    

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const popSelect = (val)=>{
        let props = {};
        if (['OpBmgStatus','clientBmgStatus'].includes(prop)) prop='bmgStatus';
        props[prop]=val;
        handlerMethod(user,props);
        setOpen(false);
    };
    
    const popContent = () => {

        const popItems = [];

        switch(prop){
            case 'bmgStatus':
                for(const item in items){
                    if (item>0) {
                        popItems.push({
                            value:item,
                            label : items[item].label,
                            color:items[item].color,
                            className:"pop-item tag small"
                        });
                    }
                }            
                break;
            case 'OpBmgStatus':
                for(const item of items){
                    popItems.push({
                       ...item,
                        className:"pop-item tag"
                    });
                }            
                break; 
            case 'clientBmgStatus':
                for(const item of items){
                    popItems.push({
                        ...item,
                        className:"pop-item tag"
                    });
                }            
                break;                  
            case 'score':
                for(const item in items){
                    popItems.push({
                        value:item,
                        label : scoreContent(item),
                        className:"pop-item stars"
                    });
                }   
                break;
            case 'color': 
                for(const item of items){
                    popItems.push({
                        value:item.color,
                        label:<div style={{padding:"8px"}}/>,
                        color:item.color,
                        className:"pop-item tag"
                    });
                }
                break;   
            case 'label':
                for(const item of items){                    
                    if (item && item._id) {
                        popItems.push({
                            value:item._id,
                            label : item.name,
                            color:item.color,
                            className:"pop-item tag small"
                        });
                    }
                }   
                break;                             
            default:
        }
        
        return (
            <div className='capitalize'>
                {popItems && popItems.length && popItems.map((item) => (
                    <div className={item.className} style={item.color ? {backgroundColor:item.color}: null} onClick={()=>popSelect(item.value)} key={item.value}>{item.label}</div>
                ))}
            </div>
        );
    };


    return (
        <Popover            
            content={popContent}
            trigger="click"
            open={open}
            className="bmg-popover"
            onOpenChange={handleOpenChange}
        >
            <span onClick={()=>handleOpenChange} >{content}</span>
        </Popover>
    );
};
export default PopOver;