import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { Checkbox, Input, Table, Modal, Space, Button } from 'antd';
import { IssuesCloseOutlined,RedoOutlined,UndoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { capitalize, CheckStaffAgenda, GetMissionStaffStatus , FormatMissionSlotsByRoles, getAge, scoreContent } from "../../../utils/miscHelpers"
import { SlotBmgStatus  } from "../../../utils/constants";
import LegendStatus from '../../../components/elements/LegendStatus';

const StaffMissionSlotsModal = ({staffList,  storedOperation, isModalOpen, closeModal, onAffectUsers}) => {    
    
    const { t } = useTranslation();    

    const roles = FormatMissionSlotsByRoles(storedOperation && storedOperation.missionSlots ? storedOperation.missionSlots : [])    
    
    const [selectedSlots, setSelectedSlots] = useState([]);
    //const [bookedStaff, setBookedStaff] = useState({});
    
    const [selectedStaffCheck, setSelectedStaffCheck] = useState([]);    
    const [selectedRoleKeySlots, setSelectedRoleKeySlots] = useState([]);    
    const [roleKeyDisabled, setRoleKeyDisabled] = useState([]);
    const [rollOverStaff, setRollOverStaff] = useState(null);
    const [reverseList, setReverseList] = useState(false);
    const [listData, setListData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);
    const [hiddenStaff, setHiddenStaff] = useState([]);
    const [userList, setUserList] = useState([]);
    const [sortedStaffList, setSortedStaffList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const onReverseList = () =>{
        setSelectedStaffCheck([]);
        setReverseList(!reverseList);
    };

    useEffect(() => {       
        const retrieveHiddenStaffFromStatus = () =>{            
            const hiddenStaffList = [];
            const cleanUserList = [];
            if (sortedStaffList && sortedStaffList.length) {
                let staffIdx = 0;
                for(const staff of sortedStaffList){
                    let hide = 0;
                    let nbSlots = 0;
                    for(const roleKey in roles){
                        const role = roles[roleKey];                                
                        if (role && role.missionSlots && role.missionSlots.length) {
                            let midx = 0;
                            for(const mission of role.missionSlots){
                                const { missionStaffStatus } = prepareCheckboxOptions(
                                    roleKey,
                                    midx,
                                    staffIdx,
                                    storedOperation,
                                    staff,
                                    mission
                                );
                                if (statusFilter.includes(missionStaffStatus)) hide++;
                                if (missionStaffStatus === null && statusFilter.includes(-1)) hide++;
                                midx++;
                                nbSlots++;
                            }
                        }
                    }                    
                    if (hide === nbSlots) {
                        hiddenStaffList.push(staff._id);
                    } else {
                        cleanUserList.push(staff);
                    }
                }
                staffIdx++;
            }            
            setUserList(cleanUserList);
            setHiddenStaff(hiddenStaffList);        
        };

        retrieveHiddenStaffFromStatus();        
    }, [statusFilter]); 

    useEffect(() => {  
        selectStaff();
        prepareTable();
    }, [hiddenStaff,userList]); 
    
    const sortUserList = () =>{
        if (storedOperation && storedOperation.users && storedOperation.users.length) {
            return [...storedOperation.users]
                .reverse() // Reverse the order of `storedOperation.users`
                .map(userId => staffList.find(ele => ele._id === userId)) // Map to matching staff objects
                .filter(staff => staff && staff._id); // Filter out invalid matches
        } 
        return [];
    };
    
    const onChangeTerm = (e) =>{        

        const term = e.target.value;
        if (!term || term === '') {
            setSearchTerm(''); 
            setUserList(sortedStaffList);
            return;
        }

        const filteredUsers = [...sortedStaffList].filter(user => 
            user.firstName.toLowerCase().includes(term.toLowerCase()) ||
            user.lastName.toLowerCase().includes(term.toLowerCase())
        );    
        
        setUserList(filteredUsers);
        setSearchTerm(term);    
    };

    const onCheckbox = (cellKey)=>{
        let newSelectedBox = [...selectedSlots];
        if (newSelectedBox.includes(cellKey)) {
            newSelectedBox = newSelectedBox.filter((ele)=>ele !== cellKey)
        } else {
            newSelectedBox.push(cellKey);            
        }
        setSelectedSlots(newSelectedBox);        
    }

    const linkMeToUser = async (user)=>{
        return window.open(process.env.REACT_APP_PUBLIC_BO_URL+'/user/detail?staffId='+user._id);
    };

    const onCheckStaffBox = (staff)=>{        
        
        let newSelectedBox = [...selectedSlots];
        let newSelectedStaffBox = [...selectedStaffCheck];

        if (newSelectedStaffBox.includes(staff._id)) {
            newSelectedStaffBox = newSelectedStaffBox.filter((ele)=>ele !== staff._id);
            
            if (roles && Object.keys(roles).length > 0) {
                for(const roleKey in roles){                
                    if (roles[roleKey] && roles[roleKey].missionSlots && roles[roleKey].missionSlots.length){                
                        for(const idx in roles[roleKey].missionSlots){                        
                            for(const staffIdx in userList){                        
                                const kkey = roleKey+'-'+idx+'-'+staffIdx;
                                if (userList[staffIdx] && userList[staffIdx]._id === staff._id) {
                                    const missionStaffStatus = GetMissionStaffStatus(storedOperation._id, staff, roles[roleKey].missionSlots[idx]);
                                    if (newSelectedBox.includes(kkey) && !missionStaffStatus) newSelectedBox = newSelectedBox.filter((ele)=>ele !== kkey);
                                }
                            }
                        }
                    }  
                }
            }

        } else {
            newSelectedStaffBox.push(staff._id);   
            
            if (roles && Object.keys(roles).length > 0) {
                for(const roleKey in roles){                
                    if (roles[roleKey] && roles[roleKey].missionSlots && roles[roleKey].missionSlots.length){                
                        for(const idx in roles[roleKey].missionSlots){                        
                            for(const staffIdx in userList){                        
                                const kkey = roleKey+'-'+idx+'-'+staffIdx;
                                if (userList[staffIdx] && userList[staffIdx]._id === staff._id) {
                                    if (!newSelectedBox.includes(kkey)) newSelectedBox.push(kkey);
                                }
                            }
                        }
                    }  
                }
            }            

        }        
        setSelectedStaffCheck(newSelectedStaffBox);
        setSelectedSlots(newSelectedBox);
        
    };
    
    // const onRoleCheckbox = (cellKey)=>{
    //     const [roleKey,staffIdx] = cellKey.split('-');
    //     let newSelectedRoleKeySlots = [...selectedRoleKeySlots];
    //     let newSelectedBox = [...selectedSlots];
    //     if (newSelectedRoleKeySlots.includes(cellKey)) {
    //         newSelectedRoleKeySlots = newSelectedRoleKeySlots.filter((ele)=>ele !== cellKey);

    //         if (roles[roleKey] && roles[roleKey].missionSlots && roles[roleKey].missionSlots.length){                
    //             for(const idx in roles[roleKey].missionSlots){
    //                 const kkey = roleKey+'-'+idx+'-'+staffIdx;
    //                 newSelectedBox = newSelectedBox.filter((ele)=>ele !== kkey)
    //             }
    //         }  
    //     } else {
    //         newSelectedRoleKeySlots.push(cellKey);

    //         if (roles[roleKey] && roles[roleKey].missionSlots && roles[roleKey].missionSlots.length){                
    //             for(const idx in roles[roleKey].missionSlots){
    //                 const kkey = roleKey+'-'+idx+'-'+staffIdx;
    //                 if (!newSelectedBox.includes(kkey)) newSelectedBox.push(kkey);
    //             }
    //         }            
    //     }
    //     setSelectedRoleKeySlots(newSelectedRoleKeySlots);        
    //     setSelectedSlots(newSelectedBox);
    // }
    
    const onCheckAll = () =>{        
        let newSelectedBox = [];
        if (roles && Object.keys(roles).length > 0){
            for(const roleKey in roles){                
                if (roles[roleKey] && roles[roleKey].missionSlots && roles[roleKey].missionSlots.length){                
                    for(const idx in roles[roleKey].missionSlots){
                        //let staffIdx=0;
                        for(const staffIdx in userList){                        
                            const kkey = roleKey+'-'+idx+'-'+staffIdx;
                            if (!newSelectedBox.includes(kkey)) newSelectedBox.push(kkey);
                            //staffIdx++;
                        }
                    }
                }  
            }
        }
        setSelectedSlots(newSelectedBox);
    };
    
    const StaffBlock = ({staff})=>{
        return(
            <div className={"flex " + (reverseList ? '' : 'flex-row')} style={{justifyContent:'space-around',alignItems: "center",padding:'0 0',width:'100%',margin:'0 0'}}>
                <div className='flex' style={{ width:'200px',  maxWidth: '200px',alignItems: "center",flexDirection:'column'}}>
                    <div className='link' onClick={()=>linkMeToUser(staff)} >
                        <img alt="user" src={staff.avatar ? staff.avatar : '/img/placeholder-avatar.jpg'} className="avatar-small" />
                    </div>
                    {(staff.score !== null && staff.score !== 0) && 
                        <div className="score-list-user-small">{scoreContent(staff.score,'score-small')}</div>
                    }                                                                     
                    <div className="custom-name capitalize bold link" style={{ textAlign: 'center'}}  onClick={()=>linkMeToUser(staff)} >{staff.firstName +' '+staff.lastName}</div> 
                    <div className='flex flex-row' style={{alignItems: 'center'}}>
                        <span className='tab-table-row-span'>({staff.gender === 1 ? 'H' : 'F'}) {staff.birthday ? getAge(staff.birthday)+' ans' : ''} {staff.height ? (staff.height/100).toFixed(2)+'m' : ''}</span>                     
                        <span className='tab-table-row-span'>{capitalize(staff.city)}</span>
                    </div>                   
                </div>
                <div style={{textAlign:'center',marginTop:reverseList ? '0': '16px'}}>
                    <Checkbox
                        style={{ zIndex: 1 }}
                        title={capitalize(staff.firstName) + " " + capitalize(staff.lastName)}
                        className="custom-checkbox big"
                        checked={selectedStaffCheck.includes(staff._id)}
                        onChange={() => onCheckStaffBox(staff)}
                    />
                </div>
            </div>
        );
    }

    const SlotBlock = ({role, mission})=>{
        return(
            <div className='cartouche-mission-item' style={{ width: '80%', marginLeft: 16, fontSize: '12px' }}>
                <div>
                <div className='bold'>
                    {capitalize(mission.location.name)} {capitalize(mission.location.address)} {capitalize(mission.location.city)} 
                    - {mission.users ? mission.users.length : 0} / {mission.workForce}
                </div>
                <div className='bold capitalize'>{dayjs(mission.eday).format('dddd D MMMM YYYY')}</div>
                <div className='uppercase'>{role.name} - {mission.timeSlot.stime} <span className='uncapitalize'>à</span> {mission.timeSlot.etime}</div>                                                                
                </div>                                                                                                                      
            </div>
        );
    };

    const CheckboxBlock = ({midx, staff,staffIdx,slotAgenda,SlotBmgStatus,missionStaffStatus,cellKey})=>{
        
        return(
            <div key={midx.toString()} style={{ margin: "6px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <div style={{ width: "176px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div key={staffIdx.toString()}>
                        {slotAgenda && slotAgenda.bookable === false && (
                            <div>
                                <IssuesCloseOutlined
                                    color="black"
                                    style={{ color: "var(--violet-color)", fontSize: "21px", cursor: "cell" }}
                                    title={
                                        capitalize(staff.firstName) +
                                        " " +
                                        capitalize(staff.lastName) +
                                        " est déjà Booké(e) sur " +
                                        (slotAgenda && slotAgenda.operation ? slotAgenda.operation : "")
                                    }
                                />
                            </div>
                        )}
                        {(!slotAgenda || (slotAgenda && slotAgenda.bookable)) && (
                            <div>
                                <Checkbox
                                    style={{ zIndex: 1 }}
                                    title={capitalize(staff.firstName) + " " + capitalize(staff.lastName)}
                                    className={
                                        "custom-checkbox big " + (SlotBmgStatus[missionStaffStatus] ? "has-" + SlotBmgStatus[missionStaffStatus].css : "")
                                    }
                                    checked={selectedSlots.includes(cellKey)}
                                    disabled={missionStaffStatus ? true : false}
                                    onChange={() => (!missionStaffStatus ? onCheckbox(cellKey) : null)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const prepareCheckboxOptions = (roleKey, midx, staffIdx, storedOperation, staff, mission) => {
        const cellKey = `${roleKey}-${midx}-${staffIdx}`;
        const missionStaffStatus = GetMissionStaffStatus(storedOperation._id, staff, mission);
        const staffAgenda = CheckStaffAgenda(
          storedOperation,
          [{ eday: mission.eday, stime: mission.timeSlot.stime, etime: mission.timeSlot.etime }],
          staff.missions
        );
        const slotAgenda = staffAgenda.find(
          (ele) =>
            ele.eday === mission.eday &&
            ele.stime === mission.timeSlot.stime &&
            ele.etime === mission.timeSlot.etime &&
            !ele.bookable
        );
      
        return { cellKey, missionStaffStatus, staffAgenda, slotAgenda };
      };
    
    const prepareTable = () =>{        
        const firstColumn ={ 
            key: "item", 
            title: (
              <div className='flex cartouche-mission-item_blank  mission-table-role-cell' style={{ width: reverseList ? '200px' : '16%',alignSelf: "self-start" ,backgroundColor:'white',justifyContent: "space-between",}}>
                  <div className='flex-row'>
                    <div className='flex' style={{justifyContent: 'space-between'}}>
                        <LegendStatus 
                            shape='square' 
                            type={'affect'}
                            direction='column'
                            clickable={true}
                            statusFilter={statusFilter}
                            setStatusFilter={setStatusFilter}                       
                        />
                      {reverseList && 
                          <RedoOutlined color='black' style={{marginRight:'32px',fontSize:'21px',cursor:'pointer'}} title="Inverser les colonnes" onClick={()=> onReverseList()}/>
                      }
                      {!reverseList && 
                          <UndoOutlined color='black' style={{marginRight:'32px',fontSize:'21px',cursor:'pointer'}} title="Inverser les colonnes" onClick={()=> onReverseList()}/>
                      }
                    </div>
                    <div style={{marginBottom:'16px'}}>
                        <Input placeholder="Rechercher" allowClear onChange={onChangeTerm} />
                    </div>                    
                  </div>
              </div>
            ), 
            dataIndex: "item", 
            width: reverseList ? '250px' : '250px',
            fixed: 'left',      
            render: (value,record) => {
                return ( 
                  <> 
                      <div style={{ maxWidth:'240px', paddingRight:'16px',paddingTop:reverseList ? '16px' : '0',height:reverseList ? '120px' : 'initial',backgroundColor: 'white' }}>{value}</div>
                  </> 
                ); 
              },      
        };

        let headerColumns = [];
        const dataList = reverseList ? Object.keys(roles) : userList;
        for(const item of dataList){
            if (reverseList) {
                const role = roles[item];
                if (role.missionSlots){
                    let midx = 0;
                    for(const mission of role.missionSlots){
                        const mKey = item + "-" + midx 
                        headerColumns.push({ 
                            key: mission._id,                 
                            title: (<SlotBlock role={role} mission={mission}/>),
                            dataIndex: 'checkbox', 
                            width: '250px',
                            render: (_,record) => {
                                //if (!record.slots) return null;
                                const ele = record.slots.find((ele)=>  ele.key === mKey )
                                if (!ele) return null;
                                return ( 
                                  <> 
                                      <div>{ele.checkbox}</div>
                                  </> 
                                ); 
                              }, 
                        });
                        midx++;
                    }
                }
            } else {
                //console.log('item :>> ', item.firstName, !hiddenStaff.includes(item._id));
                //if (!hiddenStaff.includes(item._id)) {
                    headerColumns.push({ 
                        key: item._id,                 
                        title: (<StaffBlock staff={item}/>),
                        dataIndex: 'checkbox', 
                        width: '168px',
                        render: (_,record) => {
                            const ele = record.staffs.find((ele)=>  item._id === ele._id )
                            if (!ele) return false;
                            return ( 
                              <> 
                                  <div>{ele.checkbox}</div>
                              </> 
                            ); 
                          }, 
                    });
                //}
            }
        }
        
        setColumns([firstColumn, ...headerColumns]);
        
        let data = [];
        if (reverseList) {
            data = userList.map((staff, staffIdx) => {
                //if (hiddenStaff.includes(staff._id)) return false;

                const slots =  Object.keys(roles).flatMap((roleKey, index) => {
                    const role = roles[roleKey];                                
                    if (!role) return false;

                    return role.missionSlots.map((mission, midx) => {
                        const { cellKey, missionStaffStatus, slotAgenda } = prepareCheckboxOptions(
                            roleKey,
                            midx,
                            staffIdx,
                            storedOperation,
                            staff,
                            mission
                        );
                        return {
                            ...staff,
                            key:roleKey + "-" + midx,
                            checkbox: (
                                <CheckboxBlock
                                    midx={midx}
                                    staffIdx={staffIdx}
                                    staff={staff}
                                    slotAgenda={slotAgenda}
                                    SlotBmgStatus={SlotBmgStatus}
                                    missionStaffStatus={missionStaffStatus}
                                    cellKey={cellKey}
                                    />
                            ),
                        };
                    });
                });

                return {
                    item: <StaffBlock staff={staff}/>,
                    slots
                }
            });

        } else {
            data =  Object.keys(roles).flatMap((roleKey, index) => {
                const role = roles[roleKey];                                
                if (role) {  
                  return role.missionSlots.map((mission, midx) => {
                    
                    const staffs = dataList.map((staff, staffIdx) => {
                        //if (hiddenStaff.includes(staff._id)) return false;
                        const { cellKey, missionStaffStatus, slotAgenda } = prepareCheckboxOptions(
                            roleKey,
                            midx,
                            staffIdx,
                            storedOperation,
                            staff,
                            mission
                        );
                        return {
                            ...staff,
                            cellKey,
                            checkbox: (
                                <CheckboxBlock
                                    midx={midx}
                                    staffIdx={staffIdx}
                                    staff={staff}
                                    slotAgenda={slotAgenda}
                                    SlotBmgStatus={SlotBmgStatus}
                                    missionStaffStatus={missionStaffStatus}
                                    cellKey={cellKey}
                                    />
                            ),
                        };
                    });                
    
                    return {
                        item: <SlotBlock role={role} mission={mission}/>,
                        staffs
                    }
                  });
                }
                return []; 
              });

        }

        setListData(data);
    };

    const selectStaff = () =>{
        // Parse Staff Missions Already Selected
        const staffSelected = [];    
        for (const roleKey in roles){
            if (roles[roleKey] && roles[roleKey].missionSlots && roles[roleKey].missionSlots.length) {            
                let idx = 0;
                for(const mission of roles[roleKey].missionSlots){
                    if (mission && mission.users && mission.users.length) {
                        let staffIdx = 0;
                        for(const staff of userList){
                            if (mission && 
                                mission.users && 
                                mission.users.includes(staff._id) 
                            ) staffSelected.push(roleKey+'-'+idx+'-'+staffIdx);
                            staffIdx++;
                        }
                    }
                    idx++;
                }
            }        
        }
        setSelectedSlots(staffSelected);
    };
    useEffect(() => {
        
        const roleKeyDisable = () =>{
            const onRoleKeyDisabled = (roleKey) => {
                setRoleKeyDisabled((prevRoleKeyDisabled) => {
                    let newRoleKeyDisabled = [...prevRoleKeyDisabled];
                    if (!newRoleKeyDisabled.includes(roleKey)) newRoleKeyDisabled.push(roleKey);
                    return newRoleKeyDisabled;
                });
            };            
            if (roles) {
                Object.keys(roles).map((roleKey,index)=>{
                    const role = roles[roleKey];
                    if (role.missionSlots && role.missionSlots.length>0) {
                        role.missionSlots.map((mission,idx)=>{
                            return userList.map((staff,staffIdx)=>{
                                const missionStaffStatus = GetMissionStaffStatus(storedOperation._id, staff, mission);                                 
                                if (missionStaffStatus !== null) onRoleKeyDisabled(roleKey+'-'+staffIdx);
                                return true;
                            });        
                        });
                    }
                    return true;
                });
            }
        };

        setSortedStaffList(
            sortUserList()
        );

        setStatusFilter([0]); // Set default display - Here filter Refusé Status or could be [0,-1,3]
        setHiddenStaff([]);
    
        selectStaff();
        roleKeyDisable();

    }, [isModalOpen]);

    useEffect(() => {
        prepareTable();
    }, [selectedSlots,selectedStaffCheck,reverseList]);
    
    const onCancel = ()=>{      
        setSelectedRoleKeySlots([]);  
        setSelectedSlots([]);
        setReverseList(false);
        closeModal();
    };

    const onSubmit = async ()=>{        
        onAffectUsers(userList,[...selectedSlots]);
        setSelectedRoleKeySlots([]);  
        setSelectedSlots([]);
        setReverseList(false);
        closeModal();
    };

    const onTableChange = (pagination, filters, sorter, extra) => {
        //console.log('onTableChange ', extra); 
    };
   
    return (
        <>
            <Modal 
                title="Géstion du Staff & des Roles"
                open={isModalOpen}
                onCancel={onCancel}
                footer={
                    <Space>
                    {userList && userList.length !== 0 &&
                        <div className='flex' style={{alignItems:'center'}}>
                            <div className='link' onClick={onCheckAll}>Tout séléctionner</div>
                            <Button className="button-small cancel spacer" type="primary" onClick={onCancel}>{t('buttons.cancel')}</Button>
                            <Button className="button-small apply" type="primary" onClick={onSubmit}>{t('buttons.affectStaffOp')}</Button>
                        </div>
                    }
                    </Space>
                }
                width={'90%'}
                styles={{
                    maxWidth: '90%',
                    maxHeight: '80vh', 
                    body: {
                        height: 'calc(80vh - 120px)', 
                        overflowY: 'auto', 
                    }
                  }}               
                >
                <Table 
                    dataSource={listData} 
                    rowKey="_id"
                    columns={columns} 
                    pagination={false} 
                    className="capitalize custom-checkbox custom-table no-padding norowselection"
                    onChange={onTableChange} 
                    sticky={{ offsetHeader: 0 }}  
                    scroll={{
                        y: '80%',
                        x: '80%',
                    }}
                />          
            </Modal>
        </>
    );
};
export default StaffMissionSlotsModal;
