import React, { useState,useEffect } from 'react';
import dayjs from 'dayjs';
import {  Table } from 'antd';
import { SafetyCertificateOutlined, EyeOutlined, SendOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { pdf,Document, Page, Text, View,  Image, StyleSheet, Font } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

import { useNotification } from '../../../hooks/NotificationProvider';
import { ApiRoutes, Upload } from '../../../services/Api'
import {sortMissionSlotsByDays,getAddress, GetInformationBySlots, FormatUserMissionSlots, getMissionsDates, capitalize,getAge, getGender, CheckMissionSlotStaffStatus, getCountry, FormatStaffByRole, scoreContent } from "../../../utils/miscHelpers"
import { BmgStatus, SlotBmgStatus, ScoresValues } from "../../../utils/constants"

import OperationGeneral from "./OperationGeneral"
import PopOver from "../../elements/PopOver"
import LabelForm from "../../label/LabelForm"
//import PdfContract from '../elements/PdfContract';

import PoppinsRegular from '../../../assets/font/Poppins-Regular.ttf';
import PoppinsBold from '../../../assets/font/Poppins-Bold.ttf';
import PoppinsItalic from '../../../assets/font/Poppins-Italic.ttf';
import logoBmg from '../../../assets/images/logo_tagline_frame.png'
import firmBmg from '../../../assets/images/eva_firm.jpg'

const PdfContract = ({staff,storedOperation,version}) => {

    const mission = staff.missions.find((mission)=> mission.status === 1 && mission._operation._id === storedOperation._id);
    if (!mission) return null;
    
    const colWidth = [90,10];
    const missionDates     = getMissionsDates(mission.slots);
    const formattedMission = FormatUserMissionSlots(mission.slots,1);    
    const informations     = GetInformationBySlots(mission.slots,storedOperation);
    
    return (
        <Document>
            <Page size="A4" orientation="portrait" style={styles.page} wrap={true}>
                <View style={styles.section}>
                    <Image style={styles.logo} src={logoBmg} />
                    <View  style={[styles.rowWithColumns,{marginTop:'16px'}]}>
                        <View>
                            <Text style={[styles.body,styles.bold]}>Be My Guest Sàrl</Text>
                            <Text>Centre administratif</Text>
                            <Text>Chemin des Polonais 16A</Text>
                            <Text>2016 Cortaillod, Switzerland</Text>                           
                        </View>  
                        <View>
                            <Text style={[styles.body,styles.bold]}>{capitalize(staff.firstName)} {capitalize(staff.lastName)}</Text>
                            <Text>{staff.address}</Text>
                            <Text>{staff.zipcode} {capitalize(staff.city)}</Text>
                            <Text>{staff.country ? capitalize(getCountry(staff.country)) : ''}</Text>
                            <View style={{marginTop:'16px'}}>
                                <Text>{dayjs(new Date()).format('DD MMMM YYYY')}</Text>
                            </View>                            
                        </View>                          
                    </View>
                    <View style={styles.opTitle}>
                        <Text>{storedOperation.name}</Text>
                    </View>
                    <View>
                        <Text>Nous vous confirmons votre engagement pour l’événement ci-dessous :</Text>
                        <View style={[styles.rowWithColumnsStart,{marginTop:'6px'}]}>
                            <Text style={[{width:colWidth[0]},styles.bold]}>Date</Text>
                            <Text style={[{width:colWidth[1]},styles.bold]}>:</Text>
                            <Text style={styles.bold}>Du {dayjs(missionDates.startDate).format('dddd D MMMM YYYY')} au {dayjs(missionDates.endDate).format('dddd D MMMM YYYY')}</Text>
                        </View>
                        {formattedMission && formattedMission.data  && formattedMission.data.length &&  formattedMission.data.map((op,idx)=>{                            
                            return( 
                                <View key={'mission-'+idx.toString()}>
                                    <View style={styles.rowWithColumnsStart}>
                                        <Text style={[{width:[colWidth[0]]},styles.bold]}>Lieu</Text>
                                        <Text style={[{width:[colWidth[1]]},styles.bold]}>:</Text>
                                        <Text style={styles.bold}>{getAddress(op.location)}</Text>
                                    </View>   
                                    <View style={styles.rowWithColumnsStart}>
                                        <Text style={[{width:[colWidth[0]]},styles.bold]}>Horaires & Rôle</Text>
                                        <Text style={[{width:[colWidth[1]]},styles.bold]}>:</Text>                                        
                                        <View>
                                            {op.slots && op.slots.length && op.slots.map((slot,sidx)=>{
                                                if (!slot || slot.status !== 1) return null;                                                
                                                return(
                                                    <View key={'slot-'+sidx.toString()} style={styles.bold}>
                                                        <Text style={{textTransform:'uppercase'}}>{slot.role}</Text>
                                                        <Text>{dayjs(slot.eday).format('DD/MM/YYYY')} de {slot.stime} à {slot.etime}</Text>
                                                    </View>
                                                )                                            
                                            })}
                                            <Text style={{fontStyle:'italic'}}>(attention ces horaires sont indicatifs et peuvent être modifiés sur place.</Text>
                                            <Text style={{fontStyle:'italic'}}>Merci pour votre flexibilité le cas échéant)</Text>
                                        </View>
                                        
                                    </View>
                                </View>
                            );
                        })}   
                        <View style={styles.rowWithColumnsStart}>
                            <Text style={[{width:colWidth[0]},styles.bold]}>Classe salariale   </Text>
                            <Text style={[{width:[colWidth[1]]},styles.bold]}>:</Text>
                            <Text style={styles.bold}>Sans formation professionnelle</Text>
                        </View>            
                        <View style={{margin:'16px 0'}}>
                            <Text style={styles.bold}>Stylisme</Text>
                            {informations && informations.length > 0 && informations.map((info)=>{
                                return(
                                    <View>
                                        {informations.length > 1 && 
                                            <Text style={{marginTop:10}}>{capitalize(info.role.name)} / {capitalize(info.location.name)} : </Text>
                                        }
                                        {(info.instruction || storedOperation.instruction) && 
                                            <Text>{info.instruction || storedOperation.instruction}</Text>
                                        }
                                        {(info.dressCode || storedOperation.dressCode) && 
                                            <Text>{info.dressCode || storedOperation.dressCode}</Text>
                                        }
                                    </View>
                                )
                            })}
                            {(!informations || !informations.length) && 
                                <View>
                                    {storedOperation.instruction && 
                                        <Text >{storedOperation.instruction}</Text>
                                    }                                    
                                    {storedOperation.dressCode && 
                                        <Text >{storedOperation.dressCode}</Text>
                                    }
                                </View>
                            }
                        </View>        
                        <View style={{margin:'6px 0'}}>
                            <Text style={styles.bold}>Conditions générales</Text>
                            <Text >L’image de Be My Guest étant directement liée à la qualité de votre prestation, une attention particulière est exigée au niveau du langage, de la disponibilité, de la politesse et de l’attitude générale.</Text>
                        </View>   
                        <View style={{margin:'6px 0'}}>
                            <Text style={styles.bold}>Conditions financières</Text>
                            <Text >Dans le cadre de cette prestation, vous serez rémunéré sur une base horaire de {formattedMission.price}.00 {storedOperation.quoteCurrency?.toUpperCase()}/heure (comprenant les indemnités vacances de 8,33% et le 13ème salaire de 8,33%). Salaire majoré de 25% dès 23h00 et de 50% le dimanche. Merci de préciser si vous souhaitez cotiser pour l’AVS – Obligatoire au-dessus de 2'300.00 CHF /an.</Text>
                        </View>   
                        <View style={{margin:'6px 0'}} wrap={true}>
                            <Text style={styles.bold}>Repas</Text>
                            <Text >Pris en charge sur place ou indemnisés 20.00 CHF pour plus de 6h de travail effectué.</Text>
                        </View>   
                        <View style={{margin:'6px 0'}}>
                            <Text style={styles.bold}>Déplacement</Text>
                            <Text >A moins que cela ne soit spécifié dans votre contrat, les frais de déplacement sont à votre charge.</Text>
                        </View>                          
                        <View style={{margin:'6px 0'}}>
                            <Text style={styles.bold}>Temps d’essai</Text>
                            <Text >Le travail étant de durée déterminée, les deux premiers tiers, mais au maximum trois mois, sont réputés temps d’essai. Pendant le temps d’essai, les rapports de travail peuvent être résiliés à tout moment moyennant un préavis de deux jours ouvrables.</Text>
                        </View>   
                        <View style={{margin:'6px 0'}}>
                            <Text style={styles.bold}>Devoir de diligence</Text>
                            <Text >Le travailleur s’engage à accomplir avec diligence et conscience les tâches que lui confie l’entreprise de mission. Le travailleur observe les directives de l’employeur et de l’entreprise de mission.</Text>
                        </View>   
                        <View style={{margin:'6px 0'}}>
                            <Text style={styles.bold}>Résiliation des rapports de travail</Text>
                            <Text >Le contrat étant de durée déterminée, il prend fin dès la mission terminée. L'agence se réserve le droit de mettre un terme définitif à votre contrat (à l'oral ou par e-mail) si vous ne respectez pas les règles élémentaires de savoir-vivre pendant vos heures de travail ou sur les lieux de la mission tout en respectant le délai de préavis prévu à l’art. 11 CCT Location de services et les dispositions de l’art. 336 CO.</Text>
                        </View>   
                        <View style={{margin:'6px 0'}}>
                            <Text style={styles.bold}>Protection des données</Text>
                            <Text >L’employeur ne traite les données du travailleur qu’avec son assentiment.</Text>
                        </View>                           
                        <View style={{margin:'6px 0'}}>
                            <Text style={styles.bold}>Sécurité au travail</Text>
                            <Text >L’employeur a donné au travailleur les instructions sur la sécurité au travail.</Text>
                        </View>   
                        <View style={{margin:'6px 0'}}>
                            <Text style={styles.bold}>Paiement du salaire</Text>
                            <Text >Les salaires sont versés les 15 et 30 de chaque mois.</Text>
                        </View>   
                        <View style={{margin:'6px 0'}}>
                            <Text style={styles.bold}>En cas de litige</Text>
                            <Text >Selon l'art. 34 al. 1 CPC (code de procédure civile), "le tribunal du domicile ou du siège du défendeur ou celui du lieu où le travailleur exerce habituellement son activité professionnelle est compétent pour statuer sur les actions relevant du droit du travail".</Text>
                            <Text style={{marginTop:'10px'}}>En vertu de l'art. 34 al. 2 CPC (code de procédure civile), "le tribunal du lieu de l'établissement commercial du bailleur de services ou de l'intermédiaire avec lequel le contrat a été conclu est également compétent pour statuer sur les actions de demandeurs d'emploi ou de travailleurs relevant de la loi du 6 octobre 1989 sur le service de l'emploi et la location de services".</Text>
                        </View>   
                        <View style={{margin:'6px 0'}}>
                            <Text style={styles.bold}>Confirmation</Text>
                            <Text >Nous vous remercions de bien vouloir nous renvoyer le double de la présente daté et signé pour accord.</Text>
                        </View>  
                    </View>
                    <View  style={[styles.rowWithColumns,{marginTop:'16px'}]}>
                        <View>
                            <Text>Date : {dayjs(new Date()).format('DD/MM/YYYY')}</Text>
                            <Text>Signature :</Text>
                        </View>
                        <View>
                            <Text style={{marginBottom:'6px',textTransform:'uppercase'}}>Be My Guest Sàrl</Text>
                            <Image style={styles.firm} src={firmBmg} />
                            <Text style={{marginTop:'6px'}}>Eva Ramoscelli</Text>
                        </View>
                    </View>
                </View>    
                <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />                                 
            </Page>
        </Document>
      );
};

Font.register({
    family: 'Poppins',
    fonts: [
      { src: PoppinsRegular },
      { src: PoppinsBold, fontWeight: 'bold' },
      { src: PoppinsItalic, fontStyle: 'italic' },
    ], 
});

const styles = StyleSheet.create({
    pageNumbers: {
        position: 'absolute',
        bottom: 8,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize:9,        
      },
    opTitle : {
        textAlign:'center',
        border: '1px solid #C9B39B',
        textTransform:'uppercase',
        padding:'6px 0',
        margin:'16px 0',
        fontSize: 11,    
    },
    cover: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        backgroundColor:'#433F3A'
        
    },

    page: {
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        fontFamily: 'Poppins', 
        fontSize: 10,
        padding:20,
      },
      section: {
        margin: 16,
      //   flexGrow: 1
      },
    logoContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    svg: {
        width: '50%', // Adjust as needed
        height: '50%', // Adjust as needed
    },  
    logo: {
        width: 100,
        height: 64,
        color:'#ff0000'
    },
    firm: {
        width: 90,
        height: 46,
    },    
    staffImage : {
        width: 300,
        height: 360,
        objectFit:"cover"
    },
    sectionImage : {
        // flexGrow: 1
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',        
    },
    smallSection:{
        fontSize:10,
    },
    inline : {
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
        marginTop:8,
        marginBottom:8,
    },
    row : {
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
    },      
    rowWithColumns : {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
    },  
    rowWithColumnsStart : {
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
    },       
    semibold : {
        fontWeight:400,
    },
    left: {
        textAlign:'left',
    },
    column : {
        width:'16%',  
        textAlign:'right',      
        border:'solid 1px black'
    },
    uppercase : {
        textTransform:"uppercase",
    },
    h2: {
        fontSize: 28,        
        color:'#C9B39B',
        fontWeight:600,
    },
    h3: {
        fontSize: 18,        
    },  
    h4: {
        fontSize: 14,   
        fontWeight:600,
    },      
    accent : {
        color:'#C9B39B',
    },
    bold : {
        fontWeight:600,
    },
    cartouche : {
        border: '0.5px solid #C9B39B',
        borderRadius:6,
        padding:6,
        backgroundColor:'#C9B39B',
    },
    body: {
        fontSize: 12,    
    }, 
    bodySmall: {
        fontSize: 11,  
        // fontStyle:'italic',
    },      
    block:{
        marginTop:32,
        marginBottom:64,
    },
    blockNeutral:{
        marginTop:16,
        marginBottom:16,
    }, 
    hr : { 
        width: '100%', 
        border: '0.3px solid #C9B39B',
        marginTop:6,
        marginBottom:6
    },
    big:{
        fontSize:33,
        display:'inline',
    },
    smallFont : {
        fontSize:9,
    },
    smallerFont : {
        fontSize:7,
    },    
    footer: {
        position: 'absolute',
        bottom: '16px', // 20px from the bottom
        left: '0',
        right: '0',
        textAlign: 'center',
        fontSize:8,
        color: '#808080',
    },      
});




const ContractTab =  ({
            section, 
            bmgFilters, 
            listUsers , 
            staffList,
            loading,
            openGeneral,
            setOpenGeneral,
            currentPage,
            pageSize,
            totalPages,
            storedLabelList,    
            onPaginationChange,
            onTableChange,
            onPopChange,
            onLabelUpdated,
            isStaff,
            showModal,
            selectedUsers, 
            setSelectedUsers,
            showRecapModal,
            setSelectedRecapTypes
        }) => {

    const { openNotification } = useNotification();
    // const { t } = useTranslation();          
    
    const storedOperation = useSelector(state => state.operation.value && state.operation.value ? state.operation.value  : {});
    
    //const [selectedRows, setSelectedRows] = useState([]);
    const [bookedStaff, setBookedStaff] = useState([]);
    const [sentContracts, setSentContracts] = useState([]);
    const [version, setVersion] = useState(1);

    useEffect(() => {

        const staffWithBookedMissions = (staffs) =>{
            const listStaff = [];
            for(const staff of staffs){            
                const bookedMissions = staff.missions && staff.missions.length && staff.missions.filter((mission)=> mission.status === 1 && mission._operation._id === storedOperation._id);
                if (bookedMissions && bookedMissions.length) listStaff.push(staff); 
            }
            return listStaff;
        };

        setBookedStaff( staffWithBookedMissions(staffList) );
    }, [staffList]);

    const linkMeToUser = async (user)=>{
        return window.open(process.env.REACT_APP_PUBLIC_BO_URL+'/user/detail?staffId='+user._id);
    };

    const generateContract = async (staff,origin) =>{
        const blob = await pdf(
            <PdfContract 
                staff={staff} 
                version={version}
                storedOperation={storedOperation}
            />
        ).toBlob();
        //saveAs(blob, 'Contrat_BeMyGuest_'+storedOperation.name+'.pdf');
        if (origin === 'view') {
            saveAs(blob, 'Contrat_BeMyGuest_'+storedOperation.name+'.pdf');
        } else {

            const mission = storedOperation.missions.find((miss)=>miss._user === staff._id);            
            if (mission && mission._id) {
                const formData = new FormData();
                formData.append('section', 'contract');
                formData.append('sectionId', mission._id);
                formData.append('file', blob);        
                const response = await Upload(ApiRoutes.addFile, formData);
                if (response && response.success && response.value && response.value._id){
                    openNotification('success',"Contrat Envoyé");      
                    let newSentContracts = [...sentContracts];
                    if (!newSentContracts.includes(staff._id)) newSentContracts.push(staff._id) ;
                    setSentContracts(newSentContracts);
                } else {
                    openNotification('error',"Un problème lors de l'envoi du Contrat");     
                }
                return true;
            }
        }

        setVersion(version+1);
    };

    const viewContract = (staff) =>{
        //console.log('viewContract',staff);
        generateContract(staff,'view');
    };

    const sendContract = async (staff) =>{
        generateContract(staff,'send');
    };    
    
    const columns = [        
        { 
          key: "name", 
          title: "Staff Booké", 
          dataIndex: "name", 
            //   sorter: {},
            width: '260px',        
          fixed: 'left',      
          render: (value,record) => { 
            return ( 
            <> 
                <div className="tab-table-row-block">
                    <div className='flex' style={{flexDirection:'column', alignItems: "center"}}>
                        <img style={{marginBottom:'6px'}} alt="user" src={record.avatar ? record.avatar : '/img/placeholder-avatar.jpg'} className="avatar-medium" />
                        <PopOver 
                            content={
                                <span className="capitalize tag" style={{backgroundColor:record.bmgStatus ? BmgStatus[record.bmgStatus].color : BmgStatus[0].color}}>{record.bmgStatus ? BmgStatus[record.bmgStatus].label : BmgStatus[0].label}</span>
                            }              
                            prop="bmgStatus" 
                            handlerMethod={onPopChange}
                            items={BmgStatus}
                            user={record}
                        />   
                    </div>
                    <div style={{marginLeft:'6px'}}>
                        {(record.score !== null && record.score !== 0) && 
                            <PopOver 
                                content={
                                    <div className="score-list-user-small">{scoreContent(record.score,'score-small')}</div>
                                }
                                prop="score"
                                handlerMethod={onPopChange}
                                items={ScoresValues}
                                user={record}
                            />                          
                        }
                        <div className="link custom-name capitalize" onClick={()=>linkMeToUser(record)}>{record.firstName +' '+record.lastName}</div> 
                        <span className="tab-table-row-span capitalize">{record.nationality ? capitalize(getCountry(record.nationality ,'fr')) : ''} ({getGender(record.gender)}) {record.workPermit ? <SafetyCertificateOutlined/> : ''}</span>
                        <div className='flex' style={{flexDirection:'column'}}>
                            <div style={{minWidth:'100px'}}>
                                <span className='tab-table-row-span'>{record.birthday ? getAge(record.birthday)+' ans' : ''}</span>
                                <span className='tab-table-row-span'>{record.height ? record.height+'cm' : ''}</span>
                            </div>                            
                        </div>                       
                    </div>
                </div>
            </> 
            ); 
          },
        },        
        { 
            key: "missionSlots", 
            title: "Rôle ", 
            dataIndex: "missionSlots", 
            width:'222px',  
            render: (value,staff) => { 
                
                if (!value || !value.length) return '-';
                
                const formatStaffByRole = FormatStaffByRole(value);

                if (!formatStaffByRole.length) return '-';
                
                return ( 
                    <> 
                        {formatStaffByRole && formatStaffByRole.length>0 && formatStaffByRole.map((role,index) =>
                            <div key={'role-'+index.toString()} className='flex' style={{flexDirection: "column",fontSize:'12px',marginBottom:'6px'}}>
                                <span className="semibold uppercase">{role.name} - {role.price}/{role.cost}{storedOperation.quoteCurrency}</span>
                                {role.slots && role.slots.length && sortMissionSlotsByDays(role.slots).map((slot,idx) => {
                                    const staffSlotStatus = CheckMissionSlotStaffStatus(slot,storedOperation,staff._id);
                                    return (
                                        <div key={'slot-'+idx.toString()} className='flex' style={{ 
                                                textDecoration: staffSlotStatus === 0 ? "line-through":"none",
                                                alignItems:'center'
                                            }}>
                                            <div style={{width:'10px',height:'10px', marginRight:'6px', borderRadius:'5px',backgroundColor:staffSlotStatus && SlotBmgStatus[staffSlotStatus] ? SlotBmgStatus[staffSlotStatus].color : 'white'}} title={SlotBmgStatus[staffSlotStatus] ? SlotBmgStatus[staffSlotStatus].label : ''}/>
                                            <span style={{marginRight:'10px'}}>{dayjs(slot.eday,'YYYY-MM-DD').format('DD/MM/YY')}</span>
                                            <span>{slot.timeSlot?.stime} <span className='uncapitalize'>à</span> {slot.timeSlot?.etime}</span>                                               
                                        </div>
                                    )
                                    }
                                )}   
                            </div>
                        )}                        
                    </> 
                ); 
            },      
        },   
        { 
            key: "label", 
            title: "Labels", 
            dataIndex: "label", 
            // sorter: {},      
            // width: '33px',           
            render: (value,record) => { 
              return ( 
              <> 
                   <LabelForm 
                        section='user'
                        labels={value}
                        record={record}
                        handlerMethod={onLabelUpdated}
                        storedLabelList={storedLabelList || []}                
                    />  
              </> 
              ); 
            },      
        },
        { 
            key: "contractValidate", 
            title: "Contrat approuvé le", 
            dataIndex: "contractValidate", 
            // sorter: {},      
            // width: '33px',           
            render: (value,record) => { 
              const missionOp = record.missions && record.missions.length ? record.missions.find((ele)=>ele._operation._id === storedOperation._id) : null;
              return ( 
                    <> 
                        {(!missionOp || !missionOp.contractValidate) &&
                            <div>-</div>
                        }
                        {missionOp && missionOp.contractValidate &&
                            <div>{dayjs(missionOp.contractValidate.cdate).format('DD/MM/YYYY à HH:mm')}</div>
                        }                        
                    </> 
              ); 
            },      
        },        
        { 
            key: "action", 
            title: "Actions", 
            width: '100px',
            fixed: 'right',
            render: (record) => { 
                const missionOp = record.missions && record.missions.length ? record.missions.find((ele)=>ele._operation._id === storedOperation._id) : null;
                let cssContract = '';                
                if ((missionOp && missionOp.contract) || sentContracts.includes(record._id)) cssContract = ' has-contract';
                return ( 
                    <> 
                    <div className="flex">                     
                        <EyeOutlined 
                        title="Voir le contrat"
                        className='icon-table'
                        style={{ }} 
                        onClick={()=>viewContract(record)}
                        /> 
                        <SendOutlined 
                        title="Envoyer le contrat"
                        className={'icon-table'+cssContract}
                        onClick={()=>sendContract(record)}
                        /> 
                    </div> 
                    </> 
                ); 
            }, 
          },          
                               
    ];
    
    return(
        <div className="tab-content">
            <OperationGeneral
                section={section}
                bmgFilters={bmgFilters}
                openGeneral={openGeneral}
                setOpenGeneral={setOpenGeneral}
                staffList={staffList}
                showRecapModal={showRecapModal}
                setSelectedRecapTypes={setSelectedRecapTypes}
            />
            <div className={'tab-block '+ (openGeneral ? '' : 'folded')} style={{paddingTop:'26px',marginTop:'16px',marginRight:'6px'}}>               
                <Table 
                    dataSource={bookedStaff} 
                    loading={loading} 
                    rowKey="_id"
                    columns={columns} 
                    pagination={false} 
                    className="capitalize custom-checkbox custom-table  norowselection"
                    onChange={onTableChange} 
                    scroll={{
                        y: 450,                        
                    }}
                    // rowSelection={{
                    //     type: 'checkbox',
                    //     ...rowSelection,
                    // }}
                    locale={{
                        emptyText: 'Aucun Staff booké',
                    }}
                />
                {/* <Pagination
                    showSizeChanger={true} 
                    current={currentPage}
                    pageSize={pageSize}                
                    total={totalPages} 
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}       
                    onChange={onPaginationChange}
                    className="pagination-list"
                /> */}
            </div>
        </div>
    )
};

export default ContractTab;