import React, { useState, useEffect } from 'react';
import { Modal,Input,Space,Button } from 'antd';
import { useTranslation } from 'react-i18next';

const { TextArea } = Input;

const RoleInfoModal = ({  storedOperation, isModalOpen, closeModal, handlerMethod}) => {    
    
    const { t } = useTranslation();    
    const [info, setInfo] = useState([]);           

    useEffect(() => {           
        const prepareData = () => {
            if (!storedOperation?.missionSlots?.length) return;
    
            const infoMap = new Map();
    
            storedOperation.missionSlots.forEach(({ role, location }) => {
                const key = `${role.key}-${location.key}`;
                if (!infoMap.has(key)) {
                    const information = storedOperation.information?.find(info => info.key === key);
                    infoMap.set(key, {
                        key,
                        role : { 
                            key : role.key,
                            name : role.name,
                        },
                        location,
                        instruction: information?.instruction || null,
                        dressCode: information?.dressCode || null,
                        contact: information?.contact || null,
                    });
                }
            });
    
            setInfo(Array.from(infoMap.values()));
        };        
        prepareData();
    }, [isModalOpen,storedOperation]); 

    const onCancel = ()=>{      
        closeModal();
    };

    const onSubmit = ()=>{
        handlerMethod(info);
        closeModal();
    };    
    
    const changeEntry = (e,key,field) => {
        const newInfo = [...info];
        for(let newData of newInfo){
            if (newData.key === key) {
                newData[field] = e.target.value;
            }
        }
        setInfo(newInfo);
    };

    return (
        <>
            <Modal 
                title={'Editer les Instructions & DressCode'}
                open={isModalOpen}    
                onCancel={onCancel}
                footer={
                    <Space>
                        <div>
                            <Button className="button-small cancel spacer" type="primary" onClick={closeModal}>{t('buttons.cancel')}</Button>
                            <Button className="button-small apply"  type="primary" onClick={onSubmit}>{t('buttons.save')}</Button>
                        </div>
                    </Space>
                }
                width={600}
                styles={{                    
                    maxHeight: '80vh',
                    body: {
                        height: 'calc(80vh - 120px)',
                        overflowY: 'auto',
                    }
                }}               
                >
                {info && info.map((data,idx)=>{
                    return(
                        <div style={{marginBottom:'16px'}}>
                            {/* {idx === 0 &&                            
                                <Divider className="divider-full"/>
                            } */}
                            {/* <Divider className="divider-full"/>                                                                                     */}
                            <div className='h3b' style={{borderRadius:'6px',paddingLeft:'16px',paddingTop:'16px',paddingBottom:'16px',backgroundColor:'var(--accent-light-alt-color)'}}>
                                {data.location.name} / {data.role.name}
                            </div>
                            {/* <Divider className="divider-full"/>                                                                                     */}
                            <div className='tab-form-block'>
                                <div className="form-label-name">
                                    <span className="label">Instructions {data.location.name} / {data.role.name} (App & Contrat)</span>    
                                    <TextArea 
                                        title={'Instructions ('+data.location.name+' / '+data.role.name+')'}
                                        value={data.instruction}
                                        onChange={(e)=>changeEntry(e,data.key,"instruction")}
                                        className="bmg-textarea" 
                                        rows={6} 
                                        placeholder='Instructions'
                                    />
                                </div> 
                            </div>                  
                            <div className='tab-form-block'>
                                <div className="form-label-name">
                                    <span className="label">DressCode {data.location.name} / {data.role.name} (App & Contrat)</span>    
                                    <TextArea 
                                        title={'DressCode ('+data.location.name+' / '+data.role.name+')'}
                                        value={data.dressCode}
                                        onChange={(e)=>changeEntry(e,data.key,"dressCode")}
                                        className="bmg-textarea" 
                                        rows={2} 
                                        placeholder='Dress Code'
                                    />
                                </div> 
                            </div>
                            <div className='tab-form-block'>
                                <div className="form-label-name">
                                    <span className="label">Contact {data.location.name} / {data.role.name} (App)</span>    
                                    <TextArea 
                                        title={'Contact ('+data.location.name+' / '+data.role.name+')'}
                                        value={data.contact}
                                        onChange={(e)=>changeEntry(e,data.key,"contact")}
                                        className="bmg-textarea" 
                                        rows={2} 
                                        placeholder='Contact'
                                    />
                                </div> 
                            </div>                               
                        </div>
                    )
                })}
            </Modal>
        </>
    );
};
export default RoleInfoModal;