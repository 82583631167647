import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/fr'; // Import French locale
import { Select, Tooltip } from 'antd';

import LegendStatus from './LegendStatus'
import '../../assets/styles/Gantt.css';

const periods = {
  hours: 'hours',
  quarterDay: 'quarterDay',
  days: 'days',
  weeks: 'weeks',
  months: 'months',
};
const periodText = {
    hours: 'Heures',
    quarterDay: '1/4 Jr',
    days: 'Jour',
    weeks: 'Semaine',
    months: 'Mois',
};

const PeriodSwitcher = ({ selectType , onSelectType, periodSelected, setPeriod,displayTypeSelector }) => {
  const handleChangePeriod = (period) => {
    setPeriod(period);
  };

  return (
    <div className="-gantt-switcher-container">
        {displayTypeSelector &&
            <Select
                style={{width:'100px',marginRight:'50px'}}
                placeholder="Type de vue"
                defaultValue={'role'}
                value={selectType}
                onChange={onSelectType}
                options={[
                {
                    label:'Staff',
                    value:'staff'
                },
                {
                    label:'Rôle',
                    value:'role'
                },            
                ]}
                className="custom-select bold"
            />        
        }       
      {Object.keys(periods).map((periodKey) => (
        <button
          key={periodKey}
          className={periodKey === periodSelected ? "-gantt-switcher-button active" : "-gantt-switcher-button"}
          onClick={() => handleChangePeriod(periods[periodKey])}
        >
          {periodText[periodKey]}
        </button>
      ))}
    </div>
  );
};

const GanttBlock = ({ section,displayTypeSelector, onSelectType, selectType, events }) => {
  const [period, setPeriod] = useState('quarterDay');

  // Set locale globally for Moment
  moment.locale('fr');

  // Calculate the oldest and latest date from the events array
  const startDate = moment.min(events.map((event) => moment(event.start)));
  const endDate = moment.max(events.map((event) => moment(event.end)));

  const generateDateRange = (start, end, period) => {
    let range = [];
    let current = moment(start);
  
    switch (period) {
      case 'hours':
        // Add 2 hours before and after
        current.subtract(2, 'hours');
        while (current.isSameOrBefore(end)) {
          range.push(moment(current));
          current.add(1, 'hours');
        }
        current.add(1, 'hours'); // Add one more hour after
        break;
      case 'days':
        // Add 2 days before and after
        current.subtract(2, 'days');
        while (current.isSameOrBefore(end)) {
          range.push(moment(current));
          current.add(1, 'days');
        }
        current.add(1, 'days'); // Add one more day after
        break;
      case 'weeks':
        // Add 1 week before and after
        current.subtract(1, 'weeks');
        while (current.isSameOrBefore(end)) {
          range.push(moment(current));
          current.add(1, 'weeks');
        }
        current.add(1, 'weeks'); // Add one more week after
        break;
      case 'months':
        // Add 1 month before and after
        current.subtract(1, 'months');
        while (current.isSameOrBefore(end)) {
          range.push(moment(current));
          current.add(1, 'months');
        }
        current.add(1, 'months'); // Add one more month after
        break;
      case 'quarterDay':
        // Add 1 day before and after
        current.subtract(1, 'days');
        while (current.isSameOrBefore(end)) {
          range.push(moment(current));
          current.add(6, 'hours');
        }
        current.add(6, 'hours'); // Add 6 more hours after to complete the quarter day
        break;
      case 'midDay':
        // No buffer needed for 'midDay'
        while (current.isSameOrBefore(end)) {
          range.push(moment(current));
          current.add(12, 'hours');
        }
        break;
      default:
        break;
    }
  
    // Add additional buffer if period is months or weeks
    if (period === 'months') {
      current.add(1, 'months');
      range.push(moment(current));
    } else if (period === 'hours') {
    //   current.add(2, 'hours');
    //   range.push(moment(current));
    } else if (period === 'quarterDay') {
        current.add(1, 'days');
        range.push(moment(current));
    } else if (period === 'weeks') {
        current.add(1, 'weeks');
        range.push(moment(current));
    }
    return range;
  };
  

  const range = generateDateRange(startDate, endDate, period);

  // Group events by their ref property
  const groupedEvents = events.reduce((acc, event) => {
    if (!acc[event.ref]) acc[event.ref] = [];
    acc[event.ref].push(event);
    return acc;
  }, {});

  // Function to check if two dates are equivalent for merging
  const datesAreEquivalent = (date1, date2) => {
    return date1.format('YYYY-MM-DD') === date2.format('YYYY-MM-DD');
  };

  // Render legend for the selected period
  const renderLegend = () => {
    return (
      <tr className="-gantt-legend">
        <td className="-gantt-fixed-cell -gant-col1 bold">Name</td>
        {range.map((date, idx) => {
          let hourRange = '';
          const hour = parseInt(date.format('HH'), 10); // Extract the hour as an integer

          // Determine the range based on the hour
          if (period === 'quarterDay') {
            if (hour >= 0 && hour < 6) {
              hourRange = '00';
            } else if (hour >= 6 && hour < 12) {
              hourRange = '06';
            } else if (hour >= 12 && hour < 18) {
              hourRange = '12';
            } else if (hour >= 18 && hour < 24) {
              hourRange = '18';
            }
          }

          return (
            <td key={idx} className="-gantt-legend-cell">
              {period === 'hours' ? date.format('HH') : ''}
              {period === 'quarterDay' ? hourRange : ''}
              {period === 'days' ? date.format('DD') : ''}
              {period === 'weeks' ? `W${date.isoWeek()}` : ''}
              {period === 'months' ? date.format('MMM YYYY') : ''}
            </td>
          );
        })}
      </tr>
    );
  };

  // Render header for the selected period
  const renderHeader = () => {
    let mergedCells = []; // Array to keep track of merged cells
    let currentColspan = 1; // Initial colspan
    let currentDate = range[0].clone(); // Clone to avoid mutating the original moment object

    // Iterate through range dates to determine colspan for each cell
    for (let i = 1; i < range.length; i++) {
      // Check if the current date needs to be merged with the previous one based on the period
      let shouldMerge = false;

      switch (period) {
        case 'hours':
        case 'quarterDay':
          shouldMerge = datesAreEquivalent(range[i], currentDate);
          break;
        case 'days':
          shouldMerge = range[i].month() === currentDate.month();
          break;
        case 'weeks':
          shouldMerge = range[i].month() === currentDate.month();
          break;
        case 'months':
            shouldMerge = range[i].year() === currentDate.year();
            break;          
        default:
          break;
      }

      if (shouldMerge) {
        currentColspan++; // Increment colspan if dates should be merged
      } else {
        // Push the current colspan value to mergedCells array
        mergedCells.push({ date: currentDate.clone(), colspan: currentColspan });
        currentColspan = 1; // Reset colspan for the new date
        currentDate = range[i].clone(); // Update currentDate to the new date
      }
    }

    // Push the last remaining colspan value
    mergedCells.push({ date: currentDate.clone(), colspan: currentColspan });

    return (
      <tr className="-gantt-header">
        <td className="-gantt-fixed-cell -gant-col1"></td>
        {mergedCells.map((cell, idx) => (
          <td key={idx} className="-gantt-header-cell" colSpan={cell.colspan}>
            {renderHeaderCellContent(cell.date)}
          </td>
        ))}
      </tr>
    );
  };

  // Helper function to render the content inside header cells based on the period
  const renderHeaderCellContent = (date) => {
    switch (period) {
      case 'hours':
        return date.format('DD MMM');
      case 'quarterDay':
        return date.format('DD MMM');
      case 'days':
        return date.format('MMM YYYY');
        //return `S${date.isoWeek()}`;
      case 'weeks':
        return date.format('MMM YYYY');
      case 'months':
        return date.format('YYYY');
      default:
        return '';
    }
  };

  // Render cells for the events
  const renderCells = (refEvents) => {
    return range.map((rangeDate, idx) => {
      // Check if any event overlaps with the current period    
      const event = refEvents.find((ev) => {
        const eventStart = moment(ev.start);
        //const eventEnd   = moment(ev.end);     
        const eventEnd = moment(ev.end).endOf('day');
        let dateChecked = eventStart.isSameOrBefore(rangeDate) && eventEnd.isSameOrAfter(rangeDate);
        if (['days','weeks','months'].includes(period)) {
            const tmpRangeDate = new Date(moment(rangeDate).format('YYYY-MM-DD'));
            const tmpEventStart = new Date(moment(eventStart).format('YYYY-MM-DD'));
            const tmpEventEnd = new Date(moment(eventEnd).format('YYYY-MM-DD'));
            dateChecked =  tmpEventStart <= tmpRangeDate && tmpEventEnd >= tmpRangeDate;
        }
        return dateChecked;
    });

      return (
        <Tooltip color={event ? event.styles.backgroundColor : 'black' } key={idx} title={event ? event.body : ''}>
          <td
            className="-gantt-cell"
            style={{ backgroundColor: event ? event.styles.backgroundColor : 'white' }}
          >
            {event && (
              <div className="-gantt-event" style={{ backgroundColor: event.styles.backgroundColor }}></div>
            )}
          </td>
        </Tooltip>
      );
    });
  };

  return (
    <div className="-gantt-container">
      <PeriodSwitcher 
        setPeriod={setPeriod}
        onSelectType={onSelectType}
        selectType={selectType}
        periodSelected={period} 
        displayTypeSelector={displayTypeSelector}
      />
      {['operation','user'].includes(section) && <LegendStatus/>}
      <div className="-gantt-table-wrapper" style={{marginTop:'16px'}}>
        <table className="-gantt-table">
          <thead>
            {renderHeader()}
          </thead>
          <tbody>
            {renderLegend()}
            {Object.keys(groupedEvents).map(ref => (
              <tr key={ref} className="-gantt-row">
                <td className="-gantt-fixed-cell -gant-col1 ">{groupedEvents[ref][0].name}</td>
                {renderCells(groupedEvents[ref])}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GanttBlock;
