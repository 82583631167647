import React,{useEffect, useState, useRef} from "react";
import { useNavigate,useSearchParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Badge, Card, Col, Row, Pagination,Divider } from 'antd';
import moment from 'moment';
import { useNotification } from '../../../hooks/NotificationProvider';

import { saveLists } from '../../../features/lists/listsSlice';
import { saveUser } from '../../../features/user/userSlice';

import { InstagramFilled } from '@ant-design/icons';
import { BmgStatus, ScoresValues } from "../../../utils/constants"
import { capitalize, getAge, getCountry, getLanguages, scoreContent,updateList, getNotificationCount,findObjDifferences } from "../../../utils/miscHelpers"
import { ListUsers,EditUserProps } from "../../../services/User"
import HeaderBar from "../../../components/layout/HeaderBar";
import PopOver from "../../../components/elements/PopOver"
import LabelForm from "../../../components/label/LabelForm"
import PopOverForm from "../../../components/elements/PopOverForm"
import ToDoIcon from "../../../components/todo/ToDoIcon";

const section = 'user';

const UserList = () => {
  
  const defaultPageSize = 20;
  const cardWidth = 300;
 
  const storedFilters = useSelector(state => state.filters.value && state.filters.value[section] ? state.filters.value[section] : {});
  const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : null);
  const storedMessageNotification = useSelector(state => state.notification.value && state.notification.value.message ? state.notification.value.message : null);
  const storedLabelList = useSelector(state => state.lists.value && state.lists.value['label'] ? state.lists.value['label'] : []);  

  const [searchParams, setSearchParams] = useSearchParams();
  let actualPage = searchParams.get('page') || 1;

  const { openNotification } = useNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { t } = useTranslation();
  const [listItems, setListItems] = useState(storedList || []);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [totalPages, setTotalPages] = useState(100);

  const { Meta } = Card;  

  const prevDependencies = useRef({ actualPage, pageSize, storedFilters });
  
  const containerRef = useRef(null);

  useEffect(() => {
    
    const fetchData = async (hasFilterDiff) => {
      try {
        
        setLoading(true);      

        const goPage = hasFilterDiff ? 1 : actualPage;

        // Get list
        const {resultList, total} = await ListUsers({page:goPage,pageSize:pageSize,filters:storedFilters});
        dispatch(saveLists({section:section,data:resultList}));  
        setListItems(resultList);        
        setTotalPages(total);
        if (containerRef.current) {
          containerRef.current.scrollTo({
            top: 0,
            behavior: "smooth", // Smooth scrolling
          });
        }
        setCurrentPage(goPage);    
        setSearchParams({ page: Number(goPage) });    
        setLoading(false);
        return;
      } catch (error) {
        console.error('Error fetching user list:', error);
      }
    };
    setCurrentPage(actualPage);

    const hasFilterDiff = findObjDifferences(prevDependencies.current.storedFilters,storedFilters);  
    
    if (
    (
      Number(prevDependencies.current.actualPage) !== Number(actualPage) ||
      Number(prevDependencies.current.pageSize) !== Number(pageSize) ||
      Object.keys(hasFilterDiff).length) &&
      Object.keys(storedFilters).length
    ) { 
      fetchData(Object.keys(hasFilterDiff).length);
      prevDependencies.current = { actualPage:actualPage, pageSize, storedFilters };
    }
  }, [actualPage, pageSize, storedFilters]);

  const linkMe = async (user)=>{
    dispatch(saveUser(user));
    navigate("/user/detail?staffId="+user._id);
  };

  const doUserMediaWidth = (nbItems)=>{    
    let width = cardWidth / nbItems;
    return (width-1) + 'px';
  }

  const onPaginationChange = (page,pageSize) => {  
    setSearchParams({ page: Number(page) });
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onPopChange = async (user,props)=>{
    try {
      const userUpdated = await EditUserProps(user,props);
      if (userUpdated && userUpdated._id) {
        setListItems( updateList(listItems,userUpdated) );
        openNotification('success','Modification Enregistrée');
      }  else {
        openNotification('error','Probléme lors de la sauvegarde');
      } 
    } catch (error) {
      console.error('Error onPopChange EditUserProps:', error);
      openNotification('error','Catch / Probléme lors de la sauvegarde');
    }     
  };
  
  const onLabelUpdated = (record) => {
    const userRecord = listItems.find((ele)=>ele._id === record._id);
    if (userRecord) {

      const updatedList = updateList(listItems,{...userRecord,...{label:record.label}}) ;
      setListItems( updatedList );
      dispatch(saveLists({section:section,data:updatedList}));
    }
    return true;
  };

  const onEditTodo = (todoUpdated) =>{    
    if (todoUpdated && todoUpdated._id) {
      let eleToModified = storedList.find((ele)=>  ele._id === todoUpdated.targetId )
      if (eleToModified && eleToModified._id) {
        const eleModified = {...eleToModified, todo : todoUpdated};
        const updatedList = updateList(storedList,eleModified); 
        setListItems(updatedList);                
        dispatch(saveLists({ section : section, data : updatedList}));
      }
    }    
  };

  const cardMetaDescription = (user) =>{

    return (
      <div className="card-content">      
        <div style={{ display: "flex",flexDirection: "row",width: '100%'}}>
          <div onClick={()=>linkMe(user)} className="link" style={{ width:"40%"}}>
            <div className="flex">
              <div  style={{width:'50px'}}><span className="h2">{t('user.age')} : </span></div>
              <span>{getAge(user.birthday)}</span>
            </div>
            <div className="flex">
              <div  style={{width:'50px'}}><span className="h2">{t('user.height')} : </span></div>
              <span>{user.height ? (user.height/100).toFixed(2) : '-'}</span>
            </div>  
            <div className="flex">
              <div  style={{width:'50px'}}><span className="h2">{t('user.body')} : </span></div>
              <span className="uppercase">{user.bodyUp} / {user.bodyDown}</span>
            </div>  
            <div className="flex">
              <div  style={{width:'50px'}}><span className="h2">{t('user.profileCompleted')} : </span></div>
              <span className="uppercase">{user.profileCompleted}%</span>
            </div>
          </div>
          <div style={{width:'100%',maxWidth:'60%',margin:0}}>
            <div onClick={()=>linkMe(user)} className="link">
              <span className="h2">{t('user.nationality')} : </span>
              <span className="capitalize">{user.nationality ? getCountry(user.nationality ,'fr') : ''}</span>
            </div>
            <div onClick={()=>linkMe(user)}  className="link">
              <span className="h2">{t('user.languages')} : </span>
              <span className="uppercase">{user.languages ? getLanguages(user.languages,'code') : '-'}</span>
            </div>            
            <div onClick={()=>linkMe(user)} className="link">
              <span className="h2">{t('user.city')} : </span>
              <span className="capitalize">{user.city ? user.city : '-'}</span>
            </div>  
            <div>
              <span className="h2" >{t('user.bmgStatus')} : </span>
              <PopOver 
                content={
                  <span className="capitalize tag" style={{backgroundColor:user.bmgStatus ? BmgStatus[user.bmgStatus].color : BmgStatus[0].color}}>{user.bmgStatus ? BmgStatus[user.bmgStatus].label : BmgStatus[0].label}</span>
                }              
                prop="bmgStatus" 
                handlerMethod={onPopChange}
                items={BmgStatus}
                user={user}/>
            </div>
          </div>
        </div>
        {(user.phone || user.email) &&
          <div style={{marginBottom:'6px'}}>
            {user.email &&
              <div className="flex">
                <div  style={{width:'50px'}}><span className="h2">Email : </span></div>
                <span >{user.email}</span>
              </div>
            }               
            {user.phone &&
              <div className="flex">
                <div  style={{width:'50px'}}><span className="h2">Tél. : </span></div>
                <span >{user.phone}</span>
              </div>
            }         
          </div>
        }
        {user.instagram &&
          (
            <div>
              <a className="icon-link" title="Ouvrir sur Instagram" href={'https://instagram.com/' + user.instagram } target="new"><InstagramFilled/></a>            
            </div>            
          )
        }          
        <div className='flex flex-column-end' style={{ paddingBottom:"16px",textAlign: "right"}}>
          <LabelForm 
                section={section}
                labels={user.label}
                record={user}
                handlerMethod={onLabelUpdated}
                storedLabelList={storedLabelList || []}                
            />    
            <PopOverForm
              content={
                <ToDoIcon section={section} record={user}/>
              }
              form="todo"
              handlerMethod={onEditTodo}
              data={user.todo}
              target='user'
              targetData={user}
            />     
        </div>
      </div>
  
    );
  }
  
  return (
    <div className="container" ref={containerRef} style={{height: "90vh", overflowY: "auto"}}>
      <HeaderBar loading={loading} hasFilters={true} hasSearch={true} hasSort={true} section={section} storedFilters={storedFilters} setCurrentPage={setCurrentPage}/>
      <Pagination
            showSizeChanger={true} 
            current={currentPage}
            pageSize={pageSize}                
            total={totalPages} 
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}       
            onChange={onPaginationChange}
            className="pagination-list"            
          />      
      <div className="content"  style={{top:0}}  >     
        <div>
          <Row gutter={[16,16]}>
            {listItems && listItems.map((user) => {

              const notificationCount = getNotificationCount(
                'user',
                storedMessageNotification && storedMessageNotification.list && storedMessageNotification.list.length ? storedMessageNotification.list : [], 
                {_user:user}
              );

              return (
                <Col span={6} key={user._id}>                  
                  <Card
                      hoverable
                      // onClick={()=>{
                      //   linkMe(user)}
                      // }
                      className="bmg-card"
                      style={{ width: cardWidth+'px',marginTop:'50px',padding:0}}
                      cover={<img onClick={()=>linkMe(user)} alt="user" src={user.avatar ? user.avatar : '/img/placeholder-avatar.jpg'} className="avatar-list-user link" />}
                    >
                    {notificationCount !== 0 && <Badge count={notificationCount}/> }           
                    <PopOver 
                      content={
                        <div className="score-list-user">{scoreContent(user.score,'score-small')}</div>
                      }
                      prop="score"
                      handlerMethod={onPopChange}
                      items={ScoresValues}
                      user={user}
                    />              
                    <div onClick={()=>linkMe(user)} className="link" style={{width: cardWidth+'px',margin:0,padding:0}}>
                      {/* {user && user.files && user.files.map((file)=>{
                        return(
                          <img alt="media" src={file}  style={{width:doUserMediaWidth(user.files.length > 3 ? 3 : user.files.length)}} className="media-list-user img-bw"/>
                        )
                      })}
                      {user && user.files ? user.files.length : '-'} */}
                      { user && user.files && user.files.length > 0 ?
                          <>
                            { user && user.files && user.files[0] ? <img alt="media" src={user.files[0]}  style={{width:doUserMediaWidth(user.files.length > 3 ? 3 : user.files.length)}} className="media-list-user img-bw"/> : '' }
                            { user && user.files && user.files[1] ? <img alt="media" src={user.files[1]}  style={{width:doUserMediaWidth(user.files.length > 3 ? 3 : user.files.length)}} className="media-list-user img-bw"/> : '' }
                            { user && user.files && user.files[2] ? <img alt="media" src={user.files[2]}  style={{width:doUserMediaWidth(user.files.length > 3 ? 3 : user.files.length)}} className="media-list-user img-bw"/> : '' }
                          </>
                        : 
                          <>
                            <img alt="media" src={'/img/placeholder-media.jpg'}  style={{width:doUserMediaWidth(1)}} className="media-list-user"/>
                          </>
                      }
                    </div>
                    <div onClick={()=>linkMe(user)} className="h2 link">{capitalize(user.firstName) + ' '+ capitalize(user.lastName)}</div>
                    <Divider className="divider-list-user"/>
                    <Meta description={cardMetaDescription(user)} style={{width:'100%'}}/>                    
                    <div style={{marginBottom:'6px'}}>
                        {user.ldate && 
                          <span className="h5bl">Connexion le {moment(user.ldate).format('DD/MM/YYYY à HH[h]mm')}</span>
                        }
                        {!user.ldate && 
                          <div style={{height:'21px'}}/>
                        }
                    </div> 
                  </Card>
                </Col>
              )
            })}
          </Row>
         
        </div> 
      </div>
      <Pagination
            showSizeChanger={true} 
            current={currentPage}
            pageSize={pageSize}                
            total={totalPages} 
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}       
            onChange={onPaginationChange}
            className="pagination-list"            
          />
    </div>
  );
};

export default UserList ;
