import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { GetAvatar } from "../../services/User"
import MessageForm from './MessageForm';
import MessageThread from './MessageThread';
import { DefaultAvatar } from "../../utils/constants"

const MessageDrawer = ({drawerLoading, openDrawer,handlerMethod}) => {

    const storedMessage = useSelector(state => state.message.value && state.message.value.user ? state.message.value : null); // {}

    const { t } = useTranslation();
    
    //const [isLoading, setIsLoading] = useState(drawerLoading);
    const [drawerTitle, setDrawerTitle] = useState(t('message.title'));
    const [avatarFile, setAvatarFile] = useState(null);

    useEffect(() => {

        const getAvatar = async ()=>{
            if (storedMessage && storedMessage.user && storedMessage.user.medias && storedMessage.user.medias.length) {
                const avatarImg = await GetAvatar(storedMessage.user);
                return setAvatarFile( avatarImg );
            }
            setAvatarFile(null);
        };

        getAvatar();
        
         if (storedMessage && storedMessage.user && storedMessage.user._id) {
            setDrawerTitle(storedMessage.user.firstName +' '+storedMessage.user.lastName);
         } else {
            setDrawerTitle(t('message.title'));
         }
      }, [openDrawer]);
      
    return (
        <>
            <Drawer
                title={
                    <div className="h3b grey-dark message-drawer-title ">
                        {avatarFile && <img alt="user" src={avatarFile} className="avatar-small accent-color-radius" />}
                        {!avatarFile && <UserOutlined/>}                                                
                        <div style={{marginLeft:'16px'}}>{drawerTitle}</div>
                    </div>
                }
                placement="right"
                width={500}
                onClose={()=>handlerMethod(false)}
                open={openDrawer}
                className='message-drawer'                
                //destroyOnClose={true}
                //extra={}
            >  
               {storedMessage && <MessageThread avatarFile={avatarFile ? avatarFile : DefaultAvatar} storedUser={storedMessage.user}/>} 
                <MessageForm handlerMethod={handlerMethod}/>
            </Drawer>
         </>
    );
};
export default MessageDrawer;