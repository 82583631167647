import React, { useEffect, useRef} from 'react';
//import {  } from 'antd';
import moment from 'moment';
import { useSelector,useDispatch } from 'react-redux';
//import { MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { saveMessage } from '../../features/message/messageSlice';
import { ListMessages } from "../../services/Message"

import { saveNotification } from '../../features/notification/notificationSlice';
import { viewNotifications } from "../../services/Notification"

const bmgAvatar = "/img/logo_no_tagline.svg";

const MessageThread = ({ avatarFile , storedUser}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const threadRef = useRef(null);

    const storedMessageNotification = useSelector(state => state.notification.value && state.notification.value.message ? state.notification.value.message : []);

    const storedMessage = useSelector(state => state.message.value && state.message.value.user ? state.message.value : []);    
    let messageList = storedMessage && storedMessage.list && storedMessage.list.length ? storedMessage.list : [];    
    //let storedUser = storedMessage && storedMessage.user ? storedMessage.user : null;    // This create an infinite loop 

    useEffect(() => {

        const getMessageList = async ()=>{
            if (storedUser !== null && storedUser && storedUser._id) {            
                const messageList = await ListMessages({filters:{user:storedUser._id}});
                dispatch(saveMessage({user:storedUser,list:messageList.resultList && messageList.resultList.length ? messageList.resultList : []}));    
                
                // Check Notifications
                const listNotif = storedMessageNotification && storedMessageNotification.list && storedMessageNotification.list.length ? storedMessageNotification.list.filter((notif)=>{return notif._user === storedUser._id}) : [];
                if (listNotif && listNotif.length) {
                  const viewNotification = await viewNotifications({message:listNotif});
                  dispatch(saveNotification(viewNotification));
                }
                return true;
            }
        };

        if (storedUser !== null && storedUser && storedUser._id) {
            getMessageList();
            // if (threadRef.current) {
            //     //threadRef.current.scrollTop = threadRef.current.scrollHeight;
            // }
        }
    }, [storedUser]); 

    const openExternalLink = (link) =>{
        return window.open(link);
    };      

    return (
        <div className='message-thread' ref={threadRef}>            
            { storedMessage.user && messageList.length !== 0 && messageList.slice().map((message,index)=>{    
                if (message) {            
                    if (message.sender === '_user') {
                        return (
                            <div key={index.toString()} className="message-row message-left">
                                <img alt="user" src={avatarFile} className="avatar-small" />
                                <div style={{marginLeft:'16px'}}>
                                    <div style={{fontSize:"0.6rem"}}>{moment(message.cdate).format("HH:mm - DD/MM/YYYY") || ''}</div>
                                    <div >{message.body || ''}</div>
                                </div>
                            </div>
                        )                     
                    } else {
                        return (
                            <div key={index.toString()} className="message-row message-right">
                                <div style={{marginRight:'16px'}}>
                                    <div style={{fontSize:"0.6rem"}}>{moment(message.cdate).format("HH:mm - DD/MM/YYYY") || ''}</div>
                                    <div 
                                        className={message.output && message.output === 'link' ? 'link' : ''}
                                        onClick={()=>message.output && message.output === 'link' ? openExternalLink(message.body) : null} 
                                    >
                                        {message.body || ''}
                                    </div>
                                </div>
                                <img style={{backgroundColor:'var(--secondary-color)',borderRadius:'3px',padding:'6px',marginRight:'2px'}} alt="user" src={bmgAvatar} className="avatar-bmg-small " />
                            </div>
                        )     
                    }
                } else {
                    return null;
                }
              })
            }
            { !messageList.length && storedMessage.user &&
                <div className="no-data">{t('message.nodata')}</div>
            }            
        </div>
    );
};
export default MessageThread;