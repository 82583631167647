import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Modal,Input,Tag } from 'antd';
//import { IssuesCloseOutlined,RedoOutlined,UndoOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { BmgStatus,DemandTypes,MissionBmgStatus, SlotBmgStatus,  DemandTypesDefaultMessages } from "../../../utils/constants"
import {capitalize,sortMissionSlotsByDays, getAge, doS, scoreContent, FormatStaffByRole, CheckMissionSlotStaffStatus } from "../../../utils/miscHelpers"
import LegendStatus from '../../../components/elements/LegendStatus';

const StaffRecapMissions = ({staffList,  storedOperation, selectedRecapTypes, isModalRecapOpen, closeRecapModal}) => {    
    
    const { t } = useTranslation();    

    const statusAll = [-1,0,1,2,3,4];

    const [statusFilter, setStatusFilter] = useState([]);        

    const linkMeToUser = async (user)=>{
        return window.open(process.env.REACT_APP_PUBLIC_BO_URL+'/user/detail?staffId='+user._id);
    };

    const sortUserList = () =>{
        if (storedOperation && storedOperation.users) {
            return [...storedOperation.users]
            .reverse() // Reverse the order of `storedOperation.users`
            .map(userId => staffList.find(ele => ele._id === userId)) // Map to matching staff objects
            .filter(staff => staff && staff._id); // Filter out invalid matches
        } else {
            return [];
        }

    };    

    const prepareUserList = () => {       
        const sortedStaffList = sortUserList();
        setUserList(sortedStaffList); // Set the processed list                
    };

    const checkDisplayStaffByRole = (staff,staffByRole) =>{
        let displayStaff = true;
        if (staffByRole && staffByRole.length) {
            let nbMatchRole = 0;
            let nbMatch = 0;
            //console.log('staffByRole :>> ', staffByRole);
            for(const role of staffByRole){
                //console.log('role :>> ', role);
                if (role && role.slots && role.slots.length){
                    nbMatch = 0;
                    for(const slot of role.slots){
                        const staffSlotStatus = CheckMissionSlotStaffStatus(slot, storedOperation,staff._id);                        
                        if (statusFilter.includes(staffSlotStatus)) nbMatch++;
                    }
                    if (nbMatch && nbMatch === role.slots.length) nbMatchRole++;
                }
            }
            if (nbMatchRole && nbMatchRole === staffByRole.length ) displayStaff = false;
        }
        return displayStaff;
    };

    useEffect(() => {
        // setSelectedUsers([]);        
        // setSelectedSlots([]);
        if (!statusFilter.length) {
            prepareUserList();
        } else {
            const newUserList = [];
            const sortedStaffList = sortUserList();
            for(const staff of sortedStaffList){
                if (staff) {
                    const staffByRole = FormatStaffByRole(staff.missionSlots);
                    const displayStaff = checkDisplayStaffByRole(staff,staffByRole);
                    if (displayStaff) newUserList.push(staff);
                }                
            }
            setUserList(newUserList); 
        }        
    }, [statusFilter]); 

    useEffect(() => {
        setStatusFilter(
            statusAll.filter(item => !selectedRecapTypes.includes(item))
        );      
    }, [selectedRecapTypes]); 

    //const roles = FormatMissionSlotsByRoles(storedOperation && storedOperation.missionSlots ? storedOperation.missionSlots : [])    
    
    const [userList, setUserList] = useState(staffList);
    const [searchTerm, setSearchTerm] = useState('');
    
    const onCancel = ()=>{      
        closeRecapModal();
    };

    const onChangeTerm = (e) =>{        
        setSearchTerm(e.target.value);    
    };

    // console.log('StaffRecapMissions staffList ', staffList);
    // console.log('StaffRecapMissions selectedRecapTypes', selectedRecapTypes);

    const filteredUsers = userList.filter(user => 
        user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );   
    
    return (
        <>
            <Modal 
                title={'Récap. du Staff & Missions - '+ (filteredUsers && filteredUsers.length ? filteredUsers.length : 0) + ' / '+staffList.length+' Staff'+doS(filteredUsers)}
                open={isModalRecapOpen}    
                onCancel={onCancel}            
                //width={'90%'}
                footer={null}
                width={600}
                styles={{                    
                    maxHeight: '80vh',                     
                    body: {
                        height: 'calc(80vh - 120px)',
                        overflowY: 'auto',
                    }
                }}               
                >
                <LegendStatus
                    clickable={true}
                    type={'affect'}
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                />
                <div style={{marginTop:'16px',marginBottom:'6px'}} className='flex'>                                         
                    <Input placeholder="Rechercher" allowClear onChange={onChangeTerm} />
                </div>                     
                {(filteredUsers && filteredUsers.length !== 0) &&    
                    <>   
                        <div  style={{width:'100%',justifyContent: "flex-start"}}>
                            {filteredUsers.map((staff,index)=>{                                   
                                const staffByRole = FormatStaffByRole(staff.missionSlots);
                                return(
                                    <div key={index.toString()} className="tab-table-row-block row-ele" style={{ alignItems: "center",justifyContent: "space-between",padding:'10px 0',width:'100%',borderBottom:'solid 1px var(--accent-thin-color)',margin:'0 0'}}>
                                        <div className="tab-table-row-block">                                        
                                            <div onClick={()=>linkMeToUser(staff)} className='link'> 
                                                <img alt="user" src={staff.avatar ? staff.avatar : '/img/placeholder-avatar.jpg'} className="avatar-small" />                                                    
                                            </div>
                                            <div style={{marginLeft:'6px'}}>
                                                {(staff.score !== null && staff.score !== 0) && 
                                                    <div className="score-list-user-small">{scoreContent(staff.score,'score-small')}</div>
                                                }
                                                <div onClick={()=>linkMeToUser(staff)} className="link custom-name capitalize bold">{staff.firstName +' '+staff.lastName}</div> 
                                                <div>
                                                    <span className='tab-table-row-span'>({staff.gender === 1 ? 'H' : 'F'})</span>
                                                    <span className='tab-table-row-span'>{staff.birthday ? getAge(staff.birthday)+' ans' : ''}</span>
                                                    <span className='tab-table-row-span'>{staff.height ? (staff.height/100).toFixed(2)+'m' : ''}</span>
                                                    <span className='tab-table-row-span'>{capitalize(staff.city)}</span>
                                                </div>
                                                <div style={{marginTop:'16px'}}>
                                                    <span className="capitalize tag" style={{backgroundColor:staff.bmgStatus ? BmgStatus[staff.bmgStatus].color : BmgStatus[0].color}}>{staff.bmgStatus ? BmgStatus[staff.bmgStatus].label : BmgStatus[0].label}</span>
                                                    {staff.label && staff.label.map((label,lidx)=>
                                                        <Tag
                                                            key={lidx.toString()}
                                                            color={"white"}
                                                            closable={false}
                                                            className="tag" 
                                                            style={{
                                                                margin:"4px",
                                                                width:'80px',
                                                                display:'inline',
                                                                backgroundColor:label.color,
                                                                color:'white',
                                                                fontSize:'10px',
                                                            }}
                                                        >
                                                            {label.name}
                                                        </Tag>
                                                    )}
                                                </div>                                                                                                
                                            </div>                                            
                                        </div>
                                        <div>
                                            {MissionBmgStatus[staff.operationStatus] && 
                                                <span className="capitalize tag" style={{backgroundColor:MissionBmgStatus[staff.operationStatus].color}}>{MissionBmgStatus[staff.operationStatus].label}</span>
                                            }                                                
                                        </div>
                                        <div style={{marginRight:'6px'}}>
                                            {staffByRole  && staffByRole.map((role,idx)=>{
                                                let cntOkStatus = 0;
                                                if (role.slots && role.slots.length) {
                                                    for(const sslot of role.slots){
                                                        const staffSlotStatus = CheckMissionSlotStaffStatus(sslot, storedOperation,staff._id);
                                                        if (!statusFilter.includes(staffSlotStatus)) cntOkStatus++;
                                                    }
                                                }
                                                if (!cntOkStatus) return null;
                                                return(
                                                    <div key={'role-'+idx.toString()} className='flex' style={{flexDirection: "column",fontSize:'12px',marginBottom:'6px'}}>
                                                        <span className="semibold uppercase">{role.name} - {role.price}/{role.cost}{storedOperation.quoteCurrency}</span>
                                                        {role.slots && role.slots.length && sortMissionSlotsByDays(role.slots).map((slot,idx) => {
                                                            const staffSlotStatus = CheckMissionSlotStaffStatus(slot, storedOperation,staff._id);
                                                            //if (staff._id === '6753419c06ce6b2ef116ac68') console.log(role.name,staff._id,staff.firstName,staffSlotStatus,statusFilter);
                                                            if (!statusFilter.includes(staffSlotStatus)) {
                                                                return (
                                                                    <div key={'slot-'+idx.toString()} className='flex' style={{ 
                                                                            textDecoration: staffSlotStatus === 0 ? "line-through":"none",
                                                                            alignItems:'center'
                                                                        }}>
                                                                        <div style={{width:'10px',height:'10px', marginRight:'6px', borderRadius:'5px',backgroundColor: [-1,4].includes(staffSlotStatus)  ? '#c9b39b' : SlotBmgStatus[staffSlotStatus] ? SlotBmgStatus[staffSlotStatus].color : 'white'}} title={SlotBmgStatus[staffSlotStatus] ? SlotBmgStatus[staffSlotStatus].label : ''}/>                                                                
                                                                        <span style={{marginRight:'10px'}}>{dayjs(slot.eday,'YYYY-MM-DD').format('DD/MM/YY')}</span>
                                                                        <span>{slot.timeSlot?.stime} <span className='uncapitalize'>à</span> {slot.timeSlot?.etime}</span>                                                                       
                                                                    </div>
                                                                )
                                                            } else {
                                                                return null;
                                                            }                                                      
                                                            }
                                                        )}  
                                                    </div>
                                                )
                                               }
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>                    
                    </>
                }  
                {(!filteredUsers || filteredUsers.length === 0) &&    
                    <div style={{marginTop:'32px'}}>Aucun Staff</div>
                }
            </Modal>
        </>
    );
};
export default StaffRecapMissions;