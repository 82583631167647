import React,{useEffect, useState} from "react";
import dayjs from 'dayjs';
//import { useTranslation } from 'react-i18next';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Steps } from 'antd';
import { useNotification } from '../../../hooks/NotificationProvider';
import { ListClients } from "../../../services/Client";
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import StepOne from "../../../components/operation/steps/StepOne"
import StepTwo from "../../../components/operation/steps/StepTwo"
import StepThree from "../../../components/operation/steps/StepThree"
import StepFour from "../../../components/operation/steps/StepFour"
import HeaderBar from "../../../components/layout/HeaderBar";

import WorkForceDrawer from "../../../components/operation/elements/WorkForceDrawer"

import { saveLists } from '../../../features/lists/listsSlice';
import { saveOperation } from '../../../features/operation/operationSlice';
import { SaveOp, EditOpProps, CountOps } from '../../../services/Operation';
import { updateList } from "../../../utils/miscHelpers";

import {operationBmgStatus} from '../../../utils/constants'

import { getMissionSlotsDateRange, importBmgFilters,changeListToLabelValue } from "../../../utils/miscHelpers"
// import { toBeEmpty } from "@testing-library/jest-dom/dist/matchers";

const section = 'operation';

const OpForm = () => {

  const { openNotification } = useNotification();
  const dispatch = useDispatch();
  // const { t } = useTranslation();
  const navigate = useNavigate();

  const storedFilters = useSelector(state => state.filters.value && state.filters.value[section] ? state.filters.value[section] : {});
  const storedOperation = useSelector(state => state.operation.value && state.operation.value ? state.operation.value  : {});
  const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);
  
  const [clientList, setClientList] = useState([]);  

  const  onSubmitNewClient = async (clientData) =>{
    setClient(clientData._id);
    const {resultList } = await ListClients({size:'light'});        
    setClientList(changeListToLabelValue(resultList,'name','_id'));
  };

  const [openDrawer, setOpenDrawer] = useState(false);
  const [bmgFilters, setBmgFilters] = useState(null); 
  const [current, setCurrent] = useState(0);

  // STEP 1 FIELDS + LOGIC

  const [name, setName] = useState(storedOperation && storedOperation.name ? storedOperation.name : '');   
  const [client, setClient] = useState(storedOperation && storedOperation._client && storedOperation._client._id ? storedOperation._client._id : '');     
  
  let imposedDate = true;
  if (storedOperation && storedOperation._id && storedOperation.dateImposed !== undefined) imposedDate = storedOperation.dateImposed;
  if (storedOperation && storedOperation._id && storedOperation.dateImposed === undefined) imposedDate = true;  
  const [dateImposed, setDateImposed] = useState(imposedDate);
  //const [dateImposed, setDateImposed] = useState(storedOperation && storedOperation.dateImposed ? storedOperation.dateImposed : (storedOperation && storedOperation._id && storedOperation.dateImposed === undefined ? false :true));
  
  const [body, setBody] = useState(storedOperation && storedOperation.body ? storedOperation.body : '');
  const [bodyClient, setBodyClient] = useState(storedOperation && storedOperation.bodyClient ? storedOperation.bodyClient : '');
  const [instruction, setInstruction] = useState(storedOperation && storedOperation.instruction ? storedOperation.instruction : '');
  const [dressCode, setDressCode] = useState(storedOperation && storedOperation.dressCode ? storedOperation.dressCode : '');
  const [contact, setContact] = useState(storedOperation && storedOperation.contact ? storedOperation.contact : '');
  const [bookedTotal, setBookedTotal] = useState(storedOperation && storedOperation.bookedTotal ? storedOperation.bookedTotal : '');

  const [latitude, setLatitude] = useState(storedOperation && storedOperation.latitude ? storedOperation.latitude : '');
  const [longitude, setLongitude] = useState(storedOperation && storedOperation.longitude ? storedOperation.longitude : '');
  
  const [languages, setLanguages] = useState(storedOperation && storedOperation.languages ? storedOperation.languages :  ['fr']);
  const [type, setType] = useState(storedOperation && storedOperation.type ? storedOperation.type : '');
  const [newType, setNewType] = useState('');
  const [bmgStatus, setBmgStatus] = useState(storedOperation && storedOperation.bmgStatus ? storedOperation.bmgStatus : operationBmgStatus[0].value);
  const [missionSlots, setMissionSlots] = useState(storedOperation && storedOperation.missionSlots ? storedOperation.missionSlots :  []);

  const [quote, setQuote] = useState(storedOperation && storedOperation.quote ? storedOperation.quote : '');
  const [quoteCurrency, setQuoteCurrency] = useState(storedOperation && storedOperation.quoteCurrency ? storedOperation.quoteCurrency : 'chf');
  const [quoteExpire, setQuoteExpire] = useState(storedOperation && storedOperation.quoteExpire &&  dayjs(storedOperation.quoteExpire,'YYYY-MM-DD').isValid() ? dayjs(storedOperation.quoteExpire,'YYYY-MM-DD') : dayjs(new Date()).add(15, 'day'));
  const [quoteVat, setQuoteVat] = useState(storedOperation && storedOperation.quoteVat ? storedOperation.quoteVat : 8.1);
  const [quoteHeader, setQuoteHeader] = useState(storedOperation && storedOperation.quoteHeader ? storedOperation.quoteHeader : '');
  const [quoteFooter, setQuoteFooter] = useState(storedOperation && storedOperation.quoteFooter ? storedOperation.quoteFooter : '');
  const [quoteFees, setQuoteFees] = useState(storedOperation && storedOperation.quoteFees ? storedOperation.quoteFees : []);

  useEffect(() => {
    const fetchClients = async () => {
      try {     
        const {resultList } = await ListClients({size:'light'});        
        setClientList(changeListToLabelValue(resultList,'name','_id'));        
        return;
      } catch (error) {
        console.error('Error fetching clients data:', error);
      }
    };
    fetchClients();    

    const fetchOpTotal = async () => {      
      try {     
        const totalOp = await CountOps({});
        if (quote === '') {
          setQuote('D-1'+(totalOp+1));
        }
        return;
      } catch (error) {
        console.error('Error fetching clients data:', error);
      }
    }
    fetchOpTotal();

    // if (!storedOperation || !storedOperation._id) {
    //   navigate('/operation');
    // } 

  }, []);

  const closeDrawer = async () =>{
    setOpenDrawer(false);
  };

  const onChangeData = (e,setField)=>{    
      const value = e?.target?.value;      
      switch(setField){
          case 'setName' :
              return  setName(value); 
          case 'setQuote' :
              return  setQuote(value); 
          case 'setQuoteVat' :
              return  setQuoteVat(value); 
          case 'setNewType' :
              return  setNewType(value);
          case 'setQuoteCurrency' :
              return  setQuoteCurrency(e);                             
          case 'setQuoteHeader' :
              return  setQuoteHeader(value);               
          case 'setQuoteFooter' :
              return  setQuoteFooter(value);                             
          // case 'setPrice' :
          //     return  setPrice(value); 
          // case 'setAdress' :
          //     return  setAdress(value); 
          // case 'setZipcode' :
          //     return  setZipcode(value); 
          case 'setLatitude' :
              return  setLatitude(value); 
          case 'setLongitude' :
              return  setLongitude(value); 
          case 'setDressCode' :
              return  setDressCode(value);        
          case 'setBody' :
              return setBody(value); 
          case 'setBodyClient' :
            return setBodyClient(value);               
          case 'setDateImposed' :
              return setDateImposed(e.target.checked);              
          case 'setInstruction' :
              return  setInstruction(value);  
          case 'setContact' :
            return  setContact(value);              
          case 'setBookedTotal' :            
              return  setBookedTotal(e); 
          default:
      }
  };

  const onSelectData = (value,setField)=>{    
      switch(setField){
          case 'setLanguages' :
              return  setLanguages(value); 
          case 'setType' :
              return  setType(value); 
          case 'setBmgStatus' :
            return  setBmgStatus(value); 
          case 'setClient' :
            return  setClient(value);               
          default:
      }
  };

  const data = {
    locations: [],
    roles: [],
    positions: [],
    edays: [],
    timeSlots: []
  };

  const formatEdaysGrpsToDaysjs = (edaysGrps) =>{
    let formatedEdaysGrps = [];
    for(const edayGrpIdx in edaysGrps){
      for(const eday of edaysGrps[edayGrpIdx]){
        if (!formatedEdaysGrps[edayGrpIdx]) formatedEdaysGrps[edayGrpIdx] = [];
        formatedEdaysGrps[edayGrpIdx].push(dayjs(eday));
      }
    }
    return formatedEdaysGrps;
  }

  // STEP 2 FIELDS + LOGIC

  const [roles, setRoles] = useState(storedOperation && storedOperation.roles && storedOperation.roles.length ? storedOperation.roles : []);    
  const [positions, setPositions] = useState(storedOperation && storedOperation.positions && storedOperation.positions.length ? storedOperation.positions : []);    
  const [locations, setLocations] = useState(storedOperation && storedOperation.locations && storedOperation.locations.length ? storedOperation.locations : []);      
  const [timeSlots, setTimeSlots] = useState(storedOperation && storedOperation.timeSlots && storedOperation.timeSlots.length ? storedOperation.timeSlots : []);    
  const [edays, setEdays] = useState(storedOperation && storedOperation.edays && storedOperation.edays.length ? formatEdaysGrpsToDaysjs(storedOperation.edays) : []);    
  
  const [selectDisables, setSelectDisables] = useState([]);
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [selectedData, setSelectedData] = useState(data);
  
  const addEntry = (entryType) =>{

      // Manage Selection / Disable

      let newSelectedEntries = [...selectedEntries];
      //let newDisables = [...selectDisables];
      const hasSelected = newSelectedEntries.filter(selected => selected.includes(entryType));
      if (hasSelected && hasSelected.length) {
        // const hasDisables = newDisables.filter(disable => disable.includes(entryType));
        // newDisables.push(`${entryType}_${hasDisables.length+1}`);        
      }
      setSelectedEntries(newSelectedEntries);
      //setSelectDisables(newDisables); 

      // Add New EntryType

      switch(entryType){
          case 'role' :
              const newRoles = [...roles];
              newRoles.push({
                key:roles.length,
                name: '',
                price:28,
                cost:48,
                currency:'chf'
              });
              return setRoles(newRoles);
          case 'location' :
              const newLocation = [...locations];
              newLocation.push({
                key:locations.length,
                name: '',
                address: '',
                zipcode:'',
                city:'',
                country:''
              });
              return setLocations(newLocation);
          case 'timeSlot' :
              const newTimeSlots = [...timeSlots];
              newTimeSlots.push({
                key:timeSlots.length,
                stime: '',
                etime:'',
              });
              return setTimeSlots(newTimeSlots);             
          case 'position' :
              const newEntries = [...positions];
              newEntries.push('Accueil');
              return setPositions(newEntries);
          case 'eday' :
            const newEdays = [...edays];
            newEdays.push([]);
            return setEdays(newEdays);
          case 'quoteFees' :
            const newQuoteFees = [...quoteFees];
            newQuoteFees.push({
              key:quoteFees.length,
              name: '',
              price:0,
            });
            return setQuoteFees(newQuoteFees);            
          case 'team' :
            formatSelectedData(data);
            setOpenDrawer(true);
            return true;
          default:
              return true;
      }    
  };

  const formatSelectedData = () =>{
    if (selectedEntries && selectedEntries.length) {
      for(const selectedEntry of selectedEntries){
        const [entryType,idx] = selectedEntry.split('_');
        switch(entryType){
          case 'role' :
            data.roles.push(roles[idx]);
            break;
          case 'location' :
            data.locations.push(locations[idx]);
            break;
          case 'timeSlot' :
            data.timeSlots.push(timeSlots[idx]);
            break;
          case 'position' :
            data.positions.push(positions[idx]);
            break;
          case 'eday' :
            data.edays.push(edays[idx]);
            break;
          default:
            return true;
        }
      }
    }
    setSelectedData(data);
  };

  const deleteEntry = (entryType, idx) =>{

    if (window.confirm('Êtes-vous sur de vouloir effacer cette ligne ?')){

      // Manage Selection / Disable

      const entryKey = `${entryType}_${idx}`;
      // let newDisables = [...selectDisables];
      // newDisables = newDisables.filter(disabled => disabled !== entryKey);

      let newSelectedEntries = [...selectedEntries];      
      if (newSelectedEntries.includes(entryKey)) { // delete a selected line
        //newDisables = newDisables.filter(disable => !disable.includes(entryType));
        newSelectedEntries = newSelectedEntries.filter(selected => selected !== entryKey);        
      } else { // delete an unselected line
        // const blockDisables = newDisables.filter(disabled => disabled.includes(entryType));
        // if (blockDisables && blockDisables.length) {
        //   const newBlockDisables = [];
        //   for(const blockDisable of blockDisables){
        //     const [block, oldIndex] = blockDisable.split('_');
        //     if (oldIndex>idx) {
        //       newBlockDisables.push(`${block}_${oldIndex-1}`);
        //     }
        //   }
        //   newDisables = [...newDisables,...newBlockDisables];
        // }
      }

      const blockEntries = newSelectedEntries.filter(selected => selected.includes(entryType));
      if (blockEntries && blockEntries.length) {
        const newBlockEntries = [];
        for(const blockEntry of blockEntries){
          const [block, oldIndex] = blockEntry.split('_');
          if (oldIndex>idx) {
            newBlockEntries.push(`${block}_${oldIndex-1}`);
          } else {
            newBlockEntries.push(blockEntry);
          }
        }
        newSelectedEntries = [...newSelectedEntries.filter(selected => !selected.includes(entryType)),...newBlockEntries];
      }      
      setSelectedEntries(newSelectedEntries);
      //setSelectDisables(newDisables);

      // Delete New EntryType

      switch(entryType){
          case 'role' :
                const newRoles = [...roles];
                let newRolesFiltered = newRoles.filter((ele)=> ele.key !== idx);
                let indexRole = 0;
                for (let ele of newRolesFiltered){
                  ele.key = indexRole;
                  indexRole++;
                }
                setRoles(newRolesFiltered);
              return true;
          case 'position' :              
                const newEntries = [...positions];
                setPositions(newEntries.filter((val,index)=> idx !== index));              
              return true;
          case 'mission' :              
              const newMissions = [...missionSlots];
              setMissionSlots(newMissions.filter((val,index)=> idx !== index));              
            return true;              
          case 'eday' :            
                const newEdays = [...edays];
                let newEdaysFiltered = newEdays.filter((val,index)=> idx !== index);   
                setEdays(newEdaysFiltered);            
            return true;              
          case 'location' :            
                const newLocations = [...locations];
                let newLocationsFiltered = newLocations.filter((ele)=> ele.key !== idx);
                let indexLocation = 0;
                for (let ele of newLocationsFiltered){
                  ele.key = indexLocation;
                  indexLocation++;
                }
                setLocations(newLocationsFiltered);
             
            return true;     
          case 'timeSlot' :              
                  const newTimeSlots = [...timeSlots];
                  let newTimeSlotsFiltered = newTimeSlots.filter((ele)=> ele.key !== idx);
                  let indexTS = 0;
                  for (let ele of newTimeSlotsFiltered){
                    ele.key = indexTS;
                    indexTS++;
                  }
                  setTimeSlots(newTimeSlotsFiltered);               
              return true;                         
          case 'quoteFees' :
            const newQuoteFees = [...quoteFees];
            let newQuoteFeesFiltered = newQuoteFees.filter((ele)=> ele.key !== idx);
            let indexQuoteFee = 0;
            for (let ele of newQuoteFeesFiltered){
              ele.key = indexQuoteFee;
              indexQuoteFee++;
            }
            setQuoteFees(newQuoteFeesFiltered);
          return true;              
          default:
              return true;
        }    
      }
  };  
  const changeEntry = (entryType, e,index,field) =>{     
      switch(entryType){
          case 'role' :
              let newRoles = roles.map(ele => ({...ele }));
              let val = e;
              if (field !== 'currency') {
                val  = e.target.value;
              }
              newRoles[index][field] = val;   
              return setRoles(newRoles);              
          case 'position' :
              let newPositions = [...positions];
              newPositions[index] = e.target.value;   
              return setPositions(newPositions);
          case 'location' :
              let newLocations = locations.map(ele => ({ ...ele }));     
              newLocations[index][field] = e.target.value;   
              return setLocations(newLocations);  
          case 'timeSlot' :
              let newTimeSlots = timeSlots.map(ele => ({ ...ele }));     
              newTimeSlots[index][field] = dayjs(e).format('HH:mm');   
              return setTimeSlots(newTimeSlots);                
          case 'eday' :
              let newEdays = [...edays];
              newEdays[index] = e;   
              return setEdays(newEdays);                    
          case 'missionWorkForce' :              
              let newMissions = missionSlots.map(ele => ({ ...ele }));              
              newMissions[index].workForce = e;   
              return setMissionSlots(newMissions);         
          case 'quoteFees' :                   
              let newQuoteFees = quoteFees.map(ele => ({ ...ele }));     
              newQuoteFees[index][field] = e.target.value;   
              return setQuoteFees(newQuoteFees);      
          case 'quoteExpire' :
            return setQuoteExpire(e);
          default:
              return true;
      }
  };  

  const selectEntry = (e) => {
    let newSelectedEntries = [...selectedEntries];
    let newDisables = [...selectDisables];
    const [block, index] = e.target.id.split('_');
    const entries = {
        'location': locations,
        'position': positions,
        'role': roles,
        'eday': edays,
        'timeSlot': timeSlots
    }[block];
    
    if (e.target.checked && entries && entries.length) {
        entries.forEach((_, idx) => {
            if (Number(index) === idx) {
              newSelectedEntries.push(`${block}_${index}`);
            } else {
              newDisables.push(`${block}_${idx}`);
            }
        });
    } else {
        newSelectedEntries = newSelectedEntries.filter(selected => selected !== `${block}_${index}`);      
        //newDisables = newDisables.filter(disable => !disable.includes(block));
    }
    setSelectedEntries(newSelectedEntries);
    //setSelectDisables(newDisables);
  };

  const formatEdayGrp = (edayGrp)=>{
    const edayArr = [];
    if (edayGrp.length) {
      for(let edayEle of edayGrp){
        edayArr.push(dayjs(edayEle).format('YYYY-MM-DD'));
      }          
    }  
    return edayArr;
  };

  const onWorkForceSubmit= (workForceMissionData) => {
    //console.log('onWorkForceSubmit workForceMissionData',workForceMissionData);
    setMissionSlots(workForceMissionData);
  };

  // //console.log('missionSlots :>> ', missionSlots);
  // let missionItems = missionSlots.map(ele => ({ ...ele }));
  // for(let mission of missionItems){        
  //   mission.eday = dayjs(mission.eday).format('YYYY-MM-DD');
  //   mission.edayGrp = formatEdayGrp(mission.edayGrp)
  // }
  // console.log('missionItems :>> ', missionItems);

  const onSubmit = async ()=>{

      if (!name || name === '') {
          openNotification('error',"Le champs 'Intitulé de l'événement' est obligatoire.");     
          return;
      }
      if (!bmgStatus || bmgStatus === '') {
        openNotification('error',"Le champs 'Statut' est obligatoire.");     
        return;
      }
      // if (!missionSlots.length) {
      //   openNotification('error',"Il n'y a aucune mission a sauvegarder.");     
      //   return;
      // }    
      
      let formatedEdays = [];
      for(const edayGrps of edays){
        if (edayGrps.length) {
          formatedEdays.push(formatEdayGrp(edayGrps));
        }       
      }
      
      let missionItems = missionSlots.map(ele => ({ ...ele }));
      for(let mission of missionItems){        
        mission.eday = dayjs(mission.eday).format('YYYY-MM-DD');
        mission.edayGrp = formatEdayGrp(mission.edayGrp)
      }
      
      const { sdate, edate } = getMissionSlotsDateRange(missionItems);
      
      let props = {
          _client:client,        
          bmgStatus,
          dateImposed,
          name,
          type : newType !== '' ? newType.toLowerCase() : type,          
          body,
          bodyClient,
          instruction,
          contact,
          dressCode,   
          latitude,
          longitude,
          languages,
          bookedTotal,
          locations,
          roles,
          positions,
          edays:formatedEdays,
          timeSlots,
          missionSlots:missionItems,
          quote,
          quoteHeader,
          quoteFooter,
          quoteCurrency,
          quoteVat,
          quoteFees,
          quoteExpire:dayjs(quoteExpire).format('YYYY-MM-DD'),
          sdate:dayjs(sdate, 'YYYY-MM-DD HH:mm').toISOString(),
          edate : dayjs(edate, 'YYYY-MM-DD HH:mm').toISOString(),
      };      

      let entryUpdated = null;
      if (!storedOperation || !storedOperation._id) {
        entryUpdated = await SaveOp(props);
      } else {           
        entryUpdated = await EditOpProps(storedOperation._id,props);
      }
      if (entryUpdated && entryUpdated._id) {
          openNotification('success',"Information Sauvegardée");     
          const updatedList = updateList(storedList,entryUpdated) ;            
          // // Update store
          dispatch(saveLists({section:section,data:updatedList}));
          dispatch(saveOperation(entryUpdated));
          navigate(-1);
      } else {
          openNotification('error',"Un problème est survenu lors de la sauvegarde");     
      }

      return true;
  };

  const onDeleteMissionsSlots = ()=>{
    if (window.confirm(`Êtes-vous sur de vouloir effacer toutes les ${missionSlots && missionSlots.length ? missionSlots.length : 0} lignes des Missions ?`)){      
      setMissionSlots([]);
    }
  };

  const onChangeStep = (value) => {
    setCurrent(value);
  };

  const next = () => {
    if (current < steps.length - 1) setCurrent(current + 1);
  };
  const prev = () => {
    if (current > 0) setCurrent(current - 1);
  };

  useEffect(() => {
    const fetchBmgFilters = async () => {
        const bmgFiltersList = await importBmgFilters();
        if (storedOperation && storedOperation.type && bmgFiltersList[section] && bmgFiltersList[section].type) {
          const hasOpTypeInFilters = bmgFiltersList[section].type.find((ele) => ele.value === storedOperation.type);          
          if (!hasOpTypeInFilters) {
            bmgFiltersList[section].type.push({label:storedOperation.type.toLowerCase(),value:storedOperation.type.toLowerCase()});
          }
        }        
        setBmgFilters(bmgFiltersList);
    };
    fetchBmgFilters(); 
  }, []);

  const steps = [
    {
      title: 'Général',
      content: <StepOne 
          section={section}
          bmgFilters={bmgFilters}
          bmgStatus={bmgStatus}
          clientList={clientList}
          name={name}
          client={client}
          type={type}
          newType={newType}
          quote={quote}
          // price={price}
          dateImposed={dateImposed}
          body={body}
          bodyClient={bodyClient}
          instruction={instruction}
          dressCode={dressCode}
          contact={contact}
          languages={languages}
          bookedTotal={bookedTotal}
          longitude={longitude}
          latitude={latitude}
          handlerChangeMethod={onChangeData}
          handlerSelectMethod={onSelectData}   
          onSubmitNewClient={onSubmitNewClient}     
        />
    },
    {
      title: 'Planification',
      content: <StepTwo 
          section={section} 
          bmgFilters={bmgFilters}          
          addEntry={addEntry}
          deleteEntry={deleteEntry}
          changeEntry={changeEntry}
          selectEntry={selectEntry}
          roles={roles}
          positions={positions}
          locations={locations}
          timeSlots={timeSlots}          
          edays={edays}
          missionSlots={missionSlots}
          selectedEntries={selectedEntries}
          selectDisables={selectDisables}
          onDeleteMissionsSlots={onDeleteMissionsSlots}
        />
    },
    {
      title: 'Devis',
      content: <StepThree 
          section={section}
          bmgFilters={bmgFilters}
          quote={quote}
          quoteCurrency={quoteCurrency}
          quoteVat={quoteVat}
          quoteHeader={quoteHeader}
          quoteFooter={quoteFooter}
          quoteFees={quoteFees}
          quoteExpire={quoteExpire}
          addEntry={addEntry}
          deleteEntry={deleteEntry}
          changeEntry={changeEntry}
          handlerChangeMethod={onChangeData}
          handlerSelectMethod={onSelectData}     
          />
    },
    {
      title: 'Résumé',
      content: <StepFour 
          section={section} 
          quote={quote}
          quoteCurrency={quoteCurrency}
          quoteVat={quoteVat}
          quoteHeader={quoteHeader}
          quoteFooter={quoteFooter}
          quoteFees={quoteFees}
          quoteExpire={quoteExpire}
          missionSlots={missionSlots}
          bmgStatus={bmgStatus}
          clientList={clientList}
          name={name}
          client={client}
          type={type}
          body={body}
          bodyClient={bodyClient}
          instruction={instruction}
          dressCode={dressCode}
          contact={contact}
          languages={languages}
          bookedTotal={bookedTotal}          
          />
    },        
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const StepNavigation = () =>{

    return (
        <div
          style={{
            display:"flex",
            marginTop: 24,
            justifyContent: "center",
            alignItems: "center"
          }}
        >          
          <div style={{ margin: '0 8px', width:"140px" , opacity:current > 0 ? 1 : 0.6}} className="button button-with-accent button-tiny" onClick={() => prev(current)} >
            <LeftCircleOutlined className='step-navigation'/>
            <span style={{marginLeft:"16px"}}>Précédent</span>
          </div>         
          <div style={{ margin: '0 8px', width:"140px" }} className="button button-with-accent button-tiny" onClick={onSubmit}>
              Sauvegarder
            </div>
          
          <div style={{ margin: '0 8px', width:"140px" , opacity: current < steps.length - 1 ? 1 : 0.6}} className="button button-with-accent button-tiny" onClick={() => next()}>
            <span style={{marginRight:"16px"}}>Suivant</span>
            <RightCircleOutlined className='step-navigation'/>
          </div>   
        </div>
    );

  };

  return (
    <div className="container">
      <HeaderBar hasBack={true} hasFilters={false} hasSearch={false} hasSort={false} section={section} storedFilters={storedFilters} setCurrentPage={1}/>
      <div className="content">
        <Steps 
          current={current} 
          items={items}
          onChange={onChangeStep}          
        />
        <div>{steps[current].content}</div>
        <StepNavigation/>
        <WorkForceDrawer 
            drawerLoading={true}
            openDrawer={openDrawer}
            handlerMethod={closeDrawer} 
            data={selectedData}
            missionSlots={missionSlots}
            storedOperation={storedOperation}
            onWorkForceSubmit={onWorkForceSubmit}
        />   
      </div>
    </div>
  );
};

export default OpForm ;
