import React, { useState } from 'react';
import { Popover } from 'antd';
import ToDoForm from '../todo/ToDoForm';
import QuoteDetail from '../client/QuoteDetail';
import MissionSlotForm from '../operation/elements/MissionSlotForm'
import ClientForm from '../client/elements/ClientForm'
import RoleForm from '../operation/elements/RoleForm'

const PopOverForm = ({content,color,form,data,handlerMethod,target,targetData}) => {
    const [open, setOpen] = useState(false);

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };
    
    // Save todo 
    const popSubmit = (val)=>{
        handlerMethod(val);
        setOpen(false);
    };
    
    const popContent = () => {

        switch(form) {
            case 'todo' :
                return (
                    <ToDoForm setOpen={setOpen} data={data} handlerMethod={popSubmit} target={target} targetData={targetData}/>
                );
            case 'quote' :
                return (
                    <QuoteDetail setOpen={setOpen} data={data}/>
                );  
            case 'missionSlot' :
                return (
                    <MissionSlotForm setOpen={setOpen} open={open} data={data} handlerMethod={handlerMethod}/>
                );     
            case 'roleForm' :
                return (
                    <RoleForm setOpen={setOpen} open={open} data={data} handlerMethod={handlerMethod}/>
                );                                 
            case 'clientForm' :
                return (
                    <ClientForm scale='light' setOpen={setOpen} open={open} data={data} handlerMethod={handlerMethod}/>
                );                                                                
            default:
                return;
        };

    };

    return (
        <Popover            
            placement={form === 'missionSlot' ? "left" : 'top' }
            content={popContent}
            trigger="click"
            open={open}
            color={color}
            //color={color ? color : 'var(--secondary-color)'}
            className="bmg-popover"
            onOpenChange={handleOpenChange}
        >
        <span onClick={()=>handleOpenChange} >{content}</span>
        </Popover>
    );
};
export default PopOverForm;