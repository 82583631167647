import React, { useState,useEffect } from 'react';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';

import {  Table, Pagination} from 'antd';
import { IssuesCloseOutlined, CarryOutOutlined, SafetyCertificateOutlined,DeleteOutlined,MenuUnfoldOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { sortMissionSlotsByDays,capitalize, getAge, getGender,CheckStaffAgenda, CheckMissionSlotStaffStatus, getCountry, FormatStaffByRole, doS,scoreContent } from "../../../utils/miscHelpers"
import { BmgStatus, SlotBmgStatus, ScoresValues, geographicAvailibilities } from "../../../utils/constants"

import OperationGeneral from "./OperationGeneral"
import PopOver from "../../elements/PopOver"
import LabelForm from "../../label/LabelForm"

const CartTab =  ({
            section, 
            bmgFilters, 
            listUsers , 
            staffList,
            cartList,
            loading,
            openGeneral,
            setOpenGeneral,
            currentPage,
            pageSize,
            totalPages,
            storedLabelList,    
            onPaginationChange,
            onTableChange,
            onPopChange,
            onLabelUpdated,
            isCart,
            saveCartUsers,
            showModal,
            selectedUsers, 
            setSelectedUsers,
            onDeleteCart,
            showCart,
            showRecapModal,
            setSelectedRecapTypes
        }) => {

    // const dispatch = useDispatch();
    // const navigate = useNavigate();
    // const { t } = useTranslation();          
    
    const storedOperation = useSelector(state => state.operation.value && state.operation.value ? state.operation.value  : {});
    
    const [selectedRows, setSelectedRows] = useState([]);
        
    useEffect(() => {
        setSelectedRows([]);
        setSelectedUsers([]);
      }, [listUsers]);

    const rowSelection = {
        selectedRowKeys: selectedRows,
        onChange: (selectedRowKeys, selectedRows,type) => {
            setSelectedRows(selectedRowKeys);
        },
    };

    const addUserSelection = ()=>{
        const userItems = [...selectedUsers];
        if(selectedRows && selectedRows.length){
            for(const rowId of selectedRows){
                const isUserItems = userItems.find((ele)=> ele._id === rowId);                
                if (!isUserItems) {
                    const userData = listUsers.find((ele)=> ele._id === rowId);
                    if (userData && userData._id) userItems.push(userData);
                }
            }
        }
        //setSelectedUsers(userItems);
        saveCartUsers(userItems);
    };

    const affectUsers = ()=>{
        const userItems = [];
        if(selectedRows && selectedRows.length){
            for(const rowId of selectedRows){
                const isUserItems = userItems.find((ele)=> ele._id === rowId);                
                if (!isUserItems) {
                    const userData = listUsers.find((ele)=> ele._id === rowId);
                    if (userData && userData._id) userItems.push(userData);
                }
            }
        }

        setSelectedUsers(userItems);
        
    };

    // const removeStaff = ()=>{
    //     setSelectedUsers([]);
    // };

    const linkMeToUser = async (user)=>{
        return window.open(process.env.REACT_APP_PUBLIC_BO_URL+'/user/detail?staffId='+user._id);
    };
    //console.log('staffList',staffList);

    const reformatMissionSlots = (storedOperation) =>{
        const agenda = [];
        if (storedOperation && storedOperation.missionSlots && storedOperation.missionSlots.length) {
            for(const missionSlot of storedOperation.missionSlots) {
                agenda.push({
                    eday:missionSlot.eday,
                    stime:missionSlot.timeSlot.stime,
                    etime:missionSlot.timeSlot.etime
                })
            }
        }
        return agenda;
    };

    const operationFormatedMissionSlots = reformatMissionSlots(storedOperation);    

    let columns = [        
        { 
          key: "name", 
          title: isCart ? "Panier" : "Hôtesses", 
          dataIndex: "name", 
          //   sorter: {},
          width: '260px',        
          fixed: 'left',      
          render: (value,record) => {
            const staffAgenda = CheckStaffAgenda(storedOperation,operationFormatedMissionSlots,record.missions);            
            const hasBookedEvents = staffAgenda.filter((ele)=> ele.bookable === false);
            const isStaff = staffList.find((ele)=>ele._id === record._id);
            return ( 
            <> 
                <div className="tab-table-row-block">
                    <div className='flex' style={{flexDirection:'column', alignItems: "center"}}>
                        <img style={{marginBottom:'6px'}} alt="user" src={record.avatar ? record.avatar : '/img/placeholder-avatar.jpg'} className="avatar-medium" />
                        <PopOver 
                            content={
                                <span className="capitalize tag" style={{backgroundColor:record.bmgStatus ? BmgStatus[record.bmgStatus].color : BmgStatus[0].color}}>{record.bmgStatus ? BmgStatus[record.bmgStatus].label : BmgStatus[0].label}</span>
                            }              
                            prop="bmgStatus" 
                            handlerMethod={onPopChange}
                            items={BmgStatus}
                            user={record}
                        />   
                    </div>
                    <div style={{marginLeft:'6px'}}>
                        {(record.score !== null && record.score !== 0) && 
                            <PopOver 
                                content={
                                    <div className="score-list-user-small">{scoreContent(record.score,'score-small')}</div>
                                }
                                prop="score"
                                handlerMethod={onPopChange}
                                items={ScoresValues}
                                user={record}
                            />                          
                        }
                        <div className={"link custom-name capitalize "+ (isStaff ? 'text-black' : '')} onClick={()=>linkMeToUser(record)}>{record.firstName +' '+record.lastName}</div>  
                        <span className="tab-table-row-span capitalize">{record.nationality ? capitalize(getCountry(record.nationality ,'fr')) : ''} ({getGender(record.gender)}) {capitalize(record.city)} {record.workPermit ? <SafetyCertificateOutlined/> : ''}</span>
                        <div className='flex' style={{flexDirection:'column'}}>
                            <div style={{minWidth:'100px'}}>
                                <span className='tab-table-row-span'>{record.birthday ? getAge(record.birthday)+' ans' : ''}</span>
                                <span className='tab-table-row-span'>{record.height ? record.height+'cm' : ''}</span>
                            </div>                            
                        </div>
                        {hasBookedEvents && hasBookedEvents.length !== 0 && 
                            <div>
                                <IssuesCloseOutlined 
                                    color='black'
                                    style={{color:staffAgenda && hasBookedEvents && hasBookedEvents.length && staffAgenda.length === hasBookedEvents.length ? 'var(--violet-color)' : 'var(--grey-color)',marginTop:'6px',fontSize:'18px',cursor:'cell'}} 
                                    title={staffAgenda && hasBookedEvents && hasBookedEvents.length && staffAgenda.length === hasBookedEvents.length ? 'Entierement Booké(e) sur une autre opération' : 'Partiellement Booké(e) sur une autre opération'}
                                />
                            </div>
                        }                   
                    </div>
                </div>
            </> 
            ); 
          },      
        }, 
        { 
            key: "geographicAvailability", 
            title: "Dispo.", 
            dataIndex: "geographicAvailability", 
            // width:222,  
            render: (value) => { 
                let geoActivity = [];
                if (value && value.length) {
                    for(const geoId of value){
                        const geographicAvailability = geographicAvailibilities.find((geo)=>{return geo.id === geoId});
                        if (geographicAvailability) geoActivity.push(geographicAvailability.title);                        
                    }
                }
                return ( 
                    <> 
                      <div className="capitalize" >
                        {geoActivity && geoActivity.length && geoActivity.map((ele,idx) =>
                            <div key={idx.toString()}>
                                 <span className='capitalize' style={{fontSize:'13px'}}>{ele}</span>
                            </div>
                        )}
                      </div>
                    </> 
                );
            },      
        },
        // { 
        //     key: "missionSlots", 
        //     title: "Afféctations Staff ", 
        //     dataIndex: "missionSlots", 
        //     // width:222,  
        //     render: (value,staff) => { 
                
        //         if (!value || !value.length) return '-';
                
        //         const formatStaffByRole = FormatStaffByRole(value);

        //         if (!formatStaffByRole.length) return '-';
                
        //         return ( 
        //             <> 
        //                 {formatStaffByRole && formatStaffByRole.length>0 && formatStaffByRole.map((role,index) =>
        //                     <div key={'role-'+index.toString()} className='flex' style={{flexDirection: "column",fontSize:'12px',marginBottom:'6px'}}>
        //                         <span className="semibold uppercase">{role.name} - {role.price}/{role.cost}{storedOperation.quoteCurrency}</span>
        //                         {role.slots && role.slots.length && sortMissionSlotsByDays(role.slots).map((slot,idx) => {
        //                             const staffSlotStatus = CheckMissionSlotStaffStatus(slot,storedOperation,staff._id);
        //                             return (
        //                                 <div key={'slot-'+idx.toString()} className='flex' style={{ 
        //                                         textDecoration: staffSlotStatus === 0 ? "line-through":"none",
        //                                         alignItems:'center'
        //                                     }}>
        //                                     <div style={{width:'10px',height:'10px', marginRight:'6px', borderRadius:'5px',backgroundColor:staffSlotStatus && SlotBmgStatus[staffSlotStatus] ? SlotBmgStatus[staffSlotStatus].color : 'white'}} title={SlotBmgStatus[staffSlotStatus] ? SlotBmgStatus[staffSlotStatus].label : ''}/>
        //                                     <span style={{marginRight:'10px'}}>{dayjs(slot.eday,'YYYY-MM-DD').format('DD/MM/YY')}</span>
        //                                     <span>{slot.timeSlot?.stime} <span className='uncapitalize'>à</span> {slot.timeSlot?.etime}</span>                                               
        //                                 </div>
        //                             )
        //                             }
        //                         )}   
        //                     </div>
        //                 )}                        
        //             </> 
        //         ); 
        //     },      
        // },   
        { 
            key: "label", 
            title: "Labels", 
            dataIndex: "label", 
            // sorter: {},      
            // width: '33px',           
            render: (value,record) => { 
              return ( 
              <> 
                   <LabelForm 
                        section='user'
                        labels={value}
                        record={record}
                        handlerMethod={onLabelUpdated}
                        storedLabelList={storedLabelList || []}                
                    />  
              </> 
              ); 
            },      
        },
        { 
            key: "action", 
            title: "Action", 
            width: '80px',
            fixed: 'right',
            render: (record) => { 
              return ( 
                <> 
                  <div className="flex">                     
                    <DeleteOutlined 
                      title="Supprimer du Panier"
                      className='icon-table'
                      onClick={()=>onDeleteCart(record)}
                    /> 
                  </div> 
                </> 
              ); 
            }, 
          },   
        // { 
        //     key: "operationStatus", 
        //     title: "Statut", 
        //     dataIndex: "operationStatus", 
        //     // sorter: {},      
        //     // width: '33px',           
        //     render: (value,record) => { 
        //       return ( 
        //       <> 
        //         <span className="capitalize tag" style={{backgroundColor:MissionBmgStatus[value] ? MissionBmgStatus[value].color : MissionBmgStatus[0].color}}>{MissionBmgStatus[value] ? MissionBmgStatus[value].label : 'Aucun'}</span>
        //       </> 
        //       ); 
        //     },      
        // }, 
        // { 
        //     key: "action", 
        //     title: "Actions", 
        //     fixed: 'right',
        //     // width: '33px',  
        //     render: (record) => { 
        //       return ( 
        //         <> 
        //           <div className="flex" style={{
        //             flexDirection: "row",
        //             alignItems: "center",
        //             justifyContent: "space-around"
        //           }}>
        //             <TeamOutlined 
        //               title="Editer"
        //               style={{ fontSize:'14px',color: "var(--accent-color)", marginRight:'8px'}} 
        //             //   onClick={() => Edit(record)} 
        //             /> 
         
        //           </div> 
        //         </> 
        //       ); 
        //     }, 
        //   },                               
    ];
    
    const noStaffList = listUsers.filter(
        (user) => !staffList.some((staff) => staff._id === user._id)
    );
    
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    
    columns = columns.map(col => ({
      ...col,
      fixed: isMobile ? undefined : col.fixed,
    }));
    
    return(
        <div className="tab-content">
            <OperationGeneral
                section={section}
                bmgFilters={bmgFilters}
                openGeneral={openGeneral}
                setOpenGeneral={setOpenGeneral}
                staffList={staffList}
                showRecapModal={showRecapModal}
                setSelectedRecapTypes={setSelectedRecapTypes}
            />
            <div className={'tab-block '+ (openGeneral ? '' : 'folded')} style={{paddingTop:'26px',marginRight:'6px'}}>
                <div className='flex' style={{
                        border: "solid 1px var(--accent-color)",
                        borderRadius:'6px',
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        margin:'16px 0',
                        padding:'6px 0',
                        height:'32px'
                    }}>
                    { !isCart && selectedRows.length!==0 &&
                        <div className='flex' style={{justifyContent: "space-evenly",alignItems: "center"}}>
                            <span style={{fontSize:'12px'}}>{selectedRows.length} ligne{doS(selectedRows)} séléctionnée{doS(selectedRows)}</span>                    
                            <div style={{margin:'0 16px'}}className='link icon-content' title='Ajouter la séléction au Panier' onClick={addUserSelection}>+</div>                            
                        </div>
                    }
                    {!isCart && 
                        <div style={{margin:'0 16px'}} className='flex link' title='Voir le panier' onClick={showCart}>
                            <span className='h2s capitalize' style={{fontSize:'12px',marginRight:'6px'}}>Voir le panier</span>
                            <CarryOutOutlined className='h2'/>
                        </div>    
                    }
                    {/* <div style={{margin:'0 16px'}} className='flex' title='Panier'>
                        <span style={{fontSize:'12px'}}>{selectedUsers.length} Hôte(sse){doS(selectedUsers)} choisi(e){doS(selectedUsers)}</span>
                        {selectedUsers.length > 0 &&
                            <DeleteOutlined className='h2 link' onClick={removeStaff} title="Effacer les séléctionné(e)s" style={{marginLeft:'6px'}}/>
                        }                        
                     </div>    */}
                    { isCart && selectedRows.length!==0 &&
                        <div style={{margin:'0 16px'}} className='flex link' title='Affecter les Role au Staff' onClick={affectUsers}>
                            <span className='h2s capitalize' style={{fontSize:'12px',marginRight:'6px'}}>Affecter aux Roles & Missions</span>
                            <MenuUnfoldOutlined className='h2'/>
                        </div>    
                    }                                    
                </div>
                <Table 
                    dataSource={noStaffList} 
                    loading={loading} 
                    rowKey="_id"
                    columns={columns} 
                    pagination={false} 
                    className="capitalize custom-checkbox custom-table"
                    onChange={onTableChange} 
                    scroll={{
                        x: 'max-content',
                        y: 450,                        
                    }}
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    locale={{
                        emptyText: 'Aucun Staff ajouté',
                    }}
                />
                <Pagination
                    showSizeChanger={true} 
                    current={currentPage}
                    pageSize={pageSize}                
                    total={totalPages} 
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}       
                    onChange={onPaginationChange}
                    className="pagination-list"
                />
            </div>
            {/* <StaffDrawer 
                section={section}
                drawerLoading={true}
                openDrawer={openDrawer}
                handlerMethod={closeDrawer} 
                staffList={selectedUsers} 
                storedOperation={storedOperation}
                onAffectUsers={onAffectUsers}
            /> */}
        </div>
    )
};

export default CartTab;