import React from 'react';
import { MissionBmgStatus } from '../../utils/constants'

const LegendStatus = ({shape='circle',type='',direction='row',clickable=false, setStatusFilter,statusFilter}) => {
    const legend = [];
    for(const status in MissionBmgStatus){
        legend.push(MissionBmgStatus[status]);
    }
    legend.reverse();
    if (type === 'full') {
        legend.push({
            label : 'Terminé',
            color: '#C9B39B',
        });
    } else if (type === 'affect') {
        legend.push({
            label : 'Affécté',
            color: '#C9B39B',
            ref:-1
        });
    }
    
    let containerStyle = {
        alignItems:'center',
        marginRight:'10px',    
    };
    
    let divStyle = {
        borderRadius:'5px',
        marginRight:'6px',
        width:'10px',
        height:'10px',
    };
    if (shape === 'square')  {
        containerStyle = {
            marginBottom:'6px',
        };
        divStyle = {
            marginRight:'6px',
            width:'10px',
            height:'10px',
        };
    }

    if (clickable) containerStyle.cursor = 'pointer' ;

    const updateStatusFilter = (ele) =>{
        let newStatusFilter = [...statusFilter];
        if (ele.ref === -1) { // Hack to handle the 4 case
            if (newStatusFilter.includes(ele.ref) || newStatusFilter.includes(4)) {
                newStatusFilter = newStatusFilter.filter((el)=>el !== ele.ref && el !== 4);
            } else {
                newStatusFilter.push(ele.ref);
                newStatusFilter.push(4);
            }
        } else {
            if (newStatusFilter.includes(ele.ref)) {
                newStatusFilter = newStatusFilter.filter((el)=>el !== ele.ref);
            } else {
                newStatusFilter.push(ele.ref);
            }
        }        
        setStatusFilter(newStatusFilter);
    };

    const opacityValue = (ref) =>{
        return statusFilter && statusFilter.includes(ref);
    };
    
    return (
        <div className='flex ' style={{flexDirection:direction}}>
            {legend.length > 0 && legend.map((ele,index)=>(
                <div key={index.toString()} className='legend flex flex-column-start' style={containerStyle} onClick={()=>clickable ? updateStatusFilter(ele) : null}>
                    <div style={{...divStyle, backgroundColor:ele.color,opacity:opacityValue(ele.ref) ? 0.3 : 1}} />
                    <span style={{opacity:opacityValue(ele.ref) ? 0.3 : 1}}>{ele.label}</span>
                </div>
            ))

            }
        </div>
    );
};

export default LegendStatus;