import React from 'react';
import { MenuFoldOutlined,MenuUnfoldOutlined} from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
//import { useNotification } from '../../../hooks/NotificationProvider';

import { useSelector,useDispatch } from 'react-redux';
import { useNotification } from '../../../hooks/NotificationProvider';
import { EditClientProps } from "../../../services/Client";
import { saveLists } from '../../../features/lists/listsSlice';
import { saveClient } from '../../../features/client/clientSlice';
import { capitalize, getAddress, updateList } from "../../../utils/miscHelpers";
import {clientBmgStatus} from "../../../utils/constants";
import LabelForm from "../../../components/label/LabelForm";
import PopOver from "../../../components/elements/PopOver";

const ClientGeneral = ({section, bmgFilters, openGeneral, setOpenGeneral}) => {
    
    const dispatch = useDispatch();
    const { t } = useTranslation();    
    const { openNotification } = useNotification();

    const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : null);
    const storedClient = useSelector(state => state.client.value && state.client.value ? state.client.value  : {});
    const storedLabelList = useSelector(state => state.lists.value && state.lists.value['label'] ? state.lists.value['label'] : []);  
   
    const onLabelUpdated = (record) => {
        const clientUpdated = {...storedClient,...{label:record.label}};
        const updatedList = updateList(storedList,clientUpdated) ;
        dispatch(saveClient(clientUpdated));
        dispatch(saveLists({section:section,data:updatedList}));
        return true;
    };

    const onCloseGeneral = () =>{
        setOpenGeneral(openGeneral ? false : true);
    };


    const onPopClientBmgStatusChange = async (data,props)=>{
        try {
        const itemUpdated = await EditClientProps(data._id,props);
        if (itemUpdated && itemUpdated._id) {  
            dispatch(saveClient(itemUpdated));
            const updatedList = updateList(storedList,itemUpdated); 
            dispatch(saveLists({ section : section, data :updatedList}));
            openNotification('success','Modification Enregistrée');
        } else {
            openNotification('error','Probléme lors de la sauvegarde');
        }
        } catch (error) {
            console.error('Error onPopClientBmgStatusChange EditClientProps:', error);
            openNotification('error','Catch / Probléme lors de la sauvegarde');
        }     
    }; 

    const cardMetaDescription = (item) =>{
        
        if (!openGeneral) return ;
        const bmgStatus = clientBmgStatus.find((ele)=>ele.value === item.bmgStatus)
        return (
            <>
                <div className="card-content" style={{ width: "250px"}}>
                    <div>
                        
                        <div className='flex link' title="Fermer" onClick={(onCloseGeneral)}>
                            {openGeneral === true &&                            
                                <MenuFoldOutlined  style={{fontSize:'18px',marginRight:'10px',color:'var(--accent-color)'}}/>                             
                            }                            
                            <h2 className="capitalize">{item.name}</h2>                  
                        </div>
                        
                        <div style={{textAlign:'center',marginBottom:'32px'}}>
                            <PopOver 
                                content={
                                    <span className="capitalize tag-large" style={{backgroundColor:bmgStatus ? bmgStatus.color : 'white'}}>{bmgStatus ? bmgStatus.label : ''}</span>
                                }              
                                prop="clientBmgStatus" 
                                handlerMethod={onPopClientBmgStatusChange}
                                items={clientBmgStatus}
                                user={storedClient}
                            />                   
                        </div>
                        <div>
                            <span className="h2">{t('client.activity')} : </span>
                            <span className="capitalize">{item.activity}</span>
                        </div> 
                        <div>
                            <span className="h2">{t('client.admin')} : </span>
                            <span className='capitalize'>{item._admin ? capitalize(item._admin.firstName) : '-'}</span>
                        </div>                            
                        <div style={{margin:'10px 0'}}>
                            <span className="h2 capitalize">{t('client.address')} : </span>
                            <span>{getAddress(item)}</span>
                        </div>  
                        <div >
                            <span className="h2 capitalize">{t('client.contacts')} : </span>
                        </div>
                        {(item.contacts && item.contacts.length) &&
                            item.contacts.map((contact,index)=> {
                                return(
                                    <div key={index.toString()} style={{padding:'16px 0',borderBottom:'solid 1px var(--accent-color)'}}>
                                        <div className='capitalize'>
                                            <span className="h2">{t('client.name')} : </span>
                                            <span>{contact.firstName} {contact.lastName}</span>
                                        </div>
                                        <div>
                                            <span className="h2">{t('client.phone')} : </span>
                                            <span>{contact.phone}</span>
                                        </div>
                                        <div>
                                            <span className="h2">{t('client.email')} : </span>
                                            <span><a className="link" href={"mailto:"+contact.email}>{contact.email}</a></span>
                                        </div>                                        
                                    </div>
                                )
                                }
                            )
                        }
                        <div style={{ marginTop:'32px', paddingBottom:"16px",textAlign: "right"}}>
                            <LabelForm 
                                section={section}
                                labels={item.label}
                                record={item}
                                handlerMethod={onLabelUpdated}
                                storedLabelList={storedLabelList || []}                
                            />
                       </div>                                       
                    </div>
                </div>
          </>
        );
      }

    return(
        <div className={'user-general '+ (openGeneral ? 'unfolded' : 'folded')} >
            <div onClick={(onCloseGeneral)} title="Ouvrir" className='link'>
                {openGeneral !== true &&
                    <div style={{marginLeft:'10px',marginTop:'3px',position:'absolute',}}>                        
                        <MenuUnfoldOutlined className='link' style={{marginRight:'12px',color:'var(--accent-color)'}}/> 
                        <span className="h4s capitalize">{storedClient.name}</span>
                    </div>
                    
                }                
            </div>
            {cardMetaDescription(storedClient)}
        </div>
    )
};

export default ClientGeneral;
