import React, { useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; // Import the locale you want to use
import './WeekViewCalendar.css';
import { capitalize } from "../../../utils/miscHelpers"
import { LeftOutlined,RightOutlined } from '@ant-design/icons';
import EventContent from "./EventContent";

const WeekViewCalendar = ({ setTmpTodoList, events, locale = 'fr' }) => {
  // Set the locale for dayjs
  dayjs.locale(locale);

  // State to track the current week offset from today
  const [weekOffset, setWeekOffset] = useState(0);

  // State to handle input for specific week number
  const [specificWeek, setSpecificWeek] = useState('');

  // Months of the year for the dropdown
  const months = dayjs.months();

  // State to handle selected month and year
  const [selectedMonth, setSelectedMonth] = useState(dayjs().format('MMMM'));
  const [selectedYear, setSelectedYear] = useState(dayjs().year());

  // Calculate the start of the week based on the offset
  const startOfWeek = dayjs().startOf('week').add(weekOffset, 'week');

  // Days of the week
  const daysOfWeek = Array.from({ length: 7 }, (_, i) =>
    dayjs(startOfWeek).add(i, 'day')
  );

  // Handle previous week button click
  const handlePreviousWeek = () => {
    setWeekOffset(weekOffset - 1);
  };

  // Handle next week button click
  const handleNextWeek = () => {
    setWeekOffset(weekOffset + 1);
  };

  // Handle specific week navigation
  const handleGoToWeek = () => {
    if (specificWeek) {
      const weekStart = dayjs().week(Number(specificWeek)).startOf('week');
      const newOffset = weekStart.diff(dayjs().startOf('week'), 'week');
      setWeekOffset(newOffset);
    }
  };

  const handleGoToDate = (type, date) => {
    
    let selMonth = selectedMonth;
    let selYear = selectedYear;
    let selDate = dayjs(); // Default to today
  
    if (type === 'month') { 
      selMonth = date; 
      setSelectedMonth(date);
      selDate = dayjs().year(Number(selYear)).month(months.indexOf(date)).startOf('month'); 
    }
    if (type === 'year') { 
      selYear = date; 
      setSelectedYear(date);
      selDate = dayjs().year(Number(date)).month(months.indexOf(selMonth)).startOf('month');
    }
    if (type === 'date') {       
      selDate = dayjs(date); // Expecting date in valid format like "YYYY-MM-DD"
      setSelectedMonth(dayjs(date).format('MMMM'));
      setSelectedYear(dayjs(date).year());
    }
  
    // Calculate the week offset based on the exact date
    const newOffset = selDate.startOf('week').diff(dayjs().startOf('week'), 'week');
    setWeekOffset(newOffset);
  };
  

  // Generate an array of years for the year dropdown
  const yearOptions = Array.from({ length: 10 }, (_, i) =>
    dayjs().year() - 5 + i
  );

  return (
    <div className="week-view-calendar">
      <div className="week-controls">
        <div style={{marginLeft:'16px'}}>
        <input
            type="number"
            placeholder="No"
            value={specificWeek}
            style={{width:'70px'}}
            onChange={(e) => setSpecificWeek(e.target.value)}
          />
          <button style={{backgroundColor:'white'}} onClick={handleGoToWeek}>Aller à la semaine</button>
        </div>
        <LeftOutlined onClick={handlePreviousWeek}/>
        <RightOutlined onClick={handleNextWeek}/>
        <div className="specific-navigation" style={{ display: 'flex',alignItems: 'center',justifyContent: 'flex-end'}}>
          <div className='link' style={{marginRight:'16px'}} onClick={()=>handleGoToDate('date',new Date())}>Aujourd'hui</div>
          <select
            value={selectedYear}
            onChange={(e) => handleGoToDate('year',e.target.value)}
          >
            {yearOptions.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          <select
            value={selectedMonth}
            onChange={(e) => handleGoToDate('month',e.target.value)}
          >
            <option value="">Select Month</option>
            {months.map((month, index) => (
              <option key={index} value={month}>
                {capitalize(month)}
              </option>
            ))}
          </select>          
        </div>
      </div>
      <div className="week-header">
        {daysOfWeek.map((day, index) => (
          <div key={index} className="day-header">
            {capitalize(day.format('ddd'))} {day.format('DD/MM')}
          </div>
        ))}
      </div>
      <div className="week-events">
        {daysOfWeek.map((day, index) => (
          <div key={index} className="day-column">
            {events
              .filter(event =>
                dayjs(event.date).isSame(day, 'day')
              )
              .map((event, idx) => {
                return (<EventContent section='agenda' item={event} index={idx} setTmpTodoList={setTmpTodoList}/>);
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeekViewCalendar;
