import React  from 'react';
import FormGeneral from "../elements/FormGeneral";

const StepOne =  ({
        section, 
        bmgFilters, 
        clientList,
        handlerChangeMethod,
        handlerSelectMethod,
        onSubmitNewClient,
        latitude,
        longitude,
        bmgStatus,dateImposed,name,client,type,newType,quote,price,bookedTotal,address,zipcode,city,country,dressCode,contact,body,bodyClient,instruction,languages
    }) => {    

    return(
        <div className="step-content">    
            <div className='h2 step-title'>Informations Générales concernant l'Opération</div>        
            <FormGeneral
                topSpacer={12}
                section={section}
                bmgFilters={bmgFilters}
                clientList={clientList}
                bmgStatus={bmgStatus}
                dateImposed={dateImposed}
                name={name}
                client={client}
                type={type}
                newType={newType}
                quote={quote}
                price={price}
                body={body}
                bodyClient={bodyClient}
                instruction={instruction}
                dressCode={dressCode}
                contact={contact}
                address={address}
                zipcode={zipcode}
                city={city}
                country={country}
                languages={languages}
                bookedTotal={bookedTotal}
                latitude={latitude}
                longitude={longitude}
                handlerChangeMethod={handlerChangeMethod}
                handlerSelectMethod={handlerSelectMethod}
                onSubmitNewClient={onSubmitNewClient}
            />          
        </div>
    )
};

export default StepOne;